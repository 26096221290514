import React, { useCallback, useEffect, useState } from "react";
import { ORDER_STORE } from "../../../utilities/indexedDB";
import {
    Container,
    Grid,
  } from '@mui/material';
import SearchHeaderUI from "../../../components/headers/search-header/SearchHeader";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import { applySearchAndFilter } from "../../../utilities/searchFilter";
import OrderHistoryCard from "./OrderHistoryCard";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import orderFilterMapFn from "../orderFilterMapFn";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";


const OrderHistoryPage = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [filterMap, setFilterMap] = useState({})
    const [orderList, setOrderList] = useState([])
    const [orderSearchShowList, setOrderSearchShowList] = useState([]);
    const {selectedSupplier} = useSupplierContext()
    const pageRefreshed = useSelector(state => state.order.refreshedOrderHistoryPageTime);

    usePermissionCheck({
      requestType: 'view',
      tableName:'ordertable',
      message: "You don't have permission to view Order, check with your administrator!"
    })

    useEffect(() => {
      dispatch(setLoadingMessage("loading orders..."))
      fetchDataAndStore(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`,
        ORDER_STORE,
        'order_id'
      ).then((orders) => {
        setOrderList(orders);
        setFilterMap(orderFilterMapFn(orders));
        setOrderSearchShowList(orders);
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        dispatch(clearLoadingMessage())
      })

    },[selectedSupplier, pageRefreshed])

    // const fetchData = useCallback(async () => {
    //   const _orderlist = await fetchDataAndStore(
    //     `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`,
    //     ORDER_STORE,
    //     'order_status'
    //   )
    //   if(! _orderlist) return;

    //   const filteredOrderList  = [..._orderlist]
    //   setOrderList(filteredOrderList);
    //   setFilterMap(orderFilterMapFn(filteredOrderList));
    //   setOrderSearchShowList(filteredOrderList);
    // }, []);
    
    // useEffect(() => {
    //   fetchData();
    // }, []);

    useEffect(() => {
      const searchKeys = ['order_status', 'order_id', 'order_shipto_id']
      setOrderSearchShowList(applySearchAndFilter(searchValue, searchKeys, orderList, filterMap))
    }, [searchValue, orderList, filterMap])

    const orderListArea = (orderList) => {
      return (
        <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          {orderList?.map((order, index) => (
              <OrderHistoryCard
                key={order + index}
                orderData={order}
                skeleton={false}
              />
          ))}
        </Grid>
      </Grid>
      )
    }

    return (
    <div>

      <SearchHeaderUI
        label="Order History"
        shownData={orderSearchShowList}
        setSearchValue={setSearchValue}
        setIsSearching={setIsSearching}

        filterMap={filterMap}
        setFilterMap={setFilterMap}
      />
      
      <Container>
        {isSearching ? orderListArea(orderSearchShowList): orderListArea(orderList)}
      </Container>

    </div>
    )
}

export default OrderHistoryPage;
