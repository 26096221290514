import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchDataFromUrl } from '../../utilities/fetchData';
import { useDispatch } from 'react-redux';
import { setError } from '../alert-message/alerMessageSlice';
import { clearLoadingMessage, setLoadingMessage } from '../loading-dialog/loadingMessageSlice';

const SupplierContext = createContext([]);

export const SupplierProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [suppliers, setSuppliers] = useState('');

  useEffect(() => {
    localStorage.setItem('selectedSupplier', selectedSupplier);
  }, [selectedSupplier]);

  const selectSupplier = (supplierId) => {
    setSelectedSupplier(supplierId);
  };

  const setSuppliersList = (supplierIds) => {
    setSuppliers(supplierIds)
  };

  return (
    <SupplierContext.Provider value={{ suppliers, setSuppliersList, selectedSupplier, selectSupplier }}>
      {children}
    </SupplierContext.Provider>
  );
};

export const useSupplierContext = () => {
    const context = useContext(SupplierContext);
  
    if (!context) {
      throw new Error("useSupplierContext must be used within a SupplierContext.Provider");
    }
  
    return context;
  };
