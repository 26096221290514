import React from "react";
import { 
    useTheme,
    Grid,
    Typography,
    Skeleton
} from "@mui/material";


const TextItem = ({itemKey, itemValue, xsSize, mdSize, skeleton=false}) =>{
    const theme = useTheme();

    return (
      <Grid item xs={xsSize} md={mdSize}>
        <div>
          <Typography 
            variant="subtitle2" 
            color={theme.palette.text.secondary}
            >
            {itemKey}
          </Typography>
          {skeleton ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Typography 
            variant="body1"
            color={theme.palette.text.primary}
          >
          {itemValue}
          </Typography>

          
          )}
        </div>
      </Grid>
    )
  }

export default TextItem;