// error401Slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  has401Error: false,
  timestamp: new Date().toISOString()
};

const error401Slice = createSlice({
  name: 'error401',
  initialState,
  reducers: {
    set401Error: (state) => {
      state.has401Error = true;
      state.timestamp = new Date().toISOString();
    },
    clear401Error: (state) => {
      state.has401Error = false;
    },
  },
});

export const { set401Error, clear401Error } = error401Slice.actions;

export default error401Slice.reducer;
