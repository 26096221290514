import React, { useEffect, useState } from "react"
import { 
     Drawer,
     Paper,
     AppBar,
     Toolbar,
     Typography,
     IconButton,
     Container,
     Grid,
     TextField,
     FormControlLabel,
     FormGroup,
     Checkbox,
     Button,
} from "@mui/material"
import { Close, DirectionsBoatOutlined, Refresh } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { clearEditShipToData } from "./slice-shipto-edit";
import { updateShipsRefreshedTime } from "../ShipToMainPage/slice-shipto";
import { postDataAndPutInStore } from "../../../utilities/postData";
import { SHIPTO_STORE } from "../../../utilities/indexedDB";
import { updateBackendAndStore } from "../../../utilities/updateData";

// to control from 
const initShipToData = {
    shipto_destination_name: "",
    // shipto_destination_number: "",
    // shipto_destination_location_code: "",
    shipto_email_address: "",
    shipto_address_1: "",
    shipto_address_2: "",
    shipto_address_3: "",
    shipto_fax_number: "",
    shipto_phn_number: "",
    shipto_print_qrl: false,
    shipto_id: ""
}


const ShipToEditOverlay = () => {
    const dispatch = useDispatch();
    const shipToData = useSelector((state) => state.shipToEdit.shipToData)
    const [isOpen, setIsOpen] = useState(false)
    const [updatedShipToData, setupdatedShipToData] = useState(initShipToData);

    useEffect(() => {
        if (shipToData === null) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
            setupdatedShipToData({...initShipToData, ...shipToData});
          }

        return () => {
            setIsOpen(false)
        }
      }, [shipToData]);
      

    // send the null shipRef id to close the drawer.
    const handleCloseDrawer = () => {
        dispatch(clearEditShipToData());
    }

    const handleChange = (e) => {
        // to implement
        const key = e.target.name
        const val = e.target.value
        setupdatedShipToData({...updatedShipToData, [key]:val})
    }

    const handleSubmit = async() => {
        // await update_shipto_data_in_indexed_db(updatedShipToData)
        await updateBackendAndStore(
            process.env.REACT_APP_SHIPTO,
            updatedShipToData, 
            updatedShipToData.shipto_id,
            SHIPTO_STORE)
        dispatch(updateShipsRefreshedTime())
        handleCloseDrawer()
    }

    if (! updatedShipToData){
        return <></>
    }
 

    return (
    <Drawer
        anchor="bottom"
        open={isOpen}
        elevation={4}
        style={{
            '& .MuiDrawer-paper': {
                backgroundColor: 'transparent', // Add your desired background color here
              },
        }}
    >
        <Paper elevation={3} >
            <AppBar position="sticky" top={0} 
                sx={{
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem"
                }}
                >
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }} component="div">
                        Edit: {updatedShipToData.shipto_id}
                    </Typography>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 2 }}
                        onClick={handleCloseDrawer}
                    >
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Container>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12} sx={{mt:4}} >
                        <div>
                            <Typography variant="subtitle1" align="center">
                                <DirectionsBoatOutlined/>
                                Ship To
                            </Typography>
                            <Typography variant="h6" align="center">
                                {updatedShipToData.shipto_id}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            name="shipto_destination_name"
                            value={updatedShipToData.shipto_destination_name}
                            onChange={handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Address Line 1"
                            variant="outlined"
                            name="shipto_address_1"
                            value={updatedShipToData.shipto_address_1}
                            onChange={handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Address Line 2"
                            variant="outlined"
                            name="shipto_address_2"
                            value={updatedShipToData.shipto_address_2}
                            onChange={handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Address Line 3"
                            variant="outlined"
                            name="shipto_address_3"
                            value={updatedShipToData.shipto_address_3}
                            onChange={handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Phone number"
                            variant="outlined"
                            name="shipto_phn_number"
                            value={updatedShipToData.shipto_phn_number}
                            onChange={handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Fax number"
                            variant="outlined"
                            name="shipto_fax_number"
                            value={updatedShipToData.shipto_fax_number}
                            onChange={handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Email Address"
                            variant="outlined"
                            name="shipto_email_address"
                            value={updatedShipToData.shipto_email_address}
                            onChange={handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12} >
                        <FormGroup sx={{ textAlign: 'center'}}>
                            <FormControlLabel
                                control={
                                <Checkbox 
                                    checked={updatedShipToData.shipto_print_qrl} 
                                    onChange={() => setupdatedShipToData({...updatedShipToData, shipto_print_qrl: !updatedShipToData.shipto_print_qrl})} 
                                    name="Print QRL" />
                                }
                                label="Print QRL"
                            />
                        </FormGroup>
                    </Grid>
                    
                    <Grid item xs={12} sx={{ mb:4 }}>
                        <Button 
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit} 
                            color="primary"
                            startIcon={<Refresh />}
                        >
                            Update
                        </Button>
                        </Grid>
                </Grid>
            </Container>

        </Paper>
    </Drawer>
          
    )
}

export default ShipToEditOverlay;