import React, { useState } from 'react';
import { Button, Drawer, Grid, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ViewListIcon from '@mui/icons-material/ViewList';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { HighlightOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router';
import { setAlert, setError } from '../../../components/alert-message/alerMessageSlice';
import { fetchDataFromUrl } from '../../../utilities/fetchData';
import { cancelAsn, emailMplBol, getActiveAsn } from '../removeUpdateAsnUtil';
import { clearLoadingMessage, setLoadingMessage } from '../../../components/loading-dialog/loadingMessageSlice';
import { closeAsn } from '../closeAsn';
import { refreshAsnHistoryPage } from '../asnSlice';
import { ASN_CREATED } from '../../../components/LABEL_CHOICES';


const AsnDrawerButton = ({ asnData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };

  const generateTextData = async() => {
    const export_asn_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_EXPORT_ASN_ROUTE}/${asnData.asn_id}/`
    try{
      dispatch(setLoadingMessage("generating ASN..."))
      const asn_text = await fetchDataFromUrl(
        export_asn_url
      )
      return asn_text
    }catch(err){
      console.error(err)
      dispatch(setError("Error: while exporting ASN, Try Again!"))
    }finally{
      dispatch(clearLoadingMessage())
    }    
  };

  const handleDownload = async() => {
    try{
      const textData = await generateTextData();
      const blob = new Blob([textData], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `asn_${asnData.asn_id}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }catch(err){
      console.error(err)
    }
  };

  const buttonData = [
    { label: 'View LPN', icon: <ViewListIcon />, callback: () => handleButtonClick('View LPN') },
    { label: 'Export ASN', icon: <CloudDownloadIcon />, callback: handleDownload },
    { label: 'Email MPL & BOL', icon: <EmailIcon />, callback: () => handleButtonClick('Email MPL & BOL') },
    { label: 'Close', icon: <CloseIcon />, callback: () => handleButtonClick('Close') },
    { label: 'Cancel', icon: <CancelIcon />, callback: () => handleButtonClick('Cancel') },
  ];

  const handleButtonClick = async(buttonLabel) => {
    // Handle button click here, e.g., call a function or perform an action
    // console.log(`Clicked: ${buttonLabel}`);

    if (buttonLabel === 'View LPN'){
      localStorage.setItem('asnRef', JSON.stringify(asnData.asn_id));
      navigate('/create-asn/add-qrl')
    }else if (buttonLabel === 'Export ASN') {
      alert(`Todo: ${buttonLabel}`)
    }else if (buttonLabel === 'Email MPL & BOL'){
      const email_mpl_bol_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_EMAIL_MPL_BOL}/${asnData.asn_id}/`

      dispatch(setLoadingMessage("sending email!"))
      fetchDataFromUrl(email_mpl_bol_url)
      .then(response => {
        console.log(response)
        dispatch(setAlert({severity: "success", message:response?.message}))
      }).catch(err => {
        console.error(err) 
        dispatch(setError('Facing error while sending email!'))
      }).finally(() => {
        dispatch(clearLoadingMessage())
      })
    }
    else if (buttonLabel === 'Close'){
      await closeAsn(asnData.asn_id);
    }else if (buttonLabel === 'Cancel'){
      if (asnData?.asn_status === ASN_CREATED){
      await cancelAsn(asnData.asn_id)
    }else{
      dispatch(setError("ASN can be cancelled if OPEN only!"))
    }
    }else {
      dispatch(setAlert({message:`Clicked: ${buttonLabel}`}))  
    }
    dispatch(refreshAsnHistoryPage())
    setOpen(false)
  };

  const list = (
    <div>
      {buttonData.map((item, index) => (
          <ListItem key={item.label}>
            <ListItemButton onClick={item.callback} divider >
              <ListItemIcon >
                {item.icon}
              </ListItemIcon>
              <ListItemText>
                <Typography variant="button">{item.label}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
      ))}
    </div>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon />
      </Button>
      <Drawer 
        anchor="bottom" 
        open={open} 
        onClose={toggleDrawer(false)}
        >
        <IconButton style={{backgroundColor: "transparent"}} onClick={toggleDrawer(false)}>
          <HighlightOff/>
        </IconButton>
        {list}
      </Drawer>
    </div>
  );
};

export default AsnDrawerButton;
