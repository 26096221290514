import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { 
    Cancel,
    LockOpen,
    Email,
    ArrowOutward,
    Lock
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveOrder } from '../removeUpdateOrderUtil';
import { updateBackendAndStore } from '../../../utilities/updateData';
import { ORDER_STORE } from '../../../utilities/indexedDB';
import { setAlert, setError } from '../../../components/alert-message/alerMessageSlice';
import { clearLoadingMessage, setLoadingMessage } from '../../../components/loading-dialog/loadingMessageSlice';
import { ORDER_ASSIGNED, ORDER_CANCELLED, ORDER_COMPLETED, ORDER_CREATED } from '../../../components/LABEL_CHOICES';
import { refreshLabelPage } from '../orderSlice';


const CompleteOrderButton = () => {
  const dispatch = useDispatch()
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [closedDialogOpen, setClosedDialogOpen] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [isClosed, setIsClosed] = useState(false);
  const pageRefresh = useSelector(state => state.order.refreshedLabelPageTime)

  const handleConfirmationOpen = () => {
    // do not complete order if there is no label
    getActiveOrder()
    .then(order => {
      if (order?.order_label_ids.length !== 0){
        if (!isClosed) {
          setConfirmationOpen(true);
        }
      }else{
        dispatch(setError("No label attached!"))
      }
    }).catch(err => {
      console.log(err)
    })
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  useEffect(() => {
    getActiveOrder()
    .then(order => {
      setOrderData(order)
      setIsClosed(order?.order_status !== ORDER_CREATED)
    })
  },[pageRefresh])

  const handleConfirmed = async() => {
    const orderData = await getActiveOrder();
    const order_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`
    dispatch(setLoadingMessage("Updating order"))
    updateBackendAndStore(
      order_url,
      {order_status: "COMPLETED"},
      orderData.order_id,
      ORDER_STORE
    ).then((order) => {
      if (order?.order_status === 'COMPLETED'){
        dispatch(setAlert({severity:"success", message:`Order: ${order.order_id} Status update`}))
      }
    }).catch(err => {
      console.error(err)
      dispatch(setError("Error updating order status!"))
    }).finally(() => {
      dispatch(clearLoadingMessage())
      setConfirmationOpen(false);
      setClosedDialogOpen(true);
      dispatch(refreshLabelPage());
    })
  }

  const handleCloseDialogClose = () => {
    setClosedDialogOpen(false);
  };

  return (
    <div style={{ backgroundColor: 'white', opacity: 1, position: 'fixed', bottom: 0, left: 0, width: '100%', textAlign: 'center', padding: '1rem', paddingBottom: '2rem' }}>

      {
        orderData?.order_status === ORDER_CREATED &&
        <Button
        variant="contained"
        color="primary"
        onClick={handleConfirmationOpen}
        startIcon={<LockOpen />}
      >
        Complete Order
        </Button>
      }
      {
        orderData?.order_status === ORDER_COMPLETED &&
        <Button
        variant="contained"
        color="inherit"
        startIcon={<Lock />}
      >
        Order Completed
        </Button>
      }
      {
        orderData?.order_status === ORDER_ASSIGNED &&
        <Button
        variant="contained"
        color="inherit"
        startIcon={<Lock />}
      >
        Order Assigned
        </Button>
      }
      {
        orderData?.order_status === ORDER_CANCELLED &&
        <Button
        variant="contained"
        color="inherit"
        startIcon={<Cancel />}
      >
        Order Cancelled
        </Button>
      }

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to complete the order?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmed}
            color="primary"
            startIcon={<CheckCircleIcon />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Closed Dialog */}
      <Dialog open={closedDialogOpen} onClose={handleCloseDialogClose}>
        <DialogTitle>Order Completed</DialogTitle>
        <DialogContent>
          The order has been completed.
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompleteOrderButton;
