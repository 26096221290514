export function labelFilterMapFn(itemList) {
    return {
      "status": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.label_status)) {
            return true;
          }
        },
        key: "label_status",
        filter_values: [...new Set(itemList.map((item) => item.label_status))].sort(),
        values: [...new Set(itemList.map((item) => item.label_status))].sort(),
        name: "Status"
      },
      "type": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.label_type)) {
            return true;
          }
        },
        key: "label_type",
        filter_values: [...new Set(itemList.map((item) => item.label_type))].sort(),
        values: [...new Set(itemList.map((item) => item.label_type))].sort(),
        name: "type"
      }
    };
  }