import React, {useEffect, useState} from "react";
import { AppBar, Toolbar, Typography, IconButton, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import DeleteConfirmationOverlay from "./DeleteConfirmationOverlay";

const DeleteHeader = ({ 
    onDeleteClick,
    onCloseClick 
}) => {
    const theme = useTheme();
    const [confirmOverlayOpen, setConfirmOverlayOpen] = useState(false);
    const [isConfirmed, setIsConirmed] = useState(false);
    const deleteState = useSelector((state) => state.partDelete.deleteList)||[];
    const selectedCount = deleteState.length;

    const handleDeleteClick = () => {
      setConfirmOverlayOpen(true);
    }

    useEffect(() => {
      if (isConfirmed){
        onDeleteClick()
        setIsConirmed(false); // reset the isConfirmed state
        // onCloseClick();
      }
    }, [isConfirmed])

    return (
      <>
        <AppBar
          position="fixed"
          style={{
            top: "auto",
            bottom: 0,
            height: '80px',
            backgroundColor: theme.palette.primary.main,
            boxShadow: "0px -2px 8px rgba(0, 0, 0, 0.2)", // Add a subtle shadow
          }}
        >
          <Toolbar style={{        
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
            }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={onCloseClick}
            >
              <ArrowBack/>
              {/* <CloseIcon /> */}
            </IconButton>
            <Typography variant="h6" component="div" >
              {selectedCount} selected
              <DeleteConfirmationOverlay
                isOpen={confirmOverlayOpen}
                setIsOpen={setConfirmOverlayOpen}
                setIsConfirmed={setIsConirmed}
                />
            </Typography>
            <Button color="inherit" onClick={handleDeleteClick}>Delete</Button>
          </Toolbar>
        </AppBar>
    </>
  );
};

export default DeleteHeader;
