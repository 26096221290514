import React, { useState, useEffect } from "react";
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Fab,
  Grid,
  useTheme
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { ArrowRightAltOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { ORDER_STORE } from "../../../utilities/indexedDB";
import { postDataAndPutInStore } from "../../../utilities/postData";
import { ORDER_CREATED } from "../../../components/LABEL_CHOICES";
import { is_none } from "../../../components/validations";
import { useDispatch } from "react-redux";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";
import { fetchDataFromUrl } from "../../../utilities/fetchData";
import { handleErrorResponse } from "../../../utilities/handleErrors";
import { setError } from "../../../components/alert-message/alerMessageSlice";


const AddOrderButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [debugMessage, setDebugMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shipToData, setShipToData] = useState([]);
  const [error, setFieldError] = useState(false);
  const {selectedSupplier} = useSupplierContext();

  const [orderData, setOrderData] = useState({
    "order_id": "",
    "order_shipto_id": "",
    'order_status': ORDER_CREATED
  });

  useEffect(() => {
    setOrderData({
      ...orderData,
      order_supplier_id: selectedSupplier
    })
  }, [selectedSupplier])

  const fabStyle = {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderData({
      ...orderData,
      [name]: value,
    });
  }

  useEffect(() => {

    const shipto_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_SHIPTO_ROUTE}/`
    
    dispatch(setLoadingMessage("loading shipto..."))

    fetchDataFromUrl(
      shipto_url
    ).then((shiptos) => {
      setShipToData(shiptos)
    }).catch((err) => {
      console.error(err)
      handleErrorResponse(err)
      dispatch(setError("Failed while loading shipto!"))
      
    }).finally(() => {
      dispatch(clearLoadingMessage())
    })

  },[selectedSupplier])

  function isValidOrder(orderData){
    return Boolean(orderData.order_shipto_id) && !is_none(orderData.order_id)
 }

  const handleContinue = async() => {
    
    if (!isValidOrder(orderData)){
      setFieldError(true);
      return;
    }

    postDataAndPutInStore(
      `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`,
      orderData,
      ORDER_STORE
    ).then((order) => {
      localStorage.setItem('orderRef', order.order_id)
      navigate("/create-order/add-label")
    }).catch((err) => {
      console.error(err)
      
      if (err.response && err.response.status === 400 && err.response.data.order_id) {
        dispatch(setError("Order already exists!"));
      }
    })

  }
 
  return (
    <div>
      <Fab color="primary" style={fabStyle} onClick={handleOpenDialog}>
        <AddIcon />
      </Fab>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Create Order</DialogTitle>
        <div>
          {debugMessage}
        </div>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                label="Supplier Id"
                variant="filled"
                value={orderData.order_supplier_id}
              />
            </Grid>

            <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Order Id"
              name="order_id"
              value={orderData.order_trailer}
              onChange={handleChange}
              error={error && !orderData.order_trailer} // Check if the value is empty
              helperText={error && !orderData.order_trailer ? 'Order Id cannot be empty': '' }
            />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                select
                fullWidth
                id="ship-to"
                name="order_shipto_id"
                value={orderData.order_shipto_id}
                onChange={handleChange}
                label="Ship To"
                error={error && !orderData.order_shipto_id}
                helperText={error && !orderData.order_shipto_id ? 'Please choose ShipTo' : ''}
              >
                {shipToData.map((_shipto) => (
                    <MenuItem 
                     key = {_shipto.shipto_id}
                      value={`${_shipto.shipto_id}`}>
                        {`${_shipto.shipto_id || ""}`}
                      </MenuItem>
                  ))}
              </TextField>
                  
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button 
              onClick={handleContinue} 
              color="primary" 
              variant="contained">
              Continue
            <ArrowRightAltOutlined />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddOrderButton;
