import React, { useState } from 'react';
import { Button, Drawer, Grid, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ViewListIcon from '@mui/icons-material/ViewList';
import CancelIcon from '@mui/icons-material/Cancel';
import { HighlightOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router';
import { setAlert, setError } from '../../../components/alert-message/alerMessageSlice';
import { cancelAsn } from '../removeUpdateAsnUtil';
import { ASN_CREATED } from '../../../components/LABEL_CHOICES';
import { refreshAsnPage } from '../asnSlice';


const CreateAsnCardMenu = ({ asnData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };


  const buttonData = [
    { label: 'View LPN', icon: <ViewListIcon />, callback: () => handleButtonClick('View LPN') },
    { label: 'Cancel', icon: <CancelIcon />, callback: () => handleButtonClick('Cancel') },
  ];

  const handleButtonClick = async(buttonLabel) => {

    if (buttonLabel === 'View LPN'){
      localStorage.setItem('asnRef', JSON.stringify(asnData.asn_id));
      navigate('/create-asn/add-qrl')
    }

    else if (buttonLabel === 'Cancel'){
      if (asnData?.asn_status === ASN_CREATED){
      await cancelAsn(asnData.asn_id)
    }else{
      dispatch(setError("ASN can be cancelled if OPEN only!"))
    }
    }else {
      dispatch(setAlert({message:`Clicked: ${buttonLabel}`}))  
    }
    dispatch(refreshAsnPage())
    setOpen(false)
  };

  const list = (
    <div>
      {buttonData.map((item, index) => (
          <ListItem key={item.label}>
            <ListItemButton onClick={item.callback} divider >
              <ListItemIcon >
                {item.icon}
              </ListItemIcon>
              <ListItemText>
                <Typography variant="button">{item.label}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
      ))}
    </div>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon />
      </Button>
      <Drawer 
        anchor="bottom" 
        open={open} 
        onClose={toggleDrawer(false)}
        >
        <IconButton style={{backgroundColor: "transparent"}} onClick={toggleDrawer(false)}>
          <HighlightOff/>
        </IconButton>
        {list}
      </Drawer>
    </div>
  );
};

export default CreateAsnCardMenu;
