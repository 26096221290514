import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store'; // Import your Redux store
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styleguide.css';
import './index.css';
import { UserProvider } from './pages/UserPage/UserContext';
import { ThemeProviderContext } from './theme/ThemeProviderContext';
import { GattServerProvider } from './components/PrinterLabel/GattServerContext';
import { SupplierProvider } from './components/supplier-context/SupplierThemeContext';

// Call the service worker registration function 🧐
registerServiceWorker();

export function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('./service-worker.js', {scope: "./"})
        .then((registration) => {
          console.log('Service Worker registered:', registration);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  } else {
    console.log('Service Worker not supported.');
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <UserProvider>
        <ThemeProviderContext>
            <GattServerProvider>
              <SupplierProvider >
                <App />
              </SupplierProvider>
            </GattServerProvider>
          </ThemeProviderContext>
      </UserProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
