import React, { useState, useEffect } from "react";
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Fab,
  Grid,
  useTheme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ArrowRightAltOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { getAllItemFromStore } from "../../../utilities/getFromStore";
import { ASN_STORE, SHIPTO_STORE } from "../../../utilities/indexedDB";
import { postDataAndPutInStore } from "../../../utilities/postData";
import { ASN_CREATED } from "../../../components/LABEL_CHOICES";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";
import { fetchDataFromUrl } from "../../../utilities/fetchData";
import { useDispatch } from "react-redux";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";
import { handleErrorResponse } from "../../../utilities/handleErrors";


const AddAsnDialogButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shipToData, setShipToData] = useState([]);
  const [error, setError] = useState(false);
  const {selectedSupplier} = useSupplierContext()

  const [asnData, setAsnData] = useState({
    "asn_id": "",
    "asn_shipto_id": "",
    "asn_scac": "",
    "asn_trailer": "",
    'asn_status': ASN_CREATED
  });

  useEffect(() => {
    setAsnData({
      ...asnData,
      asn_supplier_id: selectedSupplier
    })
  }, [selectedSupplier])

  function isValidAsn(asnData){
     return Boolean(asnData.asn_scac && asnData.asn_trailer && asnData.asn_shipto_id)
  }

  useEffect(() => {
    const shipto_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_SHIPTO_ROUTE}/`

    dispatch(setLoadingMessage("loading shipto..."))

    fetchDataFromUrl(
      shipto_url
    ).then((shiptos) => {
      setShipToData(shiptos)
    }).catch((err) => {
      console.error(err)
      dispatch(setError("Failed while loading shipto!"))
    }).finally(() => {
      dispatch(clearLoadingMessage())
    })


  },[selectedSupplier])

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAsnData({
      ...asnData,
      [name]: value,
    });
  }

  const handleContinue = async() => {
    
    if (!isValidAsn(asnData)){
      setError(true);
      return;
    }

    const _response = await postDataAndPutInStore(
      process.env.REACT_APP_ASN,
      asnData,
      ASN_STORE
    )

    localStorage.setItem('asnRef', JSON.stringify(_response.asn_id))
    navigate("/create-asn/add-qrl")
    
  }
 
  const fabStyle = {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  };

  return (
    <div>
      <Fab color="primary" style={fabStyle} onClick={handleOpenDialog}>
        <AddIcon />
      </Fab>
      <Dialog fullWidth open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add ASN</DialogTitle>
        <div></div>
        <DialogContent>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <TextField 
                fullWidth
                disabled
                label="Supplier Id"
                value={asnData.asn_supplier_id}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                select
                fullWidth
                id="ship-to"
                name="asn_shipto_id"
                value={asnData.asn_shipto_id}
                onChange={handleChange}
                label="Ship To"
                error={error && !asnData.asn_shipto_id}
                helperText={error && !asnData.asn_shipto_id ? 'Please choose ShipTo' : ''}
              >
                {shipToData.map((_shipto) => (
                    <MenuItem 
                     key = {_shipto.shipto_id}
                      value={`${_shipto.shipto_id}`}>
                        {`${_shipto.shipto_id || ""}`}
                      </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="SCAC"
                fullWidth
                value={asnData['asn_scac']}
                name="asn_scac"
                onChange={handleChange}
                required
                error={error && !asnData.asn_scac}
                helperText={error && !asnData.asn_scac ? 'SCAC cannot be empty!': ''}
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
              required
              label="Trailer"
              name="asn_trailer"
              fullWidth
              value={asnData.asn_trailer}
              onChange={handleChange}
              error={error && !asnData.asn_trailer} // Check if the value is empty
              helperText={error && !asnData.asn_trailer ? 'Trailer cannot be empty': '' }
            />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button 
              onClick={handleContinue} 
              color="primary" 
              variant="contained">
              Continue
            <ArrowRightAltOutlined />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAsnDialogButton;
