// indexedDB.js

export const DB_NAME = "SupplierDB";
export const PARTS_STORE = "partsStore";
export const SYNC_TASKS_STORE = "syncTasksStore";
export const SHIPTO_STORE = "shipToStore";

export const LABEL_STORE = "labelStore";
export const ORDER_STORE = "orderStore";
export const QRL_STORE = "qrlStore";

export const ASN_STORE = "asnStore";

export const SYNC_TYPES = {
  ADD_PART: "add-part",
  UPDATE_PART: "update-part",
  DELETE_PART: "delete-part",
  // Add more sync types as needed
};

export async function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onerror = (event) => {
      reject("Error opening database");
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      // Create the 'partsStore' object store
      // Create the 'syncTasksStore' object store
      db.createObjectStore(PARTS_STORE, {keyPath: "part_id", autoIncrement: true });
      
      db.createObjectStore(SYNC_TASKS_STORE, {keyPath: "task_id", autoIncrement: true });
      db.createObjectStore(SHIPTO_STORE, { keyPath: "shipto_id", autoIncrement: true });
      
      db.createObjectStore(LABEL_STORE, {keyPath: "label_id", autoIncrement: true});
      db.createObjectStore(ORDER_STORE, {keyPath: "order_id", autoIncrement: true});
      db.createObjectStore(QRL_STORE, {keyPath: "label_id", autoIncrement: true});

      db.createObjectStore(ASN_STORE, {keyPath: "asn_id", autoIncrement: true});
        };
  });
}


