import { openDB } from "./indexedDB";
import { setAlert } from "../components/alert-message/alerMessageSlice";
import { handleErrorResponse } from "./handleErrors";
import { clearLoadingMessage, setLoadingMessage } from "../components/loading-dialog/loadingMessageSlice";
import store from "../store";
import axios from "axios";

const dispatch = store.dispatch;

export async function deleteDataAndRemoveFromStore(apiUrl, referenceId, storeName) {
  try {
    dispatch(setLoadingMessage("Deleting from backend..."));
    await deleteDataFromBackend(apiUrl, referenceId);

    dispatch(setLoadingMessage("Deleting from store..."));
    await deleteItemFromStore(storeName, referenceId);
    dispatch(setAlert({ severity: "success", message: "Item deleted!" }));

  } catch (error) {
    const message = error.response ? error.response.data.detail : "Network or server error";
    console.error('Error:', message);
    dispatch(setAlert({ severity: "error", message: message }));
  } finally {
    dispatch(clearLoadingMessage());
  }
}

async function deleteDataFromBackend(apiUrl, referenceId) {
    const accessToken = JSON.parse(localStorage.getItem('userData'))['access_token']
    const deleteUrl = `${apiUrl}${referenceId}/`;
    console.log(deleteUrl);
    try {
      const response = await axios.delete(deleteUrl,{
        headers:{
          Authorization: `Bearer ${accessToken}`
        },
        withCredentials : true
      });
      if (response.status === 204) {
        const _message = `Item with ID ${referenceId} deleted from server.`
        dispatch(setAlert({severity:"success", message:_message}));
      } else {
        return false;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }
  

async function deleteItemFromStore(storeName, referenceId) {
    try{
        const db = await openDB();
        const tx = db.transaction(storeName, "readwrite");
        const store = tx.objectStore(storeName);
        store.delete(referenceId);
    } catch (error) {
        throw error;
    }
  }



