import { Alert, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearLoadingMessage, setLoadingMessage } from '../loading-dialog/loadingMessageSlice';
import { setAlert } from '../alert-message/alerMessageSlice';
import { printLabel, printZpl } from './printerUtils';
import { setPrinterStatus } from './printerSlice';
import { useGattServer } from './GattServerContext';

const DirectGattConnect = () => {
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useState(null);
    // const [gattServer, setGattServer] = useState(null);
    const {gattServer, setGattServer} = useGattServer();
    const printerStatus = useSelector((state) => state.printer.status);
    // const gattServer = useSelector((state) => state.printer.gattServer);
    const selectedServiceUUID =  '38eb4a80-c570-11e3-9507-0002a5d5c51b' // Specific service UUID
    const TestZpl = '^XA^FO0,0^A0,30,30^FDHello, Supplier Label & Shipping App^FS^XZ'

    async function scanBluetooth() {

        try {
            dispatch(setLoadingMessage(`select device...`))
            dispatch(setPrinterStatus("Scanning"))

            const device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: true, // Allow all devices
                // filters: [
                //     {
                //         services: [selectedServiceUUID],
                //     },
                // ],
                optionalServices: [selectedServiceUUID], // Specify the service UUID as optional
            });

            if(!device || device === undefined ) throw Error("No device found")
            if(!device.gatt) throw Error('Device does not support Bluetooth GATT')
            
            return device;
        }catch (error) {
            console.error('Printer error:', error);
            setErrorMessage('Failed to connect to the Bluetooth device: ' + error.message);
            dispatch(setPrinterStatus('Error'))
            return null;
        }finally{
            dispatch(clearLoadingMessage());
        }
    }

    async function connectGatt(device){
    try {
        dispatch(setLoadingMessage(`connecting ${device.name}`))
        dispatch(setPrinterStatus('Connecting'))
        // connect the gatt server
        const server = await device.gatt.connect()

        console.log(server);
        dispatch(setAlert({severity:"success", message:`Connected: ${device.name}` }))
        // dispatch(setGattServer(server));
        setGattServer(server);
        dispatch(setPrinterStatus('Connected'))
            
        } catch (error) {
            console.error('Printer error:', error);
            setErrorMessage('Failed to connect to the Bluetooth device: ' + error.message);
            dispatch(setPrinterStatus('Error'));
        }finally{
            dispatch(clearLoadingMessage());
        }
    }

    async function connectToBluetoothDevice() {
        setErrorMessage(null);
        const device = await scanBluetooth();
        if (device) await connectGatt(device);
    }

    return (
        <Grid item xs={12} >
            <Typography variant="body2" align="center">
                Printer: {printerStatus}
            </Typography>
            <Typography variant="h6" align="center">

            <Button variant="outlined" onClick={() => connectToBluetoothDevice()}>Scan </Button>
            </Typography>
            
            {errorMessage && <Alert severity='error' sx={{mt:1}}>{errorMessage}</Alert>}
        </Grid>
    );
};

export default DirectGattConnect;
