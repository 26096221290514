 // ErrorHandlingComponent.js
import React, {useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { clearLoadingMessage, setLoadingMessage } from '../../components/loading-dialog/loadingMessageSlice';
import { clear401Error } from './error401Slice';
  
  const Error401Redirect = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const select401Error = useSelector((state) =>  state.error401)

    const handle401Error = useCallback(() => {
      let loaderTimeout;
      let redirectTimeout;
  
      if (select401Error.has401Error) {
        loaderTimeout = setTimeout(() => {
          dispatch(setLoadingMessage("Redirecting to the login page..."));
        }, 1000);
  
        redirectTimeout = setTimeout(() => {
          navigate("/login", { replace: true });
          dispatch(clearLoadingMessage());
          dispatch(clear401Error());
        }, 2000);
      }
  
      return () => {
        // Clear the timeouts if the component is unmounted or conditions change
        clearTimeout(loaderTimeout);
        clearTimeout(redirectTimeout);
      };
    }, [dispatch, navigate, select401Error]); // Include select401Error in the dependencies array
  
    useEffect(() => {
      const cleanup = handle401Error(); // Call the memoized function
  
      return cleanup; // Cleanup function will be called on unmount or when select401Error changes
    }, [handle401Error]); // Include the memoized function in the dependencies array

    return (
        <></>
    );
  };
  
  export default Error401Redirect;
  