import { createSlice } from '@reduxjs/toolkit';

// Define the initial state with a default severity of "info"
const initialState = {
  severity: 'info',
  message: null,
  alert_time: null
};

// Define an array of valid severity values
const validSeverityValues = ['info', 'success', 'warning', 'error']; // Add other valid values as needed

// Create a Redux slice
const alertSlice = createSlice({
  name: 'alert', // Name of the slice
  initialState,  // Initial state
  reducers: {
    setAlert: (state, action) => {
      // Check if the provided severity is valid, otherwise default to "info"
      state.severity = validSeverityValues.includes(action.payload.severity)
        ? action.payload.severity
        : 'info';
      state.message = action.payload.message || '';
      state.alert_time = new Date().toISOString();
    },
    setError: (state, action) => {
      state.severity = 'error';
      state.message = action.payload || '';
      state.alert_time = new Date().toISOString();
    },
    clearAlert: (state) => {
      // Clear the severity and message
      state.severity = 'info';
      state.message = null;
      state.alert_time = null;
    },
  },
});

// Export the action creators
export const { setAlert, setError, clearAlert } = alertSlice.actions;

// Export the reducer
export default alertSlice.reducer;

// // Thunk action to clear the alert after a specified timeout
// export const clearAlertAfterTimeout = (timeoutInMilliSeconds) => (dispatch) => {
//     setTimeout(() => {
//       dispatch(clearAlert());
//     }, timeoutInMilliSeconds);
//   };