export default function partFilterMapFn(parts) {
    return {
      "part_number": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.part_number?.substring(0, 5))) {
            return true;
          }
        },
        key: "part_number",
        filter_values: [...new Set(parts.map((part) => part.part_number?.substring(0, 5)))].sort(),
        values: [...new Set(parts.map((part) => part.part_number?.substring(0, 5)))].sort(),
        name: "Part Number (first 5 digits)"
      },
      "color": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.color_code)) {
            return true;
          }
        },
        key: "color_code",
        filter_values: [...new Set(parts.map((part) => part.color_code))].sort(),
        values: [...new Set(parts.map((part) => part.color_code))].sort(),
        name: "Color"
      }
    };
  }
  