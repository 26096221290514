import React, { useState, useEffect } from "react";
import { 
  Grid, 
  Container,
  useTheme,
  Divider
} from "@mui/material";
import HeaderMui from "../../../components/headers/mui-header/HeaderMui";
import { useSelector } from "react-redux";

import AddQrlDialogButton from "./AddQrlDialogButton";
import TextItem from "../../../components/TextItem";
import SelectedQrlCard from "./SelectedQrlCard";
import CloseTrailerButton from "./CloseTrailerButton";
import { getActiveAsn } from "../removeUpdateAsnUtil";
import { ASN_SHIPPED } from "../../../components/LABEL_CHOICES";


const AddQrlPage = () => {
  const theme = useTheme();
  const [asnData, setAsnData] = useState({});
  const [qrlIdList, setQrlIdList] = useState([]);
  const [refreshQrlCard, setRefreshQrlCard] = useState(new Date().toISOString());
  const pageRefresh = useSelector((state) => state.asn.refreshedQrlPageTime)

  const fetchData = async() => {
    try {
      const _asnData = await getActiveAsn()

      setAsnData(_asnData);
      setQrlIdList(_asnData?.asn_qrl_ids)
      setRefreshQrlCard(new Date().toISOString());

    } catch (error) {
      console.error('Error fetching QRL data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageRefresh]);


  return (
    <div >
        <HeaderMui label={"ASN Details"} backLink={true}/>

        <Container sx={{marginTop: theme.spacing(2)}} >
          <Grid container spacing={theme.spacing(2)}>
          <TextItem itemKey="Asn Id" itemValue={asnData?.asn_id} xsSize={3} mdSize={3}/>
          <TextItem itemKey="ShipTo Id" itemValue={asnData?.asn_shipto_id} xsSize={4} mdSize={4}/>
          <TextItem itemKey="Trailer" itemValue={asnData?.asn_trailer} xsSize={2} mdSize={2}/>
          <Grid item xs={3} md={3} align="right">
            <AddQrlDialogButton shiptoId={asnData?.asn_shipto_id}/>
          </Grid>
          
            <Grid item xs={12}>
              {qrlIdList.map((qrlId, index) => (
                <div key={qrlId} index={`${qrlId}-${index}`}>
                  <SelectedQrlCard 
                    refreshTrigger={refreshQrlCard}
                    asnId={asnData?.asn_id} 
                    qrlId={qrlId} />
                </div>
              ))}
            </Grid>

            <Grid item xs={12}>
              <div style={{height: '5rem'}}></div>
                <CloseTrailerButton />
            </Grid>
          </Grid>
        </Container>
    </div>
  );
};

export default AddQrlPage;


