
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Menu, MenuItem } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useLongPress } from "use-long-press";

import { PmAddOverlayMUI } from "../PmAddOverlay/PmAddOverlay";

import { PartDetailCardMUI } from '../PartDetailCard/PartDetailCardMUI'

import {
  clearDelete,
  removeFromDelete,
  selectDeleteState
} from "../PartDeleteHeader/partDeleteSlice";

import partFilterMapFn from "./partFilterMapFn";

import DeleteHeader from "../PartDeleteHeader/DeleteHeader";
import { setAlert } from "../../../components/alert-message/alerMessageSlice";
import SearchHeaderUI from "../../../components/headers/search-header/SearchHeader";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import { PARTS_STORE } from "../../../utilities/indexedDB";
import { getAllItemFromStore } from "../../../utilities/getFromStore";

import { setPartsRefresh } from "./partsSlice";
import { deleteDataAndRemoveFromStore } from "../../../utilities/removeData";
import "./style.css";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import { useNavigate } from "react-router";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";
import FabSpeedDial from "./FabSpeedDial";

export const PmMainPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {selectedSupplier} = useSupplierContext()

    const deleteState = useSelector(selectDeleteState);
    const partUpdatedTimestamp = useSelector((state) => state.parts.parts_timestamp);

    const [parts, setParts] = useState([]);
    
  
    const [isAddOverlayOpen, setIsAddOverlayOpen] = useState(false);
    const [isLongPressActive, setIsLongPressActive] = useState(false);

    const [partFilterMap, setPartFilterMap] = useState({});
  
    const [isSearchingFiltering, setIsSearchingFiltering] = useState(false);  
    const [searchValue, setSearchValue] = useState("");
    const [searchedFilteredParts, setSearchedFilteredParts] = useState([]);

    const handleRefresh = async () => {
      const _parts = await getAllItemFromStore(PARTS_STORE, 'part_number')
      if (_parts) setParts(_parts);
      
    };

    usePermissionCheck({
      requestType: 'view',
      tableName:'partstable',
      onClose: () => navigate("/"),
      message: "You don't have permission to view parts, check with your administrator!"
    })

    // FAB
    const handleAddSelect = () => {
      setIsAddOverlayOpen(true);
    };
  
    const handleDeleteSelect = () => {
      setIsLongPressActive(true);
    };
  


    useEffect(() => {
      handleRefresh();
    }, [partUpdatedTimestamp])

    useEffect(() => {
      if(!selectedSupplier) return
      
      dispatch(setLoadingMessage(`fetching parts for supplier: ${selectedSupplier}`))

      fetchDataAndStore(
          process.env.REACT_APP_PART,
          PARTS_STORE,
          'part_number'
      ).then((parts) => {
        setParts(parts)
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        dispatch(clearLoadingMessage())
      })
      
    },[selectedSupplier])

    // Handling delete click
    const handleDeleteClick = async () => {
      const numParts = deleteState.length;
      if (numParts === 0) {
        dispatch(setAlert({severity: 'warning', message:'Nothing to delete!'}))
        return;
      }

      for (const partId of deleteState) {
        await deleteDataAndRemoveFromStore(
          process.env.REACT_APP_PART,
          partId,
          PARTS_STORE
        )
        dispatch(removeFromDelete(partId));
        
      }
      console.log("Deleted All parts");
      handleCloseLongPressedBar();

      setTimeout(() => {
        dispatch(setAlert({severity: 'success', message: `${numParts} part/s deleted`})); 
        dispatch(setPartsRefresh());
      }, 2000); 
    }

    const handleCloseLongPressedBar = () => {
      dispatch(clearDelete());
      setIsLongPressActive(false);
    }

  const longPressProps = useLongPress(()=>{
    setIsLongPressActive(true)
  });

  useEffect(() => {
    setPartFilterMap(partFilterMapFn(parts));
    setSearchedFilteredParts(parts);
  },[parts])

  useEffect(() => {
    const searchedParts = applySearch(searchValue, 
                ['part_number', 'part_description', 'color_code'],
                parts);
    const filteredParts = filterItems(searchedParts, partFilterMap)
    setSearchedFilteredParts(filteredParts);

  },[searchValue, parts, partFilterMap])

  const partsListSection = (items) => {
    return (
      <div className="parts-list-section">
          {items.map((part) => (
            <div key={part.part_id } {...longPressProps()}>
              <PartDetailCardMUI
                checkbox={isLongPressActive}
                editButton={!isLongPressActive}
                partId={part.part_id}
                partNumberValue={part.part_number}
                colorValue={part.color_code}
                descriptionValue={part.part_description}
                skeleton={false}
              />
            </div>
          ))}
          <div style={{marginBottom: "80px"}}></div>
        </div> 
        )
  }

  return (
      <div >
        <SearchHeaderUI
            label={`Part Master (Total: ${parts.length})`}
            shownData={searchedFilteredParts}

            setSearchValue={setSearchValue}
            setIsSearching={setIsSearchingFiltering}

            filterMap={partFilterMap}
            setFilterMap={setPartFilterMap}
            // setFilterOverlayOpen={setIsFilterOverlayOpen}
          />

        <Container>
        {/* <div className="parts-list-section">
          <div>Updated at: {formatTimestampToDateTime(partUpdatedTimestamp)}</div>
          <Button onClick={handleRefresh}>Refresh Page</Button>
          <Button onClick={() => triggerBackgroundSync()}>Sync Backend</Button>
        </div> */}
      
      {isSearchingFiltering ? partsListSection(searchedFilteredParts): partsListSection(parts)}

      {isLongPressActive && 
        <DeleteHeader 
          onDeleteClick={handleDeleteClick}
          onCloseClick={handleCloseLongPressedBar}/>
      }

      {/* {!isLongPressActive && 
        
      } */}

    <div>
        {!isLongPressActive &&
          <FabSpeedDial 
            onAddClick={handleAddSelect} 
            onDeleteClick={handleDeleteSelect}
            />
          }
        </div>

        {isAddOverlayOpen && (<PmAddOverlayMUI
            isOpen={isAddOverlayOpen} 
            setIsOpen={setIsAddOverlayOpen}/>)}

      </Container>
    </div>
  );
};

const filterItems = (items, partFilterMap) => {
  return items.filter(item => {
    for (const key in partFilterMap) {
      const filterConfig = partFilterMap[key];
      if (!filterConfig.funct(filterConfig.filter_values, item)) {
        return false;
      }
    }
    return true;
  });
};


function applySearch(searchValue, searchKeys, data){
  if (!searchValue || searchValue === "" || !searchKeys.length) {
    return data;
  }
  
  const query = searchValue.toLowerCase();
  const filtered = data.filter((item) => {
    for (const key of searchKeys) {
      if (item[key] && item[key].toLowerCase().includes(query)) {
        return true; // Match found in this key, include the item
      }
    }
    return false; // No matches found in any of the specified keys
  });
  return filtered;
}