import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  FormControl,
  IconButton,
  Drawer,
  Paper,
  Grid,
  useTheme,
  Divider,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterControls from "./FilterControls";
import { useDispatch } from "react-redux";
import { clearLoadingMessage, setLoadingMessage } from "../../components/loading-dialog/loadingMessageSlice";

export const FitlerOverlayMUI = ({ 
  searchedFilteredData,
  filterMap, 
  setFilterMap,
  isOpen, 
  setIsOpen }) => {
  
  const dispatch = useDispatch();
  const theme = useTheme();

  // useEffect(() => {
  //   console.log(filterMap)
  // },[filterMap])

  const handleFilter = (() => {
    setIsOpen(false);

    dispatch(setLoadingMessage("filtering..."))

    setTimeout(() => {
      dispatch(clearLoadingMessage());
    }, 1000);
  })

  const handleReset = () => {
    const updatedFilterMap = { ...filterMap }; // Create a copy of the filterMap object
    for (const key in updatedFilterMap) {
      if (updatedFilterMap.hasOwnProperty(key)) {
        const filterObj = updatedFilterMap[key];
        filterObj.filter_values = [...filterObj.values];
      }
    }
    setFilterMap(updatedFilterMap); // Update the state with the modified filterMap  
    // handleFilter()
  }
  
  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={handleCloseDrawer}
      elevation={4}
      style={{
        position: "sticky",
        top: 0
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          // padding: 8,
          overflow: 'scroll'
        }}
      >
        <AppBar 
          position="static" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }} component="div">
              Filter 
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container
          fixed
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            overflow: "auto",
            marginTop: "32px"
          }}
        >

          <FormControl variant="standard" fullWidth>
            <Grid
              style={{paddingBottom: 16}}
              container 
              spacing={2}
              >
              {filterMap && Object.keys(filterMap).map((key) => (
                <React.Fragment key={key}>
                  <Grid item xs={12}>
                    <FilterControls
                      filterKey={key}
                      filterMap={filterMap}
                      setFilterMap={setFilterMap}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>

            <Grid container spacing={2}
              style={{ 
                    paddingBottom: 32,
                    marginBottom: 0, 
                    marginTop: 16, 
                    bottom: "0px",  
                    backgroundColor: "#fff" }}>
              <Grid item xs={8}>
                <Button
                size="large"
                variant="contained" 
                color="primary" 
                onClick={handleFilter}
                fullWidth
                >
                  Show {searchedFilteredData?.length} results
                </Button>
            </Grid>
              <Grid item xs={2}>
                <Button onClick={() => {
                  handleReset()
                  }}
                  >Reset</Button>
              </Grid>
            </Grid>
            
        </FormControl>
        </Container>
      </Paper>
    </Drawer>
  );
};