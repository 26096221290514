import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";

import OrderHistoryCardMenu from "./OrderHistoryCardMenu";
import TextItem from "../../../components/TextItem";

const OrderHistoryCard = ({
  orderData,
  skeleton = true,
}) => {
  const theme = useTheme();

  const cardStyle = {
    padding: theme.spacing(1),
    border: theme.shadows[24],
    borderRadius: theme.spacing(2),
    margin: theme.spacing(1),
    flexWrap: "no-wrap",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const orderItem = (itemKey, itemValue, xsSize, mdSize) =>{
    return (
      <TextItem 
        itemKey={itemKey} 
        itemValue={itemValue} 
        xsSize={xsSize} 
        mdSize={mdSize}
        skeleton={skeleton}
        />
    )
  }

  return (
    <Paper style={cardStyle}>
      <Grid container spacing={1}>
          {orderItem("Order Id", orderData?.order_id, 12, 4)}
          {orderItem("Shipto Id", orderData?.order_shipto_id, 6, 4)}
          {orderItem("Status", orderData?.order_status, 6, 4)}
      </Grid>
      <OrderHistoryCardMenu orderData={orderData} />
    </Paper>
  );
};

export default OrderHistoryCard;
