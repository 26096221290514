import React, { useState } from "react";
import {
  Paper,
  Grid,
  useTheme
} from "@mui/material"

import TextItem from "../../../components/TextItem";
import CreateAsnCardMenu from "./CreateAsnCardMenu";

const CreateAsnCard = ({ asnData, skeleton = false }) => {
  const theme = useTheme();

  const cardStyle = {
    padding: theme.spacing(1),
    border: theme.shadows[24],
    borderRadius: theme.spacing(2),
    margin: theme.spacing(1),
    flexWrap: "no-wrap",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const asnItem = (itemKey, itemValue, xsSize, mdSize) =>{
    return (
      <TextItem itemKey={itemKey} itemValue={itemValue} xsSize={xsSize} mdSize={mdSize} skeleton={false} />
    )
  }

  return (
    <Paper style={cardStyle}>
      <Grid container spacing={1}>
          {asnItem("Asn Id", asnData?.asn_id, 5, 3)}
          {asnItem("SCAC - Trailer", asnData?.asn_scac + " - "+ asnData?.asn_trailer, 5, 3)}
          {asnItem("Shipto Id", asnData?.asn_shipto_id, 5, 2)}
          {asnItem("Status", asnData?.asn_status, 5, 2)}

        <CreateAsnCardMenu asnData={asnData} />

        </Grid>
    </Paper>
  );
};

export default CreateAsnCard;


