import { makeZpl, printLabel } from "../../components/PrinterLabel/printerUtils"
import { labelQrlTemplate, labelZPLTemplate } from "../../components/PrinterLabel/zplTemplates"
import { fetchDataAndStore, fetchDataFromUrl } from "../../utilities/fetchData"
import { getItemFromStoreById } from "../../utilities/getFromStore"
import { LABEL_STORE, PARTS_STORE, QRL_STORE, SHIPTO_STORE } from "../../utilities/indexedDB"
import { postDataAndPutInStore } from "../../utilities/postData"


const copyZpl = async(zpl) => {
  // write to clipboard
  await navigator.clipboard.writeText(zpl)
  .then(() => {
      alert(`********* ZPL copied to clipboard ****** ${zpl}`)
  })
  .catch((err) => {
      console.error('Failed to copy zpl to clipboard', err);
  })
}

async function get_supplier_info(supplier_id){
    try{
      const supplier_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_SUPPLIER_INFO_ROUTE}/?supplier_id=${supplier_id}`
      const supplier_info = await fetchDataFromUrl(supplier_url)
      return supplier_info
    }catch(error){
      console.error(error)
      return {}
    }
  }


export const createQrlZpl = async(labelData, save=true) => {

  const shipto_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_SHIPTO_ROUTE}/`

    try{
      var _storedData = labelData
        // post data and put in store
      if (save){
      _storedData = await postDataAndPutInStore(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_QRL_ROUTE}/`,
        labelData,
        QRL_STORE
      )
    }
     
      // fetch complete data
      const broken_label_id = _storedData?.label_id.replace("S", "-S")

      await fetchDataAndStore(shipto_url, SHIPTO_STORE, 'shipto_id')
      const _shiptoData = await getItemFromStoreById(SHIPTO_STORE, labelData?.qrl_shipto_id)
      const _supplierInfo = await get_supplier_info(labelData?.label_supplier_id)

      const values = {
        ..._storedData,
        ..._shiptoData, 
        ..._supplierInfo,
        broken_label_id: broken_label_id,
      };

      // make zpl
      const zpl = await makeZpl(values, labelQrlTemplate)

      return zpl
    
  }catch(error){
    console.log(error)
  }

  };

  export const createAiagLabel = async(labelData, save=true) => {
    try{
    const parts_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_PARTS_ROUTE}/`
    var _storedData = labelData

    if (save){
      // transform the data in appropriate form before sending it to save.
      _storedData = await postDataAndPutInStore(
        process.env.REACT_APP_LABEL,
        labelData,
        LABEL_STORE
      );
    }

    await fetchDataAndStore(parts_url, PARTS_STORE, 'part_id')
    const _partData = await getItemFromStoreById(PARTS_STORE, _storedData?.label_part_id);
    
    const supplierInfo = await get_supplier_info(labelData.label_supplier_id)

    const values = {
      ..._partData, 
      ..._storedData, 
      ...supplierInfo,
      container_qty: _storedData?.label_container_qty,
      broken_label_id: _storedData?.label_id.replace("S", "-S")
    };

    const zpl = await makeZpl(values, labelZPLTemplate);

    return zpl
  }catch(error){
    console.error(error)
  }
}

// Function to handle printing a specific number of labels with copies
export const handleMultipleLabelPrinting = async (labelData, createZpl, gattServer) => {
    const { num_labels, num_copies } = labelData;
    var count = 1
    for (let labelIndex = 0; labelIndex < num_labels; labelIndex++) {
        
      try{
          var zpl = await createZpl(labelData);

          for (let copyIndex = 0; copyIndex < num_copies; copyIndex++) {
              // Call the function to print a label
              // alert(`******* Printing label (${count++}/${num_labels * num_copies}) :******* ${zpl}`)
              printLabel(gattServer, zpl)
              .then(() => {
                console.info(`Success Printing! ${labelIndex + 1}`)
              })
              .catch(err => {
                console.error(err)
              })
          }
      }catch (err){
        console.error(err)
      }
    }
  
    console.log(`Finished printing ${num_labels} labels with ${num_copies} copies each.`);
};




