// store.js
import { configureStore,  } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import pmOverlayReducer from './sections/partsSection/PmEditOverlay/pmEditOverlaySlice';

import partDeleteReducer from './sections/partsSection/PartDeleteHeader/partDeleteSlice';
import loadingMessageReducer from './components/loading-dialog/loadingMessageSlice';
import alertReducer from './components/alert-message/alerMessageSlice';
import error401Reducer from './utilities/error/error401Slice';

import partsReducer from './sections/partsSection/PmMainPage/partsSlice';
import shipToEditReducer from './sections/ShipToSection/ShipToEditOverlay/slice-shipto-edit';
import shipToReducer from './sections/ShipToSection/ShipToMainPage/slice-shipto';

import printerReducer from './components/PrinterLabel/printerSlice';
import orderReducer from './sections/OrderSection/orderSlice';
import asnReducer from './sections/AsnSection/asnSlice';
import labelReducer from './sections/labelSection/labelSlice';
import confirmationDialogReducer from './components/dialog-confirmation/confirmationDialogSlice';


const store = configureStore({
  reducer: {
    partDelete: partDeleteReducer,
    pmOverlay: pmOverlayReducer,
    printer: printerReducer,
    
    loadingMessage: loadingMessageReducer,
    alert: alertReducer,
    error401: error401Reducer,

    parts: partsReducer,
    shipTo: shipToReducer,
    shipToEdit: shipToEditReducer,
    asn: asnReducer,
    order: orderReducer,
    label: labelReducer,

    // confirmationDialog: confirmationDialogReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;

