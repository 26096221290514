// confirmationDialogSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  onConfirm: null,
  dialogMessage: "Are you sure you want to perform this action?",
};

const confirmationDialogSlice = createSlice({
  name: "confirmationDialog",
  initialState,
  reducers: {
    openDialog(state, action) {
      state.isOpen = true;
      state.onConfirm = action.payload.onConfirm;
      state.dialogMessage = action.payload.dialogMessage || initialState.dialogMessage;
    },
    closeDialog(state) {
      state.isOpen = false;
      state.onConfirm = null;
      state.dialogMessage = initialState.dialogMessage;
    },
  },
});

export const { openDialog, closeDialog } = confirmationDialogSlice.actions;

export default confirmationDialogSlice.reducer;
