import { useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setError } from '../alert-message/alerMessageSlice';
import { useNavigate } from 'react-router';

const usePermissionCheck = ({
  requestType,
  tableName,
  onClose,
  message = "Not Allowed!"
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkPermission = useCallback(async () => {
    let permission = false;
    const cacheKey = `${requestType}_${tableName}`;
    const cachedPermission = localStorage.getItem(cacheKey);

    if (cachedPermission !== null) {
      return JSON.parse(cachedPermission);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_CHECK_PERMISSION}/`,
        {
          'request_type': requestType,
          'table_name': tableName
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('userData')).access_token}`
          },
          withCredentials: true
        }
      );

      permission = response.data.has_permission;
      
      return permission;
    } catch (error) {
      console.error(error);
    } finally {
        localStorage.setItem(cacheKey, JSON.stringify(permission));
        return permission;
    }
  }, [requestType, tableName]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const permission = await checkPermission();

      if (!permission) {
        dispatch(setError(message));
        if (!onClose) {
          navigate("/");
        } else {
            
          onClose();
        }
      }
    };

    fetchData();

    return () => {
        
      isMounted = false;
    };
  }, [checkPermission, dispatch, navigate, onClose, message]);

  return {
    reloadPermission: useCallback(() => localStorage.removeItem(`${requestType}_${tableName}`), [requestType, tableName]),
  };
};

export default usePermissionCheck;
