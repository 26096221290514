import React, { useEffect, useState } from "react";
import { Typography, Grid, Checkbox, CircularProgress, Box, Collapse, ListItemButton, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Padding } from "@mui/icons-material";

const FilterControls = ({ filterKey, filterMap, setFilterMap }) => {
  const filterConfig = filterMap[filterKey];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleCollapseClick = () => {
    setOpen(!open);
  };

  const handleToggle = (event, value) => {
    setLoading(true);
    const updatedValues = toggleValue(filterConfig.filter_values, value);

    // Update the filterConfig with the updated values directly in filterMap
    setFilterMap((prevFilterMap) => ({
      ...prevFilterMap,
      [filterKey]: {
        ...prevFilterMap[filterKey],
        filter_values: updatedValues,
      },
    }));
    setLoading(false);
  };

  const handleSelectAll = () => {
    // Select all values
    setFilterMap((prevFilterMap) => ({
      ...prevFilterMap,
      [filterKey]: {
        ...prevFilterMap[filterKey],
        filter_values: filterConfig.values,
      },
    }));
  };

  const handleSelectNone = () => {
    // Deselect all values
    setFilterMap((prevFilterMap) => ({
      ...prevFilterMap,
      [filterKey]: {
        ...prevFilterMap[filterKey],
        filter_values: [],
      },
    }));
  };

  useEffect(() => {
    // No need to update filterConfig.filter_values here
    // since it's already maintained in the state.
  }, []);

  // Function to toggle a value in an array
  const toggleValue = (array, value) => {
    if (array.includes(value)) {
      return array.filter((v) => v !== value);
    } else {
      return [...array, value];
    }
  };

  const handleToggleAll = () => {
    setLoading(true);
    const isChecked = filterConfig.filter_values.length < filterConfig.values.length;

    // Toggle between Select All and Select None
    if (isChecked) {
      setFilterMap((prevFilterMap) => ({
        ...prevFilterMap,
        [filterKey]: {
          ...prevFilterMap[filterKey],
          filter_values: filterConfig.values,
        },
      }));
    } else {
      setFilterMap((prevFilterMap) => ({
        ...prevFilterMap,
        [filterKey]: {
          ...prevFilterMap[filterKey],
          filter_values: [],
        },
      }));
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
    
  };

  const selectAllUI = () => {
    return (
      
      <Checkbox
      checked={
        filterConfig.filter_values.length === filterConfig.values.length &&
        filterConfig.filter_values.length > 0
      }
      indeterminate={
        filterConfig.filter_values.length > 0 &&
        filterConfig.filter_values.length < filterConfig.values.length
      }
      onChange={handleToggleAll}
      style={{ marginRight: "8px"}}
      />
        
    )
  }

  return (
    <Grid item xs={12} style={{
      minHeight: "40px",
      maxHeight:"50vh", 
      overflow: "scroll",
       }}>
      <Box sx={{
        position:"absolute",
          height:"40px",
          bgcolor: 'background.paper',
          width: "100%",
          opacity: 1,
          zIndex: 1200
        }}>
        <ListItemButton 
          onClick={handleCollapseClick} 
          >
            <Typography variant="subtitle1" style={{flexGrow: 1}}>
              {filterConfig.name}:
            </Typography>
            
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </Box>

        <Collapse in={open}>
          <div style={{height:"50px"}}/>

          <div style={{ display: "flex", 
                  alignItems: "center"}}>
            
              {loading ?
                <IconButton>
                  <CircularProgress size={'30px'}/> 
                </IconButton>
                : selectAllUI()}
                <Typography variant="body1" style={{ marginRight: "8px" }}>
                  
                   {filterConfig.filter_values.length === filterConfig.values.length ? 'Deselect All' : 'Select All'}
                  <span style={{padding: "2px"}}/> ({filterConfig.filter_values.length})
                </Typography>

          </div>
          <TreeView
            aria-label="filter values"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ overflowX: 'hidden' }}
            
          >
            {Array.from(new Set(filterConfig.values)).map((value) => (
              <TreeItem
                key={value}
                nodeId={value}
                label={
                  <div>
                    <Checkbox
                      checked={filterConfig.filter_values.includes(value)}
                      onChange={(e) => handleToggle(e, value)}
                    />
                    {value ? value : 'None'}
                  </div>
                }
              />
            ))}
          </TreeView>
        </Collapse>
    </Grid>
  );
};

export default FilterControls;
