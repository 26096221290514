import React from "react";
import {
  Paper,
  Grid,
  useTheme
} from "@mui/material"

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import TextItem from "../../../components/TextItem";
import CreateOrderCardMenu from "./CreateOrderCardMenu";

const CreateOrderCard = ({ orderData, skeleton = false }) => {

  const theme = useTheme();

  const cardStyle = {
    padding: theme.spacing(2),
    border: theme.shadows[24],
    borderRadius: theme.spacing(2),
    margin: theme.spacing(1),
    flexWrap: "no-wrap",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const orderItem = (itemKey, itemValue, xsSize, mdSize) =>{
    return (
      <TextItem itemKey={itemKey} itemValue={itemValue} xsSize={xsSize} mdSize={mdSize} skeleton={false} />
    )
  }

  return (
    <Paper style={cardStyle}>
      <Grid container spacing={1} >
          {orderItem("Order Id", orderData?.order_id, 12, 5)}
          {orderItem("Shipto Id", orderData?.order_shipto_id, 5, 4)}
          {orderItem("Status", orderData?.order_status, 5, 2)}
        <Grid item xs={1}>
        <CreateOrderCardMenu orderData={orderData} />
        
        </Grid>
    </Grid>

    </Paper>
  );
};

export default CreateOrderCard;


