import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';

import {
  List,
  ListItem,
  ListItemButton,
  Divider,
  Grid,
} from '@mui/material';

import TextItem from '../../../components/TextItem';
import { fetchDataFromUrl } from '../../../utilities/fetchData';
import { setError } from '../../../components/alert-message/alerMessageSlice';
import { clearLoadingMessage, setLoadingMessage } from '../../../components/loading-dialog/loadingMessageSlice';


const OrderDetailDialogItem = ({itemKey, orderId, xsSize, mdSize}) => {
  const dispatch = useDispatch();
  
  const [labelList, setLabelList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const refreshPage = useSelector((state) => state.order.refreshedLabelPageTime);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
      const order_url =  `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/${orderId}/`
      dispatch(setLoadingMessage("loading order..."))
      fetchDataFromUrl(
        order_url
      ).then(order => {
        setLabelList(order?.order_labels) 
      }).catch(err => {
        console.error(err)
        dispatch(setError(err.message))
      }).finally(() => {
        dispatch(clearLoadingMessage())
      })
    
  }, [refreshPage]);


  return (
    <>
    <Grid item xs={xsSize} md={mdSize} onClick={handleOpenDialog} >
        <TextItem
            itemKey={itemKey}
            itemValue={orderId}
        />
            </Grid>
      <Dialog 
        fullWidth
        open={dialogOpen} 
        onClose={handleCloseDialog}
        >
        <DialogTitle>Labels for Order No. {orderId} </DialogTitle>
        <Divider/>
        <DialogContent sx={{m: 0, p: 0}}>
            <List>
          {labelList?.map((labelItem) => (
            <React.Fragment key={labelItem.label_id}>
              <ListItem>
                <ListItemButton >
                  <Grid container spacing={0}>
                    <TextItem
                      itemKey={"Label Id"}
                      itemValue={`${labelItem.label_id}`}
                      xsSize={7}
                      mdSize={7}
                      />
                      <TextItem
                        itemKey={"Quantity"}
                        itemValue={`${labelItem?.label_part?.container_qty}`}
                        xsSize={5}
                        mdSize={4}
                      />
                      <TextItem
                        itemKey={"Part Number"}
                        itemValue={`${labelItem?.label_part?.part_number}`}
                        xsSize={7}
                        mdSize={7}
                      />
                      <TextItem
                        itemKey={"Color Code"}
                        itemValue={`${labelItem?.label_part?.color_code}`}
                        xsSize={5}
                        mdSize={4}
                      />
                      
                    </Grid>
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        </DialogContent>
        <Divider />
        <DialogActions>

          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog>
      </>
  );
};

export default OrderDetailDialogItem;





