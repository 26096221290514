import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#000', // Set the primary color to black
    },
  },
});

export default customTheme;
