import React, { useState } from "react";

import { 
    DirectionsBoatOutlined,
    DescriptionOutlined as DescriptionOutlinedIcon,
    Edit as EditIcon,
 } from "@mui/icons-material";
import { 
    Grid,
    Checkbox,
    Skeleton,
    Typography,
    Container,
    IconButton,
    Paper, 
    useTheme} from "@mui/material"
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toEditShipToData } from "../ShipToEditOverlay/slice-shipto-edit";

const CardShipTo = ({
    shipToData = null,
    editButton = true,
    checkbox = !editButton,
    skeleton = false
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const handleOpenOverlay = () => {
  
        dispatch(toEditShipToData(shipToData));
    };

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
    };

    const cardStyle = {
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        margin: "8px",
        flexWrap: "no-wrap",
      };
    
      const iconStyle = {
        marginRight: "4px",
        fontSize: "16px",
      };
    
      const descriptionContainerStyle = {
        backgroundColor:theme.palette.primary.light,
        padding: "8px",
        borderRadius: "0 0 0 8px",
        display: "flex",
        alignItems: "center",
      };

    return (
        <Paper style={cardStyle}>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            {checkbox && (
              <Checkbox
                style={iconStyle}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            )}
          </Grid>
  
          <Grid item xs={8} alignItems="stretch">
            <div style={{ display: "flex", alignItems: "center" }}>
                <DirectionsBoatOutlined style={iconStyle} />
                <Typography variant="body2">Ship Id</Typography>
            </div>

            {skeleton ? (
                <Skeleton variant="text" width="100%" />
            ) : (
                <Typography variant="h6">{shipToData.shipto_id}</Typography>
            )}

          </Grid>
  
          {editButton && (
            <Grid item xs="auto" style={{ marginLeft: "auto" }}>
              {skeleton ? (
                <Skeleton variant="rect" width={24} height={24} />
              ) : (
                <IconButton
                  onClick={handleOpenOverlay}
                  style={iconStyle}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Grid>
          )}
  
          <Grid item xs={12}>
            <div style={descriptionContainerStyle}>
              <DescriptionOutlinedIcon style={iconStyle} />
              {skeleton ? (
                <Skeleton variant="rect" width="100%" animation="wave"/>
              ) : (
                <Typography variant="body1">{shipToData.shipto_destination_name}</Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    )
}

export default CardShipTo

CardShipTo.propTypes = {
    editButton: PropTypes.bool,
    checkbox: PropTypes.bool
}