import React, {useState} from "react";
import { Container, Grid } from "@mui/material";
import HeaderMui from "../../../components/headers/mui-header/HeaderMui"
import CardShipTo from "../card-shipto/CardShipTo";
import ShipToEditOverlay from "../ShipToEditOverlay/ShipToEditOverlay";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { SHIPTO_STORE } from "../../../utilities/indexedDB";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";


const ShipToMainPage = () => {
    const refreshedTime = useSelector((state) => state.shipTo.shipsRefreshedTime)
    const [shipToList, setShipToList] = useState([]);
    const {selectedSupplier} = useSupplierContext();

    usePermissionCheck({
      requestType: 'view',
      tableName:'shiptotable',
      message: "You don't have permission to view ShipTo, check with your administrator!"
    })

    useEffect(() => {

      const fetchData = async() => {
        const _shiptlist = await fetchDataAndStore(
          process.env.REACT_APP_SHIPTO,
          SHIPTO_STORE,
          'shipto_id'
        );
        if(_shiptlist){ 
          setShipToList(_shiptlist) 
        }
      }

      fetchData();

    }, [refreshedTime, selectedSupplier]);

    return (
        <div>
            <HeaderMui label="Ship To" />
            <Container>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} id="ship-to-list">
                        {shipToList.map((_shipto, index) => {
                            return (
                            <CardShipTo 
                               shipToData={_shipto}
                               />
                            )
                        })}

                    </Grid>
                </Grid>
            </Container>
          <ShipToEditOverlay/>

        </div>
    )
}

export default ShipToMainPage;


