export function applySearchAndFilter(searchValue, searchKeys, itemsList, filtermap){
    const searcheditemList = applySearch(searchValue, 
                                      searchKeys,
                                      itemsList);
    const filtereditemList = filterItems(searcheditemList, filtermap)
    return filtereditemList
  }
  
  function applySearch(searchValue, searchKeys, data){
    if (!searchValue || searchValue === "" || !searchKeys?.length) {
      return data;
    }
    
    const query = searchValue.toLowerCase();
    const filtered = data?.filter((item) => {
      for (const key of searchKeys) {
        if (item[key] && item[key].toLowerCase().includes(query)) {
          return true; // Match found in this key, include the item
        }
      }
      return false; // No matches found in any of the specified keys
    });
    return filtered;
  }
  
  const filterItems = (items, itemFilterMap) => {
    return items?.filter(item => {
      for (const key in itemFilterMap) {
        const filterConfig = itemFilterMap[key];
        if (!filterConfig.funct(filterConfig.filter_values, item)) {
          return false;
        }
      }
      return true;
    });
  };