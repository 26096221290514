import React, { useEffect, useState } from "react";
import { Grid, IconButton, Paper, useTheme } from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import TextItem from "../../../components/TextItem";
import { getItemFromStoreById } from "../../../utilities/getFromStore";
import { LABEL_STORE } from "../../../utilities/indexedDB";
import { getActiveOrder, removeLabelFromOrder } from "../removeUpdateOrderUtil";
import { useDispatch, useSelector } from "react-redux";
import { refreshLabelPage } from "../orderSlice";
import { ORDER_CREATED } from "../../../components/LABEL_CHOICES";

const SelectedLabelCard = ({orderData, labelId}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [isDisabled, setIsDisabled] = useState();
    const [label, setLabel] = useState({});
    const pageRefresh = useSelector(state => state.order.refreshedLabelPageTime)

    useEffect(() => {
      getActiveOrder()
      .then(data => setIsDisabled(data?.order_status !== ORDER_CREATED))
      .catch(err => console.error(err))

    }, [labelId, pageRefresh])

    useEffect(() => {
      // Get label information given label Id
      getItemFromStoreById(
        LABEL_STORE,
        labelId
      ).then(label => setLabel(label))
      .catch(err => console.error(err))

    },[labelId, pageRefresh])

    async function handleRemoveLabel(){
      const orderId = orderData?.order_id
      await removeLabelFromOrder(orderId, labelId)
      dispatch(refreshLabelPage())
    }

    return (
      <Paper elevation={3} 
        sx={{
          marginBottom:theme.spacing(1), 
          p:theme.spacing(1),
          borderRadius: theme.spacing(1)
          }}>
        <Grid container spacing={1}>
          <TextItem
            itemKey={"Label Id"}
            itemValue={`${label?.label_id}`}
            xsSize={7}
            mdSize={3}
            />

          <TextItem
            itemKey={"Quantity"}
            itemValue={label?.label_container_qty}
            xsSize={4}
            mdSize={3}
          />

            <TextItem
            itemKey={"Part Number"}
            itemValue={`${label?.label_part?.part_number}`}
            xsSize={7}
            mdSize={3}
            />
            <TextItem
            itemKey={"Color Code"}
            itemValue={`${label?.label_part?.color_code}`}
            xsSize={3}
            mdSize={2}
            />



          <Grid item xs={1}>
            <IconButton
            disabled={isDisabled}
            color="secondary" 
            aria-label="Delete" 
            onClick={handleRemoveLabel}
          >
          <RemoveCircleOutline />
        </IconButton>
        </Grid>
        
      </Grid>
    </Paper>
    )
}

export default SelectedLabelCard;

