import React, { useState, useEffect, useCallback } from "react";
import {
    Container,
    Grid,
  } from '@mui/material';

import { labelFilterMapFn } from "./label-filter-map";

import LabelHistoryCardMui from "./card-label-history/card-label-history";
import SearchHeaderUI from "../../../components/headers/search-header/SearchHeader";
import PrinterLabel from "../../../components/PrinterLabel/PrinterLabel";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import { applySearchAndFilter } from "../../../utilities/searchFilter";
import { LABEL_STORE, QRL_STORE } from "../../../utilities/indexedDB";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import { setError } from "../../../components/alert-message/alerMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";

const LabelHistoryMainPage = () => {
    const dispatch = useDispatch();
    const [labelList, setLabelList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [labelSearchShowList, setLabelSearchShowList] = useState([]);
    const [labelFilterMap, setLabelFilterMap] = useState({});
    const refreshTimestamp = useSelector((state) => state.label.historyPageRefreshTimestamp)
    const {selectedSupplier} = useSupplierContext()

    usePermissionCheck({
      requestType: 'view',
      tableName:'labeltable',
      message: "You don't have permission to view Label, check with your administrator!"
    })

    usePermissionCheck({
      requestType: 'view',
      tableName: 'qrltable',
      message: "You don't have permission to view Label, check with your administrator!"
    })


    // const fetchData = useCallback(async() => {
      
    // },[]);


    useEffect(() => {
      const fetchData = async() => {
      dispatch(setLoadingMessage("Loading labels..."))
      setLabelList([])
      setLabelSearchShowList([])
      setLabelFilterMap({})
      try{
        const _aiag_labels = await fetchDataAndStore(
          `${process.env.REACT_APP_API}/${process.env.REACT_APP_LABEL_ROUTE}/`,
          LABEL_STORE,
          'label_id'
        )
        const _qrl_labels = await fetchDataAndStore(
          `${process.env.REACT_APP_API}/${process.env.REACT_APP_QRL_ROUTE}/`,
          QRL_STORE,
          'label_id'
        )
        
        const _labels = [..._aiag_labels, ..._qrl_labels]

        if (!_labels) return;
        
        setLabelList(_labels)
        setLabelFilterMap(labelFilterMapFn(_labels));
        setLabelSearchShowList(_labels);
      }catch(error){
        console.error('Label History: ', error)
        dispatch(setError('Failed to fetch labels: '+ error.message))
      }finally{
        dispatch(clearLoadingMessage())
      }
    }

      fetchData()
    },[ selectedSupplier, refreshTimestamp])

    // search for labels
    useEffect(() => {
      const searchKeys = ['label_id', 'label_status', 'label_type', 'qrl_id', 'qrl_status']
      setLabelSearchShowList(applySearchAndFilter(searchValue, searchKeys, labelList, labelFilterMap))
    },[searchValue, labelList, labelFilterMap])

    const labelListArea = (itemList) => {
      return (
        <Grid item xs={12}>
          {itemList.map((label, index) => (
            <LabelHistoryCardMui
              key={label + index}
              labelData={label}
              skeleton={false}
            />
          ))}
        </Grid>
      );
    };

    return (
    <div>
      <SearchHeaderUI
        label="Label History"
        shownData={labelSearchShowList}
        setSearchValue={setSearchValue}
        setIsSearching={setIsSearching}

        filterMap={labelFilterMap}
        setFilterMap={setLabelFilterMap}
      />
      {/* {JSON.stringify(labelList)} */}
      <Container> 
      <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <PrinterLabel/>
          </Grid>
        {isSearching ? labelListArea(labelSearchShowList): labelListArea(labelList)}
        </Grid>
      </Container>

    </div>
    )
}

export default LabelHistoryMainPage;
