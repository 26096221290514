import React, { useState } from 'react';
import { 
    Box,
    SpeedDial,
    SpeedDialAction
}from '@mui/material';


import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const FabSpeedDial = ({ onAddClick, onDeleteClick }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    
    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1, bottom: 32, right: 32, position: 'fixed' }}>

        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        >
            <SpeedDialAction
            tooltipOpen
            key="Add part"
            icon={<AddIcon />}
            tooltipTitle="Add"
            onClick={onAddClick}
            />
            <SpeedDialAction
            key="Delete part"
            icon={<DeleteIcon />}
            tooltipTitle="Delete"
            tooltipOpen
            onClick={onDeleteClick}
            />
        </SpeedDial>

    </Box>
  );
};

export default FabSpeedDial;
