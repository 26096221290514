import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  TextField,
  Button,
  FormControl,
  IconButton,
  Drawer,
  Paper,
  Grid,
  useTheme,
  InputAdornment,
  MenuItem
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PmPreviewCard from "../PartDetailCard/PmPreviewCard";
import { useDispatch } from "react-redux";
import { postDataAndPutInStore } from "../../../utilities/postData";
import { setPartsRefresh } from "../PmMainPage/partsSlice";
import { PARTS_STORE } from "../../../utilities/indexedDB";
import { useUser } from "../../../pages/UserPage/UserContext";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import { fetchDataFromUrl } from "../../../utilities/fetchData";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";
import { setAlert, setError } from "../../../components/alert-message/alerMessageSlice";

export const PmAddOverlayMUI = ({ isOpen, setIsOpen }) => {
  const user = useUser()
  const theme = useTheme();
  const dispatch = useDispatch();
  const [supplierIds, setSupplierIds] = useState([])


  usePermissionCheck({ requestType: 'add', 
                        tableName: 'partstable', 
                        onClose: () => setIsOpen(false),
                        message: "User don't have permission to add parts!"
                      });

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  const initialValues = {
    partNumber: "",
    partWeight: "",
    color: "",
    quantity: "",
    dcPartLevel: "",
    containerType: "",
    description: "",
    partSupplierId: ""
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({ quantity: "", partWeight: "" });

  useEffect(() => {
    const fetchData = async() => {
      dispatch(setLoadingMessage("Fetching suppliers..."))
      
      fetchDataFromUrl(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_SUPPLIER_IDS_ROUTE}/`
      ).then((data) => {
        setSupplierIds(data)
        setFormValues({'partSupplierId': data?.[0]})
      })
      .catch((err) => {
        console.error(err)
        dispatch(setError("ADD PART: Supplier Ids not found!"))
      })
      .finally(() => {
        dispatch(clearLoadingMessage())
      })
    }

    fetchData()
  },[])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Validate the quantity field
    if (name === "quantity" && !/^\d+$/.test(value)) {
      setErrors({ ...errors, [name]: "Quantity must be an integer" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }

    // Validate the "Part Weight" field
  if (name === "partWeight") {
    // Regular expression to match decimal or integer values
    const validValue = /^\d+(\.\d*)?$/.test(value);
    if (!validValue) {
      // Set an error message if the value is not valid
      setErrors({ ...errors, [name]: "Part Weight must be a decimal or integer" });
    } else {
      // Clear the error message if the value is valid
      setErrors({ ...errors, [name]: "" });
    }
  }
  };

  const handleSubmit = async() => {
    
    var check_error = false

    Object.keys(initialValues).forEach(key => {
      if (key !== 'color' && !formValues[key]) {
          setErrors(prevErrors => ({
              ...prevErrors,
              [key]: `required!`
          }));
          check_error = true;
      }
  });

  if (check_error){
    console.log(errors)
    return
  }

    // if (!formValues.quantity){
    //   setError({...errors, 'quantity': "Quantity cannot be empty!"})
    //   dispatch(setError("Quantity cannot be empty!"))
    //   return;
    // }else if(!formValues.partNumber){
    //   setError({...errors, 'partNumber': "Part Number cannot be empty!"})
    // }

    if (Object.values(errors).some((error) => !!error)) {
      // There are errors, do not submit the form
      console.log("Form has validation errors. Please fix them.");
      return;
    }

    // You can access the form values from the formValues object and perform any necessary actions here.

    const newPart = {
      part_number: formValues.partNumber,
      part_weight: formValues.partWeight,
      color_code: formValues.color,
      container_qty: formValues.quantity,
      dc_part_level: formValues.dcPartLevel,
      container_type: formValues.containerType,
      part_description: formValues.description,
      part_supplier_id: formValues.partSupplierId
    };

    postDataAndPutInStore(
      process.env.REACT_APP_PART,
      newPart,
      PARTS_STORE
    ).then((part) => {
      dispatch(setAlert({severity:"success", message: `${part.part_number} added!`}))
      dispatch(setPartsRefresh());
      handleCloseDrawer();
      setFormValues(initialValues);
    }).catch((err) => {
       dispatch(setError("Cannot add part, Check part/color not already exists!"))
    })
    
    
  };

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={handleCloseDrawer}
      elevation={4}
    >

      <Paper
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <AppBar position="sticky" 
          sx={{ 
            backgroundColor: theme.palette.primary.main,
            top: 0
          }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }} component="div">
              Add New Part
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container
          
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            position: "relative"
          }}
        >
          <div style={{ marginTop: 16, marginBottom: 32, alignSelf:"center" }}>
            <PmPreviewCard formValues={formValues}/>
          </div>

          <FormControl 
            variant="standard" 
            style={{
              overflow: "auto"
            }}
            fullWidth
            >
            
            <div>
            <Grid 
              container 
              columnSpacing={2} 
              rowSpacing={2}
              >

            <Grid item xs={12} md={3}>
              <TextField
                select
                id="outlined-basic"
                name="partSupplierId"
                label="Supplier"
                variant="filled"
                value={formValues.partSupplierId}
                onChange={handleChange}
                fullWidth
              >
                {
                  supplierIds.map(supplier => (
                    <MenuItem key={supplier} value={supplier}>
                      {supplier}
                    </MenuItem>
                  ))
                }
                </TextField>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
               fullWidth
                id="outlined-basic"
                label="Part Number"
                variant="outlined"
                name="partNumber"
                value={formValues.partNumber}
                onChange={handleChange}
                error={!!errors.partNumber} // Set error prop based on validation result
                helperText={errors.partNumber} // Display validation error message
              />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Part Weight"
                  variant="outlined"
                  name="partWeight"
                  value={formValues.partWeight}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>
                  }}
                  error={!!errors.partWeight} // Set error prop based on validation result
                  helperText={errors.partWeight} // Display validation error message
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-basic"
                  label="Color"
                  variant="outlined"
                  name="color"
                  value={formValues.color}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
 
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Quantity"
                    variant="outlined"
                    name="quantity"
                    value={formValues.quantity}
                    onChange={handleChange}
                    error={!!errors.quantity} // Set error prop based on validation result
                    helperText={errors.quantity} // Display validation error message
                  />
                </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="DC Part Level"
                variant="outlined"
                name="dcPartLevel"
                value={formValues.dcPartLevel}
                onChange={handleChange}
                error={!!errors.dcPartLevel} // Set error prop based on validation result
                helperText={errors.dcPartLevel} // Display validation error message
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Container Type"
                variant="outlined"
                name="containerType"
                value={formValues.containerType}
                onChange={handleChange}
                error={!!errors.containerType} // Set error prop based on validation result
                helperText={errors.containerType} // Display validation error message
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Description"
                variant="outlined"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                error={!!errors.description} // Set error prop based on validation result
                helperText={errors.description} // Display validation error message
                multiline
                rows={4}
                style={{ marginBottom: 32 }}
              />
            </Grid>

            </Grid >
            </div>
              <Button
                fullWidth
                size="large"
                startIcon={<AddOutlinedIcon />}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{marginBottom:"16px", 
                        bottom: "0", 
                        right:"0", 
                        left:"0"}}
              >
                Add
              </Button>
        </FormControl>
        </Container>
      </Paper>
    </Drawer>
  );
};
