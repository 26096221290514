import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Box,
  MenuItem, // Import IconButton component
} from '@mui/material';
import NumberField from '../../../components/NumberField/NumberField';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PrinterLabel from '../../../components/PrinterLabel/PrinterLabel';
import { useDispatch } from 'react-redux';
import { setAlert, setError } from '../../../components/alert-message/alerMessageSlice';
import HeaderMui from '../../../components/headers/mui-header/HeaderMui';
import { is_non_positive, is_none } from '../../../components/validations';
import { fetchDataFromUrl } from '../../../utilities/fetchData';
import { handleMultipleLabelPrinting, createAiagLabel } from '../handlePrint';
import { useGattServer } from '../../../components/PrinterLabel/GattServerContext';
import { useSupplierContext } from '../../../components/supplier-context/SupplierThemeContext';
import { clearLoadingMessage, setLoadingMessage } from '../../../components/loading-dialog/loadingMessageSlice';


const AiagMainPage = () => {
  const dispatch = useDispatch();
  const {gattServer} = useGattServer();

  const [parts, setParts] = useState([]);

  const [partOptions, setPartOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const {selectedSupplier} = useSupplierContext();

  // Define the initial form state
  const initialFormState = {
    part_supplier_id: '',
    part_id: '',
    part_number: '',
    color_code: '',
    container_qty: 0,
    num_labels: 1,
    num_copies: 1
  };

  // State to hold form data
  const [labelData, setLabelData] = useState(initialFormState);

  useEffect(() => {

    setLabelData({
      ...initialFormState,
      'part_supplier_id': selectedSupplier
    })
    setParts([])
    setPartOptions([])
    setColorOptions([])

  },[selectedSupplier])

  // update part numbers
  useEffect(() => {

    if(!labelData.part_supplier_id) return

    setLabelData({
      ...labelData,
      part_number: ""
    })

    dispatch(setLoadingMessage("fetching parts..."))
    const parts_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_PARTS_ROUTE}/`

    fetchDataFromUrl(
      parts_url
    ).then((parts) => {
      setParts(parts)

      const _partNumbers = [...new Set(parts.map((part) => part.part_number))];
      const _partOptions = _partNumbers.sort((a, b) => (a.localeCompare(b)))
      setPartOptions(_partOptions)

      setLabelData({
        ...labelData,
        part_number: _partOptions?.[0]
      })
    })
    .catch(err => console.error(err))
    .finally(() => dispatch(clearLoadingMessage()))

  }, [labelData.part_supplier_id])

  // update color options
  useEffect(() => {
      
      if (! labelData.part_number) return

      setColorOptions([])
      const selectedParts = parts.filter((part) => (part.part_number === labelData.part_number))
      const _colorOptions = [...new Set(selectedParts.map((part) => part.color_code))];
      const _sortedColorOptions = _colorOptions.sort((a, b) => (a.localeCompare(b)))
      setColorOptions(_sortedColorOptions);

      setLabelData({
        ...labelData,
        'color_code': _sortedColorOptions?.[0]
      })

  },[labelData.part_number])

  // update container quantity
  const getUniquePart = () => {
    
    const selectedPart = parts.filter((part) => (
      part.part_number === labelData.part_number &&
      part.color_code === labelData.color_code
    ))
    
    // setUpdatedParts(selectedPart)
    return selectedPart?.[0] 
  }

  useEffect(() => {

    const part = getUniquePart()
    if (part)
      setLabelData({
        ...labelData,
        part_id: part.part_id,
        container_qty: part.container_qty
      })

  },[labelData.part_number, labelData.color_code])
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLabelData({
      ...labelData,
      [name]: value,
    });    
  };

  const validateData = () => {
    
    if (is_none(labelData.part_number)){
      dispatch(setAlert({severity: "error", message:"Part number is required!"}))
      return false;
    }else if (is_none(labelData.part_supplier_id)){
      dispatch(setError("Selected Supplier Id!"))
      return false;
    }else if (is_non_positive(labelData.container_qty)){
      dispatch(setError("container quantity cannot be zero!"))
      return false;
    }

    return true;
  }

  const cleanData = () => {

    const cleanedData = {
      label_supplier_id: labelData.part_supplier_id,
      label_part_id: labelData.part_id,
      label_container_qty: labelData.container_qty,
      num_labels: labelData.num_labels,
      num_copies: labelData.num_copies,
      // label_status: labelData.label_status
    }
    return cleanedData
  }
  
  // Handle form submission (printing)
  const handlePrint = async () => {
    if (validateData()) {

        const cleanedLabelData = cleanData();
        handleMultipleLabelPrinting(cleanedLabelData, createAiagLabel, gattServer)
        .then(() => {
          dispatch(setAlert({severity: "success", message:"Finished printing!"}))
        }).catch(err => {
          console.error(err)
        })

        // handleClear();
    }
  };

  const handleClear = () => {
    // this will auto trigger color and quantity
    const part = parts?.[0]
    setLabelData({
      ...labelData,
      num_copies: 1,
      num_labels: 1,
      part_number: part?.part_number,
      color_code: part?.color_code,
      container_qty: part?.container_qty
    })
  }

  return (
    <div>
      <HeaderMui 
        label={"AIAG Label Generation"}
      />

      <Container>
        <Grid container spacing={3} justifyContent="center"> 
          <PrinterLabel />
          <Grid item xs={12} >

            <TextField
              disabled
              fullWidth
              label="Supplier Id"
              name="part_supplier_id"
              variant="filled"
              margin="normal"
              value={labelData.part_supplier_id}
            />
            
            {partOptions.length > 0 ?
              <TextField
              select
              label="Part Number"
              name="part_number"
              fullWidth
              variant="outlined"
              margin="normal"
              value={labelData.part_number}
              onChange={handleInputChange}
              required
            >
              {partOptions.map(part => (
                <MenuItem key={part} value={part}>
                  {part}
                </MenuItem>
              ))}

              </TextField>:
              <div>No Part Found!</div>
          }
          
          {colorOptions.length > 0 && labelData.color_code ?
              <TextField
                  select
                  label="Color Code"
                  name="color_code"
                  type="string"
                  variant="outlined"
                  margin="normal"
                  value={labelData.color_code}
                  onChange={handleInputChange}
                  fullWidth
                  required
                >

                  {colorOptions.map((color, index) => (
                    <MenuItem key={index} value={color}>{color}</MenuItem>
                  ))} 
              </TextField>:
              <div>
              {partOptions.length > 0 && <div> No Color Found!</div>}
              </div>
            }

            <NumberField
              label="Quantity"
              name="container_qty"
              value={labelData.container_qty}
              onChange={(newValue) =>
                setLabelData({ ...labelData, container_qty: parseInt(newValue) })
              }
            />
          </Grid>

          {/* # Labels and # Copies */}
          <Grid item xs={12} >
            <Grid container 
                sx={{ display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between' 
                }}
                columnSpacing={4}
                rowSpacing={0}
                >
                <Grid item xs={6}>
                  <NumberField
                    label="# Labels"
                    name="num_labels"
                    value={labelData.num_labels}
                    onChange={(newValue) =>
                        setLabelData({ ...labelData, num_labels: parseInt(newValue) })
                  }
                    />
                </Grid>
                <Grid item xs={6}>
                  <NumberField
                    label="# Copies"
                    name="num_copies"
                    value={labelData.num_copies}
                    onChange={(newValue) =>
                        setLabelData({ ...labelData, num_copies: parseInt(newValue) })
                    }
                    />
                </Grid>
            </Grid>
          </Grid>
          {/* Print Button */}
          <Grid item xs={12}>
              <Box display="flex" justifyContent="space-around">
                  <Button
                  variant="text"
                  color="primary"
                  size="large"
                  onClick={handleClear}
                  >
                    Clear
                  </Button>

                  <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handlePrint}
                  startIcon={<LocalPrintshopOutlinedIcon/>}
                  >
                  Print
                  </Button>
                </Box>
            </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AiagMainPage;


