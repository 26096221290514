import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext(null);

export function UserProvider({ children }) {
  // Retrieve user data from localStorage initially
  const userData = localStorage.getItem('userData');
  const [user, setUser] = useState(userData ? JSON.parse(userData) : null);

  // Function to update both state and localStorage
  const updateUser = (newUserData) => {
    setUser(newUserData);
    localStorage.setItem('userData', JSON.stringify(newUserData));
  };

  return (
    <UserContext.Provider value={{ user, setUser: updateUser }}>
      {children}
    </UserContext.Provider>
  );
}


// export function UserProvider({ children }) {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const userData = localStorage.getItem('userData');
//     if (userData) {
//       setUser(JSON.parse(userData));
//     }
//   }, []);

//   useEffect(() => {
//     if (user) {
//       localStorage.setItem('userData', JSON.stringify(user));
//     }
//   }, [user]);

//   return (
//     <UserContext.Provider value={{ user, setUser }}>
//       {children}
//     </UserContext.Provider>
//   );
// }

export function useUser() {
  const context = useContext(UserContext);
  const navigate = useNavigate();

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  const { user, setUser } = context;

  if (!user) {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const userJsonData = JSON.parse(userData);
      setUser(userJsonData);
      return userJsonData;
    } else {
      navigate('/login');
    }
  }

  return user;
}
