import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const PmPreviewCard = ({ formValues }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          Preview
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Part Number:</strong> {formValues.partNumber || formValues.part_number}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Part Weight:</strong> {formValues.partWeight || formValues.part_weight} lbs
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Color:</strong> {formValues.color || formValues.color_code}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Quantity:</strong> {formValues.quantity || formValues.container_qty}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Supplier:</strong> {formValues.supplier || formValues.part_supplier_id}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>DC Part Level:</strong> {formValues.dcPartLevel || formValues.dc_part_level || "--"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Container Type:</strong> {formValues.containerType || formValues.container_type}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Description:</strong> {formValues.description || formValues.part_description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PmPreviewCard;
