import React, { useState, useEffect } from "react";
import { 
  Grid,  
  Container, 
  Divider,
  useTheme
} from "@mui/material";
import HeaderMui from "../../../components/headers/mui-header/HeaderMui";
import { useSelector } from "react-redux";
// import CloseTrailer from "./close-order-button/CloseOrderButton";

import { getItemFromStoreById } from "../../../utilities/getFromStore";
import { ORDER_STORE } from "../../../utilities/indexedDB";
import TextItem from "../../../components/TextItem";
import LabelDialogAddButton from "./LabelDialogAddButton";
import SelectedLabelCard from "./SelectedLabelCard";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import CompleteOrderButton from "./CompleteOrderButton";


const AddLabelPage = () => {
  const theme = useTheme();
  const [orderData, setOrderData] = useState({});
  const [labelIdList, setLabelIdList] = useState([]);
  const pageRefresh = useSelector((state) => state.order.refreshedLabelPageTime)

  const fetchData = async() => {
    try {
      const order_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`
      // Step 1: Read orderData from local storage
      const _orderRef = localStorage.getItem('orderRef');
      await fetchDataAndStore(order_url, ORDER_STORE, 'order_id')
      const _orderData = await getItemFromStoreById(ORDER_STORE, _orderRef);
 
      setOrderData(_orderData);
      setLabelIdList(_orderData?.order_label_ids)

      // setLabelList(_order_label_labels);
    } catch (error) {
      console.error('Error fetching Order data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageRefresh]);

  return (
    <div >
        <HeaderMui label={"Order Details"} backLink={true} />

        <Container sx={{marginTop: theme.spacing(2)}} >
          <Grid container spacing={theme.spacing(2)}>
            <TextItem itemKey="Order Id" itemValue={orderData?.order_id} xsSize={6} mdSize={6}/>
              <TextItem itemKey="ShipTo Id" itemValue={orderData?.order_shipto_id} xsSize={3} mdSize={3}/>
              <Grid item xs={3} md={3} align="right">
                <LabelDialogAddButton />
              </Grid>

            <Divider orientation="horizontal"/>          

            <Grid item xs={12}>
              {labelIdList?.map((labelId, index) => (
                <div key={labelId} index={`${labelId}-${index}`}>
                  <SelectedLabelCard orderData={orderData} labelId={labelId} />
                </div>
              ))}
            </Grid>
              
            <Grid item xs={12}>
              <div style={{height: '5rem'}}></div>
              <CompleteOrderButton />
            </Grid>
          </Grid>
        </Container>
    </div>
  );
};

export default AddLabelPage;


