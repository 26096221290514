import React, { useContext, useEffect, useState } from 'react';
import { Button, TextField, InputAdornment, Typography, Grid, IconButton, Alert, Container } from '@mui/material';
import { ArrowRightAlt, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { UserContext } from './UserContext'; // Assuming UserContext is an export
import { useDispatch } from 'react-redux';
import { setAlert, setError } from '../../components/alert-message/alerMessageSlice';
import axios from 'axios';
import LogoIcon from '../../components/logoIcon/LogoIcon';
import { APP_NAME } from '../../variables';
import { DB_NAME } from '../../utilities/indexedDB';
import { Link } from 'react-router-dom';

const UserLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState({ username: "", password: "" });
  const { user, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    localStorage.clear();

    const request = indexedDB.deleteDatabase(DB_NAME);

    request.onsuccess = function () {
      console.log('IndexedDB cleared successfully');
    };

    request.onerror = function (event) {
      console.error('Error clearing IndexedDB:', event.target.errorCode);
    };

  },[])

  const checkExpiry = async(data) => {
    if (data.password_expiry_days < 1){
      dispatch(setError("Password has expired!"))
      setUser(data)
      navigate("/update-password")
    }else{
      setUser(data);
      navigate("/");
    }
  }

  const handleNextClick = async () => {
    const loginUrl = process.env.REACT_APP_AUTH; // Replace with your actual login URL

    try {
      const response = await axios.post(loginUrl, 
        formValues, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          withCredentials: true
        });
        
      const data = response.data;
  
      if (data !== false) {
        dispatch(setAlert({ severity: "success", message: "Login successful!" }));
        await checkExpiry(data);

      } else {
        setErrorMessage("Supplier id not found!");
        dispatch(setAlert({ severity: "error", message: "Supplier id not found!" }));
      }
    } catch (error) {
      if (error.response && error.response.status === 423){
        setErrorMessage("App Access Locked, contact administrator!")
      }
      dispatch(setAlert({ severity: "error", message: error.message || "An error occurred" }));
      console.error('Error:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleNextClick();
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    setFormValues({
      ...formValues,
      [name]: val,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container spacing={3} sx={{ textAlign: "center", mt: 10 }}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 6 }}>
          <LogoIcon/>
          <div/>
          {APP_NAME}
        </Typography>
        {errorMessage && <Alert severity='error' sx={{mt:1}}>{errorMessage}</Alert>}
      </Grid>

      
      <Grid item xs={12}>
        <TextField
          type="text"
          label="Username"
          name="username"
          value={formValues.username}
          onChange={handleChange}
          variant="outlined"
          sx={{width:"300px"}}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Password"
          name="password"
          value={formValues.password}
          onChange={handleChange}
          variant="outlined"
          onKeyDown={handleKeyDown}
          sx={{width:"300px"}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Link to="/reset-password" color='primary' style={{ textDecoration: 'none' }}>
          <Typography variant='body2' color='primary'>
            Reset Password! Has OTP?
          </Typography>
        </Link>
      </Grid>


      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          Submit <ArrowRightAlt />
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserLogin;
