// Redux Slice for Overlay Management (pmOverlaySlice.js)
import { createSlice } from '@reduxjs/toolkit';

const pmOverlaySlice = createSlice({
  name: 'pmOverlay',
  initialState: {
    isOpen: false,
    openRefId: null,
  },
  reducers: {
    openPmEditOverlay: (state, action) => {
      state.isOpen = true;
      state.openRefId = action.payload;
    },
    closePmEditOverlay: (state) => {
      state.isOpen = false;
      state.openRefId = null;
    },
  },
});

export const { openPmEditOverlay, closePmEditOverlay } = pmOverlaySlice.actions;
export const selectIsOverlayOpen = (state) => state.pmOverlay.isOpen;
export const selectOpenRefId = (state) => state.pmOverlay.openRefId;

export default pmOverlaySlice.reducer;
