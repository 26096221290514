// shipToEditSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shipToData: null,
};

const shipToEditSlice = createSlice({
  name: 'shipToEdit',
  initialState,
  reducers: {
    toEditShipToData: (state, action) => {
      state.shipToData = action.payload;
    },
    clearEditShipToData: (state) => {
      state.shipToData = null;
    },
    // You can retain updateSuccess and updateFailure if needed
    updateEditSuccess: (state) => {
      state.shipRefId = null;
    },
    updateEditFailure: (state) => {
      state.shipRefId = null;
    },
  },
});

export const { toEditShipToData, clearEditShipToData, updateEditSuccess, updateEditFailure } = shipToEditSlice.actions;

export default shipToEditSlice.reducer;
