// asnSlice.js
import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    refreshedOrderPageTime: new Date().toISOString(),
    refreshedLabelPageTime: new Date().toISOString(),
    refreshedOrderHistoryPageTime: new Date().toISOString(),
  },
  reducers: {
    refreshOrders: (state) => {
      state.refreshedOrderPageTime = new Date().toISOString(); // Set the refreshAsn with the provided ISO string
    },
    refreshLabelPage: (state) => {
      state.refreshedLabelPageTime = new Date().toISOString();
    },
    refreshOrderHistoryPage: (state) => {
      state.refreshedOrderHistoryPageTime = new Date().toISOString();
    },
  },
});

export const { refreshOrders, refreshLabelPage, refreshOrderHistoryPage } = orderSlice.actions;

export default orderSlice.reducer;
