import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from './alerMessageSlice';
// import { clearAlert, clearAlertAfterTimeout } from './alerMessageSlice';

const autoHideDuration = 3000;

const AlertMessage = () => {
  const dispatch = useDispatch();
  const severity = useSelector((state) => state.alert.severity);
  const message = useSelector((state) => state.alert.message);
  const alert_time = useSelector((state) => state.alert.alert_time);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(alert_time ){
      setOpen(true);
    }
  },[alert_time])

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      open={open}
      autoHideDuration={autoHideDuration} // Adjust the duration as needed
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
