import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme2 = createTheme({
  palette: {
    primary: {
      main: '#0099A7',
      light: '#f0f0f0', // '#64B5F6',
      dark: '#0D47A1',
    },
    secondary: {
      main: '#FF4081',
      light: '#FF79B0', // Add a lighter shade of the secondary color
      dark: '#C60055', // Add a darker shade of the secondary color
    },
    error: {
      main: '#FF5722',
    },
    warning: {
      main: '#FFC107',
    },
    info: {
      main: '#2196F3',
    },
    success: {
      main: '#4CAF50',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 12,
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    // Add more typography styles as needed
  },
  spacing: 8, // Customize spacing values
  // Add more custom styling properties and values here
});

export default responsiveFontSizes(theme2);


// overrides: {
//   MuiButton: {
//     root: {
//       fontSize: '14px', // Adjust the font size for all buttons
//     },
//     sizeSmall: {
//       fontSize: '12px', // Adjust the font size for small buttons
//     },
//     sizeLarge: {
//       fontSize: '16px', // Adjust the font size for large buttons
//     },
//   },
// },