const TemplateZpl = `^XA
^DFE:AIAGLabel.ZPL^FS
~TA000
~JSN
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR8,8
~SD15
^JUS
^LRN
^CI27
^PA0,1,1,0
^MMT
^PW812
^LL1218
^LS0
^FO175,34^GB0,1146,4^FS
^FO364,34^GB0,1146,4^FS
^FO569,38^GFA,45,4580,4,:Z64:eJztwwEJAAAMBKGL/fGXY6Dgqqmqqqr6+gF63hjX:AAF4
^FT41,1180^A0B,28,28^FH\^CI28^FDPART NO.^FS^CI27
^FT76,1180^A0B,28,28^FH\^CI28^FD(P)^FS^CI27
^FO178,557^GB187,0,4^FS
^FO365,619^GB206,0,4^FS
^FT218,1180^A0B,28,28^FH\^CI28^FDPART NO EXT^FS^CI27
^FT253,1180^A0B,28,28^FH\^CI28^FD(C)^FS^CI27
^FO268,34^GB0,525,4^FS
^FT212,546^A0B,28,28^FH\^CI28^FDDESCRIPTION^FS^CI27
^FT303,543^A0B,23,23^FH\^CI28^FDSPLR Lot# :^FS^CI27
^FT329,543^A0B,23,23^FH\^CI28^FDSUPPLIER MFG# :^FS^CI27
^FT406,1180^A0B,28,28^FH\^CI28^FDD/C PART LEVEL^FS^CI27
^FT441,1180^A0B,28,28^FH\^CI28^FD(2P)^FS^CI27
^FT522,587^A0B,28,28^FH\^CI28^FDQUANTITY^FS^CI27
^FT557,587^A0B,28,28^FH\^CI28^FD(Q)^FS^CI27
^FT612,1183^A0B,28,28^FH\^CI28^FDSERIAL^FS^CI27
^FT647,1183^A0B,28,28^FH\^CI28^FD(1S)^FS^CI27
^FT778,1183^A0B,17,18^FH\^CI28^FDMade in C.O.O:^FS^CI27
^FT56,1039^A0B,56,68^FH\^CI28^FN1"Text1"^FS^CI27
^BY5,3,83^FT166,1067^BCB,,N,N,,A
^FN2"Barcode"^FS
^FT241,994^A0B,51,51^FH\^CI28^FN3"Text11"^FS^CI27
^BY4,3,99^FT353,1024^BCB,,N,N,,A
^FN4"Barcode1"^FS
^FT252,536^A0B,31,30^FH\^CI28^FN5"Text3"^FS^CI27
^FT435,967^A0B,51,51^FH\^CI28^FN6"Text111"^FS^CI27
^BY3,3,97^FT552,1108^BCB,,N,N,,A
^FN7"Barcode2"^FS
^BY3,3,97^FT484,516^BCB,,N,N,,A
^FN8"Barcode21"^FS
^FT551,414^A0B,51,51^FH\^CI28^FN9"Text1111"^FS^CI27
^FT640,1074^A0B,56,68^FH\^CI28^FN10"Text12"^FS^CI27
^BY5,3,97^FT751,1108^BCB,,N,N,,A
^FN11"Barcode22"^FS
^FT778,591^A0B,17,18^FH\^CI28^FN12"Text41"^FS^CI27
^FT778,1062^A0B,17,18^FH\^CI28^FN13"Text42"^FS^CI27

^XZ`
  
  export const labelZPLTemplate = `
  ^XA^XFE:AIAGLabel.ZPL^FS
  ^FN1^FV$part_number^FS 
  ^FN2^FVP$part_number^FS      
  ^FN3^FV$color_code^FS 
  ^FN4^FVC$color_code^FS 
  ^FN5^FV$part_description^FS 
  ^FN6^FV$dc_part_level^FS 
  ^FN7^FV2P$dc_part_level^FS 
  ^FN8^FVQ$container_qty^FS 
  ^FN9^FV$container_qty^FS 
  ^FN10^FV$broken_label_id^FS 
  ^FN11^FV1S$label_id^FS 
  ^FN12^FV$supplier_name, $supplier_address_1, $supplier_address_2, $supplier_state, $supplier_postal_code^FS   
  ^FN13^FV$supplier_state^FS
  ^MCY^XZ
`;

export const labelQrlTemplate = `
^XA^XFE:QRLLabel.ZPL^FS
^FN1^FV$supplier_name\\&$supplier_address_1\\&$supplier_address_2, $supplier_state, $supplier_postal_code^FS 
^FN2^FV$shipto_destination_name\\&$shipto_address_1\\&$shipto_address_2\\&$shipto_address_3, $shipto_state_code, $shipto_zip_code^FS      
^FN3^FV^FS 
^FN4^FV^FS 
^FN5^FV^FS 
^FN6^FV$broken_label_id^FS 
^FN7^FV9S$label_id^FS 
^FN8^FV$supplier_name, $supplier_address_1, $supplier_address_2, $supplier_state, $supplier_postal_code^FS 
^MCY^XZ
` 