import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { 
    Cancel,
    LockOpen,
    Email,
    ArrowOutward,
    Lock,
    CancelOutlined
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { getActiveAsn } from '../removeUpdateAsnUtil';
import { closeAsn } from '../closeAsn';
import { refreshQrlPage } from '../asnSlice';
import { clearLoadingMessage, setLoadingMessage } from '../../../components/loading-dialog/loadingMessageSlice';
import { ASN_CANCELLED, ASN_CREATED, ASN_SHIPPED } from '../../../components/LABEL_CHOICES';

import { setAlert, setError } from '../../../components/alert-message/alerMessageSlice';
import { fetchDataFromUrl } from '../../../utilities/fetchData';
import { DialogContentText } from '@mui/material';


const CloseTrailerButton = () => {
  const dispatch = useDispatch()
  const [asnData, setAsnData] = useState({})
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);

  const triggerShowTrailer = () => {
    setShowTrailer(!showTrailer);
  }

  useEffect(() => {
    const fetchdata = async() => {
      try{
        const _asnData = await getActiveAsn()
        setAsnData(_asnData)
        setIsDisabled(_asnData?.asn_status !== ASN_CREATED)
      }catch(error){
        console.error(error)
      }
    }
    fetchdata()
  }, [])

  const handleConfirmationOpen = () => {
      
      getActiveAsn()
      .then(asnData => {
        if (asnData?.asn_qrl_ids.length !== 0){
          setConfirmationOpen(true);
        }else{
          dispatch(setError("Cannot Close, No QRL attached!"))
        }
      }).catch(err => {
        console.log(err)
      })
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleCloseDialogOpen = async() => {

    const asnData = await getActiveAsn()
    
    dispatch(setLoadingMessage("Closing ASNs"))
    
    closeAsn(asnData.asn_id)
    .then((data) => {
      setAsnData(data);
      
      setIsDisabled(data.asn_status === ASN_SHIPPED)
    })
    .catch(err => {
      console.error(err)
      dispatch(setError("Error! Cannot close Trailer"))
    }) 
    .finally(() => {
      dispatch(clearLoadingMessage())
      dispatch(refreshQrlPage())
      handleConfirmationClose()
    })
    
  };

  const generateTextData = async() => {

    const export_asn_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_EXPORT_ASN_ROUTE}/${asnData?.asn_id}/`
    try{
      dispatch(setLoadingMessage("generating ASN..."))
      const asn_text = await fetchDataFromUrl(
        export_asn_url
      )
      return asn_text
    }catch(err){
      console.error(err)
      dispatch(setError("Error: while exporting ASN, Try Again!"))
      throw err;
    }finally{
      dispatch(clearLoadingMessage())
    }    
  };

  const handleAsnExport = async() => {
    try{
      const textData = await generateTextData();
      const blob = new Blob([textData], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `asn_${asnData.asn_id}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }catch(err){
      console.error(err)
    }
  };

  const handleAsnEmail = async() => {
    const email_mpl_bol_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_EMAIL_MPL_BOL}/${asnData.asn_id}/`

      dispatch(setLoadingMessage("sending email!"))
      fetchDataFromUrl(email_mpl_bol_url)
      .then(response => {
        console.log(response)
        dispatch(setAlert({severity: "success", message:response?.message}))
      }).catch(err => {
        console.error(err) 
        dispatch(setError('Facing error while sending email!'))
      }).finally(() => {
        dispatch(clearLoadingMessage())
      })
  }

  return (
    <div style={{ backgroundColor: 'white', opacity: 1, position: 'fixed', bottom: 0, left: 0, width: '100%', textAlign: 'center', padding: '1rem', paddingBottom: '2rem' }}>
        {asnData?.asn_status === ASN_CREATED &&
        <Button
         variant='contained'
         color="primary"
          onClick={handleConfirmationOpen}
          startIcon={<LockOpen/>}
         >
        Close Trailer 
        </Button>
        }
        {
          asnData.asn_status === ASN_SHIPPED &&
          <Button
          variant="contained"
          color='inherit'
          onClick={triggerShowTrailer}
          startIcon={<Lock />}
          >
            Trailer Closed
          </Button>
        }
       
        {
          asnData?.asn_status === ASN_CANCELLED &&
          <Button
          variant='contained'
          color="inherit"
            startIcon={<CancelOutlined/>}
          >
            Trailer Cancelled
          </Button>
        }
      

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle >
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close the trailer?
          </DialogContentText>
          <DialogContentText>
          (make sure all QRLs has at least one Order)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCloseDialogOpen}
            color="primary"
            startIcon={<CheckCircleIcon />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Closed Dialog */}
      <Dialog open={isDisabled && showTrailer} onClose={triggerShowTrailer} fullWidth>
        <DialogTitle>Trailer Closed</DialogTitle>
        <DialogContent>
          The trailer has been closed.
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleAsnEmail}
            color="primary"
            variant="outlined"
            startIcon={<Email />}
          >
            Email ASN
          </Button>
          <Button
            fullWidth
            onClick={handleAsnExport}
            color="primary"
            variant="contained"
            startIcon={<ArrowOutward />}
            
          >
            Export ASN
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CloseTrailerButton;
