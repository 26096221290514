import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import SearchHeaderUI from "../../../components/headers/search-header/SearchHeader";
import { ORDER_STORE } from "../../../utilities/indexedDB";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import CreateOrderCard from "./CreateOrderCard";
import { applySearchAndFilter } from "../../../utilities/searchFilter";
import AddOrderButton from "./AddOrderButton";
import { ORDER_CREATED } from "../../../components/LABEL_CHOICES";
import orderFilterMapFn from "../orderFilterMapFn";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";


const CreateOrderPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isSearching, setIsSearching] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [filterMap, setFilterMap] = useState({})

    const [searchValue, setSearchValue] = useState(""); 
    const [orderSearchShowList, setOrderSearchShowList] = useState([]);
    const {selectedSupplier} = useSupplierContext()
 
    const pageRefreshedTime = useSelector((state) => state.order.refreshedOrderPageTime)

    // Check permissions
    usePermissionCheck({
        requestType: 'view',
        tableName:'ordertable',
        onClose: () => navigate("/"),
        message: "You don't have permission to view Order, check with your administrator!"
      })

    useEffect(() => {
        dispatch(setLoadingMessage("loading orders..."))
        fetchDataAndStore(
            `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`,
            ORDER_STORE,
            'order_id'
        ).then((orders) => {

            const filteredOrders = orders?.filter((_orderItem) => (
                _orderItem?.order_status === ORDER_CREATED
            ))
            setOrderList(filteredOrders)
            setFilterMap(orderFilterMapFn(filteredOrders));
            setOrderSearchShowList(filteredOrders);
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            dispatch(clearLoadingMessage())
        })

    }, [selectedSupplier, pageRefreshedTime])

    const orderListArea = (orderList) => {
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            {orderList?.map((order, index) => (
                <CreateOrderCard
                    key={order + index}
                    orderData={order}
                    skeleton={false}
                />
            ))}
            </Grid>
        </Grid>
        )
    }

    useEffect(() => {
        const searchKeys = ['order_status', 'order_id', 'order_shipto_id']
        setOrderSearchShowList(applySearchAndFilter(searchValue, searchKeys, orderList, filterMap))
      }, [searchValue, orderList, filterMap])

    return (
        <div>
            <SearchHeaderUI
                label="Create Order"
                shownData={orderSearchShowList}
                setSearchValue={setSearchValue}
                setIsSearching={setIsSearching}

                filterMap={filterMap}
                setFilterMap={setFilterMap}
            />
            <Container>

                {isSearching ? orderListArea(orderSearchShowList): orderListArea(orderList)}

            </Container>
            <AddOrderButton />
        </div>
    );
};

export default CreateOrderPage;

