import React from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
  } from '@mui/material';
import { ArrowBack, ArrowLeft, Home } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import SelectSupplierDropdown from "../../supplier-context/SelectSupplierDropdown";


const HeaderMui = ({
    label,
    backLink = null
}) => {

    const navigate = useNavigate()

    return (
        <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 1200}}>
        <AppBar position="relative">
          <Toolbar>
            { backLink ?
              <IconButton color="inherit" onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            :
            <Link to="/" style={{ color: 'inherit'}}>
              <IconButton color="inherit">
                  <Home />
              </IconButton>
              </Link>
            }
            
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1}}>
              
              <Typography
                variant="h6"
                style={{ flexGrow: 1, textAlign: 'center' }}
              >
                
                {label}
                <SelectSupplierDropdown />
              </Typography>
              
              
            </Box>
            {/* <SelectSupplierDropdown hid={true}/> */}
            
            <IconButton disabled/>
          </Toolbar>
        </AppBar>
      </Box>
    )
}

export default HeaderMui