import React, { createContext, useContext, useState, useEffect } from 'react';
import themes from './themesRegister';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

export function ThemeProviderContext({ children }) {
  const [selectedTheme, setSelectedTheme] = useState('default'); // Set your default theme here

  useEffect(() => {
    // You can load the selected theme from localStorage or any other source
    const savedTheme = localStorage.getItem('selectedTheme');
    if (savedTheme && themes[savedTheme]) {
      setSelectedTheme(savedTheme);
    }
  }, []);

  const setTheme = (theme) => {

    if (themes[theme]) {
      setSelectedTheme(theme);
      localStorage.setItem('selectedTheme', theme);
    }
  };

  const themeKeys = Object.keys(themes);

  const contextValue = {
    themeKeys,
    selectedTheme,
    setTheme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={themes[selectedTheme]}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}
