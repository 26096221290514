import { createSlice } from '@reduxjs/toolkit';

// Define printer status choices
const PRINTER_STATUSES = ['Disconnected', 'Scanning', 'Connecting', 'Connected', 'Printing', 'Printed', 'Error'];

const printerSlice = createSlice({
  name: 'printer',
  initialState: {
    status: 'Disconnected', // Default status
    gattServer: null,
    numberOfPrints: 0
  },
  reducers: {
    setPrinterStatus: (state, action) => {
      const newStatus = action.payload;

      // Check if the provided status is a valid choice
      if (PRINTER_STATUSES.includes(newStatus)) {
        state.status = newStatus;
      } else {
        // If the provided status is not in the valid choices, set to "Disconnected"
        state.status = 'Disconnected';
      }
    },
    setGattServer: (state, action) => {
      state.gattServer = action.payload;

      // Update status based on the presence of gattServer
      state.status = action.payload ? 'Connected' : 'Disconnected';
    },
    incrementPrints: (state) => {
      state.numberOfPrints += 1;
    },
    decrementPrints: (state) => {
      if (state.numberOfPrints > 0) {
        state.numberOfPrints -= 1;
      }
    },
    resetPrints: (state) => {
      state.numberOfPrints = 0;
    }
  },
});

export const {
  setPrinterStatus,
  setGattServer,
  incrementPrints,
  decrementPrints,
  resetPrints,
  makeZpl,
} = printerSlice.actions;

export default printerSlice.reducer;
