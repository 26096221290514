
import { ORDER_CANCELLED, ORDER_CREATED } from "../../components/LABEL_CHOICES";
import { getItemFromStoreById } from "../../utilities/getFromStore";
import { ORDER_STORE } from "../../utilities/indexedDB";
import { updateBackendAndStore } from "../../utilities/updateData";

const order_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`
// const label_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_LABEL_ROUTE}/`


async function extractOrder(orderId){
  "extract the order from the store, in future, might need to extract from online if needed."

  const orderData = await getItemFromStoreById(
    ORDER_STORE, 
    orderId
  )
    return orderData
}

export const addLabelsInOrder = async(orderId, labelIds) => {

  var orderData = await extractOrder(orderId)
  const updatedOrder = { 
    'order_id': orderId,
    'order_label_ids': [...orderData.order_label_ids, ...labelIds]
  }

  await updateBackendAndStore(
    order_url,
    updatedOrder,
    orderId,
    ORDER_STORE
  )
  // await updateLabelsStatus(labelIds, LABEL_ASSIGNED)
} 


export const getActiveOrderId = async() => {
   const orderRef = localStorage.getItem('orderRef')
   return orderRef;
  }

export const getActiveOrder = async() => {
  const orderId = localStorage.getItem('orderRef')
  const orderData = await extractOrder(orderId)
  return orderData
}


export const removeLabelFromOrder = async(orderId, labelId) => {

    const orderData = await extractOrder(orderId)

    const updatedOrder = {
      'order_id': orderId,
      'order_label_ids': orderData.order_label_ids.filter((label_id) =>  label_id !== labelId)
    }

    await updateBackendAndStore(
      order_url,
      updatedOrder,
      orderId,
      ORDER_STORE
    )
    // await updateLabelsStatus([labelId], LABEL_CREATED)
  }

export const cancelOrder = async(orderId) => {
  const order = await extractOrder(orderId)
  if (order?.order_status === ORDER_CREATED){
    await updateBackendAndStore(
      order_url,
      { "order_id": orderId,
        "order_status": ORDER_CANCELLED, 
        "order_label_ids": []},
      orderId,
      ORDER_STORE
    )
  }else{
    console.error("Order can be cancelled only if open!")
  }
}