import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import LabelOptionsMenu from "./menu-label-options";

const LabelHistoryCardMui = ({
  labelData,
  skeleton = false,
}) => {
  const theme = useTheme();

  const cardStyle = {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    margin: "8px",
    flexWrap: "no-wrap",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Paper style={cardStyle}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <div>
            <Typography variant="h6" gutterBottom>
              {skeleton ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                labelData.label_id
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} sx={{ p: 0, m: 0 }}>
          <div>
            <Typography variant="subtitle2" gutterBottom>
              Status:
            </Typography>
            {skeleton ? (
              <Skeleton variant="text" width="100%" />
            ) : (
              labelData.label_status
            )}
          </div>
        </Grid>
        <Grid item xs={8}>
          <div>
            <Typography variant="subtitle2" gutterBottom>
              ASN:
            </Typography>
            {skeleton ? (
              <Skeleton variant="text" width="100%" />
            ) : (
              labelData.label_asn
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Typography variant="subtitle2" gutterBottom>
              Type
            </Typography>
            {skeleton ? (
              <Skeleton variant="text" width="100%" />
            ) : (
              labelData.label_type
            )}
          </div>
        </Grid>
      </Grid>
      {skeleton ? (
        <Skeleton variant="circle" width={24} height={24} />
      ) : (
        <LabelOptionsMenu labelData={labelData} />
      )}
    </Paper>
  );
};

export default LabelHistoryCardMui;
