import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Add } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import {
  List,
  ListItem,
  ListItemButton,
  Checkbox,
  Divider,
  Typography,
  Popover,
  Grid,
} from '@mui/material';

import { getAllItemFromStore } from '../../../utilities/getFromStore';
import { LABEL_STORE } from '../../../utilities/indexedDB';
import { refreshLabelPage, refreshOrders } from '../orderSlice';
import { LABEL_ASSIGNED, LABEL_CREATED, ORDER_ASSIGNED, ORDER_CREATED } from '../../../components/LABEL_CHOICES';
import TextItem from '../../../components/TextItem';
import { addLabelsInOrder, cancelOrder, getActiveOrder, getActiveOrderId } from '../removeUpdateOrderUtil';
import { fetchDataAndStore } from '../../../utilities/fetchData';
import { Link } from 'react-router-dom';


const LabelDialogAddButton = () => {
  const dispatch = useDispatch();
  
  const [labelList, setLabelList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const refreshPage = useSelector((state) => state.order.refreshedLabelPageTime);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  
  useEffect(() => {
    const fetchData = async() => {

      getActiveOrder()
      .then((data) => {
        setIsDisabled(data?.order_status !== ORDER_CREATED)
      })
      .catch(err => console.error(err))
  }
    fetchData()

  }, [refreshPage])

  useEffect(() => {

    getActiveOrder()
    .then(order => {

      setIsDisabled(order?.order_status !== ORDER_CREATED)  
    })
    

      fetchDataAndStore(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_LABEL_ROUTE}/`,
        LABEL_STORE,
        'label_id'
      )
      .then((labels) => {
        setLabelList(labels.filter((label) => 
        label.label_status === LABEL_CREATED)
      )
      })
      .catch((err) => console.error(err))
    
  }, [refreshPage]);


  const handleLabelSelection = (labelItem) => {
    const isSelected = selectedLabels.some((label) => label.label_id === labelItem.label_id);

    if (isSelected) {
      setSelectedLabels((prevSelected) =>
        prevSelected.filter((label) => label.label_id !== labelItem.label_id)
      );
    } else {
      setSelectedLabels((prevSelected) => [...prevSelected, labelItem]);
    }
  };

 
  const handleAddClick = async() => {
    if (selectedLabels) {

      const orderId = await getActiveOrderId();
      const selectedLabelIds = selectedLabels.map((label) => label.label_id)

      await addLabelsInOrder(orderId, selectedLabelIds)
      dispatch(refreshLabelPage());
    }
    setDialogOpen(false);
  };

  return (
    <div>
      <Button 
        variant="outlined" 
        onClick={handleOpenDialog}
        disabled={isDisabled}
        >
        <Add />
        Add Label
      </Button>
      <Dialog 
        fullWidth
        open={dialogOpen} 
        onClose={handleCloseDialog}
        >
        <DialogTitle>Select Label ({selectedLabels.length}): </DialogTitle>
        <Divider/>
        <DialogContent sx={{m: 0, p: 0}}>
            <List>
          {!labelList.length && 
            <ListItem>
              <Typography>
                Add more <Link to="/aiag-label/">label</Link>
              </Typography>
            </ListItem>}
          {labelList.map((labelItem) => (
            <React.Fragment key={labelItem.label_id}>
              <ListItem>
                <ListItemButton onClick={() => handleLabelSelection(labelItem)}>
                  <Checkbox
                    edge="start"
                    checked={selectedLabels.some((label) => label.label_id === labelItem.label_id)}
                    tabIndex={-1}
                    disableRipple
                  />
                  <Grid container spacing={0}>
                    <TextItem
                      itemKey={"Label Id"}
                      itemValue={`${labelItem.label_id}`}
                      xsSize={12}
                      mdSize={4}
                      />
                      <TextItem
                      itemKey={"Part Number"}
                      itemValue={`${labelItem?.label_part?.part_number}`}
                      xsSize={7}
                      mdSize={4}
                      />
                      <TextItem
                      itemKey={"Color Code"}
                      itemValue={`${labelItem?.label_part?.color_code}`}
                      xsSize={5}
                      mdSize={4}
                      />
                    </Grid>
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleAddClick}
            color="primary"
            disabled={!selectedLabels.length || !labelList.length}
          >
            Add
          </Button>

          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LabelDialogAddButton;





