import React from 'react';

import { useSupplierContext } from './SupplierThemeContext';
import { TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';


const SelectSupplierDropdown = ({
  hid=false}
  ) => {
  const { suppliers, selectedSupplier, selectSupplier } = useSupplierContext();

  const handleSupplierChange = (event) => {
    localStorage.setItem('selectedSupplier', event.target.value);
    selectSupplier(event.target.value);
  };

  return (
    <div hidden={hid}>
    {!suppliers ? <div>Server Error!</div>:

    <TextField
      select
      value={selectedSupplier}
      onChange={handleSupplierChange}
      variant="standard"
      InputProps={{
        style: { color: 'white' },
        startAdornment: (
          <InputAdornment position="start">
            <span style={{ marginRight: '4px', color: 'white' }}>
              Supplier:
            </span>
          </InputAdornment>
        ),
      }}
    >
      {suppliers && suppliers.map((supplier) => (
        <MenuItem key={supplier} value={supplier}>
          {supplier}
        </MenuItem>
      ))}
    </TextField>
  }
  </div>
  );
};

export default SelectSupplierDropdown;
