export const LABEL_CREATED = "OPEN"
export const LABEL_ASSIGNED = "ASSIGNED"
export const LABEL_SHIPPED = "SHIPPED"
export const LABEL_CANCELLED = "CANCELLED"

export const ORDER_CREATED = "OPEN"
export const ORDER_ASSIGNED = "ASSIGNED"
export const ORDER_COMPLETED = "COMPLETED"
export const ORDER_SHIPPED = "SHIPPED"
export const ORDER_CANCELLED = "CANCELLED"


export const QRL_CREATED = "OPEN"
export const QRL_ASSIGNED = "ASSIGNED"
export const QRL_SHIPPED = "SHIPPED"
// export const QRL_CANCELLED = "CANCELLED"

export const ASN_CREATED = "OPEN"
export const ASN_SHIPPED = "CLOSED"
export const ASN_CANCELLED = "CANCELLED"

  
export const TAG_LABEL_TYPE = "AIAG"
export const TAG_QRL_TYPE = "QRL"