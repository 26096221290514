import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
} from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PrinterLabel from '../../../components/PrinterLabel/PrinterLabel';
import NumberField from '../../../components/NumberField/NumberField';
import { useDispatch } from 'react-redux';
import { setAlert, setError } from '../../../components/alert-message/alerMessageSlice';
import HeaderMui from '../../../components/headers/mui-header/HeaderMui';
import { QRL_CREATED, TAG_QRL_TYPE } from '../../../components/LABEL_CHOICES';
import { is_none } from '../../../components/validations';
import { fetchDataFromUrl } from '../../../utilities/fetchData';
import { createQrlZpl, handleMultipleLabelPrinting } from '../handlePrint';
import { clearLoadingMessage, setLoadingMessage } from '../../../components/loading-dialog/loadingMessageSlice';
import { sortList } from '../../../utilities/utils';
import { useGattServer } from '../../../components/PrinterLabel/GattServerContext';
import { useSupplierContext } from '../../../components/supplier-context/SupplierThemeContext';


const QrlMainPage = () => {
  const dispatch = useDispatch();
  const {gattServer} = useGattServer();
  const {selectedSupplier} = useSupplierContext();

  // Define the initial form state
  const initialFormState = {
    label_supplier_id: '', // Dropdown value
    qrl_shipto_id: '',
    num_labels: 1,
    num_copies: 1,
    label_type: TAG_QRL_TYPE,
    label_status: QRL_CREATED,
  };

  // State to hold form data
  const [labelData, setLabelData] = useState(initialFormState);
  const [shipToOptions, setshipToOptions] = useState([]);

  useEffect(() => {

      if(!selectedSupplier) return

      dispatch(setLoadingMessage("Loading shipto..."))
      fetchDataFromUrl(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_SHIPTO_ROUTE}/`,
      ).then((_shipToOptions) => {
        const shiptos = sortList(_shipToOptions, 'shipto_id')
        setshipToOptions(shiptos)
        setLabelData({
          ...labelData,
          label_supplier_id: selectedSupplier,
          qrl_shipto_id: shiptos?.[0]?.shipto_id
        });      
      })
      .catch(err => {
        console.error(err)
        dispatch(setError("Cannot load shipto!"))
      })
      .finally(() => {
        dispatch(clearLoadingMessage())
      })

  },[selectedSupplier])

  // Handle changes in form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLabelData({
      ...labelData,
      [name]: value,
    });
  };

  const validateData = () => {
    if (is_none(labelData.qrl_shipto_id)){
      dispatch(setAlert({severity: "error", message:"ShipTo is required!"}))
      return false;
    }
    return true;
  }

  // Handle form submission (printing)
  const handlePrint = async() => {
    if (validateData()){
      try{
      await handleMultipleLabelPrinting(labelData, createQrlZpl, gattServer)
    }catch(error){
      console.error(error)
    }
      handleClear()
   }
  };

  const handleClear = () => {
    setLabelData({
      ...labelData,
      qrl_shipto_id: shipToOptions?.[0]?.shipto_id,
      num_labels: 1, 
      num_copies: 1
    });
  }

  return (
    <div>
      <HeaderMui label={"QRL Label Generation"} />

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PrinterLabel/>
          </Grid>
          <Grid item xs={12} >

            <TextField
              fullWidth
              disabled
              label="Supplier Id"
              name="label_supplier_id"
              variant="filled"
              margin="normal"
              value={labelData.label_supplier_id}
            />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                label="ShipTo"
                select
                fullWidth
                name="qrl_shipto_id"
                variant='outlined'
                margin="normal"
                value={labelData.qrl_shipto_id}
                onChange={handleInputChange}
                >
                  {
                    shipToOptions.map((shipto, index) => (
                      <MenuItem 
                        key={shipto.shipto_id} 
                        value={shipto.shipto_id}
                      >
                        {shipto.shipto_id}
                      </MenuItem>
                    ))
                  }
              </TextField>
            </Grid>
          

        {/* # Labels and # Copies */}
        <Grid item xs={12} >
            <Grid container 
                sx={{ display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-around' 
                }}
                columnSpacing={4}
                >
                <Grid item xs={6}>
                    <NumberField
                    label="# Labels"
                    name="num_labels"
                    value={labelData.num_labels}
                    onChange={(newValue) =>
                        setLabelData({ ...labelData, num_labels: parseInt(newValue) })
                    }
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberField
                    label="# Copies"
                    name="num_copies"
                    value={labelData.num_copies}
                    onChange={(newValue) =>
                        setLabelData({ ...labelData, num_copies: parseInt(newValue) })
                    }
                    />
                </Grid>
            </Grid>
          </Grid>

          {/* Print Button */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="text"
                color="primary"
                size="large"
                onClick={handleClear}
                >Clear</Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handlePrint}
                startIcon={<LocalPrintshopOutlinedIcon />}
              >
                Print
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default QrlMainPage;
