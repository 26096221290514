// deleteStore.js
import { createSlice } from '@reduxjs/toolkit';

const deleteSlice = createSlice({
  name: 'partDelete',
  initialState: {
    deleteList: [], // Initial deleteList is an empty array
  },
  reducers: {
    addToDelete: (state, action) => {
      // Add an item to the list of items to be deleted
      if (!state.deleteList.includes(action.payload)) {
        state.deleteList.push(action.payload);
      }
      console.log("Delete state changed to (%f):" + state.deleteList, state.deleteList.length);
    },
    removeFromDelete: (state, action) => {
      // Remove an item from the list of items to be deleted
      const index = state.deleteList.indexOf(action.payload);
      if (index !== -1) {
        state.deleteList.splice(index, 1);
      }
      console.log("Delete state changed to (%f):" + state.deleteList, state.deleteList.length);
    },
    clearDelete: (state) => {
      // Clear the list of items to be deleted
      console.log("Delete state changed to (%f):" + state.deleteList, state.deleteList.length);
      state.deleteList = [];

      return state;
    },
  },
});

export const { addToDelete, removeFromDelete, clearDelete } = deleteSlice.actions;
export default deleteSlice.reducer;

export const selectDeleteState = (state) => state.partDelete.deleteList;
