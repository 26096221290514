import { createSlice } from '@reduxjs/toolkit';

// Define the initial state with a default loadingMessage
const initialState = {
  message: null, // Rename the state variable to 'message'
};

// Create a Redux slice
const loadingMessageSlice = createSlice({
  name: 'loadingMessage', // Name of the slice
  initialState,           // Initial state
  reducers: {
    setLoadingMessage: (state, action) => {
      // Update the 'message' with the payload
      state.message = action.payload; // Rename the state variable here as well
    },
    clearLoadingMessage: (state) => {
      state.message = null;
    }
  },
});

// Export the action creator
export const { setLoadingMessage, clearLoadingMessage } = loadingMessageSlice.actions;

// Export the reducer with a different name
export default loadingMessageSlice.reducer; // Rename the reducer
export const selectLoadingMessage = (state) => state.loadingMessage;

