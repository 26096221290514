import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteConfirmationOverlay = ({ isOpen, setIsOpen, setIsConfirmed }) => {

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)} // Close the dialog when clicking outside
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setIsConfirmed(true); setIsOpen(false)}} color="secondary">
            <DeleteIcon></DeleteIcon>Delete
          </Button>
          <Button onClick={() => setIsOpen(false)} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmationOverlay;
