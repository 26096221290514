import React, { useState, useReducer, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Divider,
  Box,
} from "@mui/material";
import {
  ArrowBack,
  Close as CloseIcon,
  FilterList,
  Home as HomeIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { FitlerOverlayMUI } from "../../../overlays/FilterOverlayMUI/FilterOverlayMUI";
import SelectSupplierDropdown from "../../supplier-context/SelectSupplierDropdown";

const initialState = {
  headerType: "default", // Start with "default" by default
};

const SearchHeaderUI = ({
  label = "Label",
  shownData,
  
  setSearchValue,
  setIsSearching,

  filterMap=null,
  setFilterMap=null

}) => {
  const [search, setSearch] = useState("");
  const [state, dispatch] = useReducer(headerReducer, initialState);
  const [isFilterOverlayOpen, setIsFilterOverlayOpen] = useState(false);

  useEffect(() => {
    setSearchValue(search);
  },[search])

  const handleSearchClear = () => {
    setSearch("");
  };

  function headerReducer(state, action) {
    switch (action.type) {
      case "OPEN_SEARCH":
        setIsSearching(true);
        return {
          ...state,
          headerType: "search",
        };
  
      case "GO_BACK":
        setIsSearching(false);
        return {
          ...state,
          headerType: "default",
        };
  
      default:
        return state;
    }
  }

  return (
    <div style={{position: "sticky", top: 0, zIndex:1200}}>
      
    <AppBar position="relative" >
      <Toolbar>
        {state.headerType === "default" && (
          <>
              <Link to="/" style={{color:'inherit'}}>
                <IconButton color="inherit">
                  <HomeIcon />
                  </IconButton>
              </Link>

              
            
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
              {label}
              <SelectSupplierDropdown />
            </Typography>


            <IconButton
              onClick={() => dispatch({ type: "OPEN_SEARCH" })}
              aria-label="search"
              color="inherit"
            >
              <SearchIcon />
            </IconButton>
          </>
        )}
        {state.headerType === "search" && (
          <Box width="100%" sx={{mt:1}}>
            <Box 
              component="form" 
              noValidate 
              sx={{display:"flex", flexDirection:"row", mb:1}}>
              <IconButton
                onClick={() => { dispatch({ type: "GO_BACK" })}}
                aria-label="back"
                color="inherit"
              >
                <ArrowBack />
              </IconButton>

              <TextField
                variant="standard"
                placeholder="Search"
                value={search}
                onChange={(e) => {setSearch(e.target.value)}}
                style={{ flexGrow: 1, backgroundColor:"white", borderRadius:"50px", padding:8}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {search && (
                        <IconButton color="inherit" edge="end" onClick={handleSearchClear}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton color="inherit" onClick={()=> dispatch({ type: "OPEN_SEARCH" })}>
                <SearchIcon/>
              </IconButton>

            </Box>
            <Divider
                color="inherit"
                orientation="horizontal" 
                sx={{backgroundColor: "white", color:"white"}}
                 />

            <Box sx={{display: "flex", 
              justifyContent:"space-between",
              alignItems:"center",
              mt:1,
              mb:1
              }}>
              
              <Typography variant="body2" sx={{flexGrow: 2}}>
                Showing {shownData.length} results...
              </Typography>

              <Button
                color="inherit"
                variant="outlined"
                onClick={() => setIsFilterOverlayOpen(true)}
                sx={{mr:1}}
              >
                <FilterList />
                <Typography color="inherit" variant="body2">Filters</Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>

    <FitlerOverlayMUI 
      searchedFilteredData={shownData}
      filterMap={filterMap}
      setFilterMap={setFilterMap}
      isOpen={isFilterOverlayOpen}
      setIsOpen={setIsFilterOverlayOpen}
     />
    </div>
  );
};

export default SearchHeaderUI;

