import { openDB } from "./indexedDB";
import axios from 'axios';
import { setLoadingMessage, clearLoadingMessage } from "../components/loading-dialog/loadingMessageSlice";
import { setAlert } from "../components/alert-message/alerMessageSlice";
import store from "../store";
import { handleErrorResponse } from "./handleErrors";
const dispatch = store.dispatch;

export async function updateBackendAndStore(url, data, referenceId, storeName){
    try {
      dispatch(setLoadingMessage("updating server..."))
      const _response = await patchDataToUrl(url, data, referenceId);
      
      dispatch(setLoadingMessage("updating in store..."))
      await putItemInStore(storeName, _response);
      
      console.log('Data updated in IndexedDB:', _response);
      // dispatch(setAlert({severity:"success", message:"Item updated!"}))
      return _response
  
    } catch (error) {
      console.error(error)
      throw(error)
    } finally {
      dispatch(clearLoadingMessage());
    }
  }
  
async function patchDataToUrl(url, data, referenceId) {
    const access_token = JSON.parse(localStorage.getItem('userData'))['access_token'];
    const patchUrl = `${url}${referenceId}/`;
    // console.log(data)
    try {
      const response = await axios.patch(
        patchUrl,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`
          },
          withCredentials: true
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw(error)
      // handleErrorResponse(error)
    }
  }


async function putItemInStore(storeName, item) {
    const db = await openDB();
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.put(item);
}