import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const ConfirmationDialogTag = ({ 
  onConfirm, 
  children, 
  dialogMessage="" }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <div >
        <div onClick={handleOpen}>
            {children}
        </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage ? dialogMessage : "Are you sure you want to delete?"}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="secondary">
            <Delete/>
            Delete
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialogTag;
