export const printLabel = async (gattServer, zpl) => {
    if (! gattServer){
      throw(Error("Not Connected to Server!"))
    }

    try{
        const selectedServiceUUID = '38eb4a80-c570-11e3-9507-0002a5d5c51b';
        const selectedCharacteristicUUID = '38eb4a82-c570-11e3-9507-0002a5d5c51b';

        const encoder = new TextEncoder('utf-8');
        const data = encoder.encode(zpl);

        // Get the selected service and characteristic
        const selectedService = await gattServer.getPrimaryService(selectedServiceUUID);
        const selectedCharacteristic = await selectedService.getCharacteristic(selectedCharacteristicUUID);

        selectedCharacteristic.writeValue(data)
        .then(() => {
            return true
        })
        .catch((error) => {
            console.error(error);
            alert("Failed to send ZPL, check with administrator!");
        })
    }catch(error){
        alert("Please check console for error!")
        console.error(error);
    }
};

export async function makeZpl(values, template){
    console.log(values);
    for (const key in values) {
      const placeholder = new RegExp(`\\$${key}`, 'g');  // Removed the trailing curly brace
      
      if (values[key]){
        template = template.replace(placeholder, values[key]);
      }else{
        template = template.replace(placeholder, ``);
      }
    }
    template = template.replace(/\\&\\&/g, '\\&');
    return template;
  }



