// theme1.js
import { createTheme } from '@mui/material/styles';

const theme1 = createTheme({
  palette: {
    primary: {
      main: '#000',
      light: '#f0f0f0'
    },
  },
});

export default theme1;
