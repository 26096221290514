import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPrinterStatus } from './printerSlice';


// Create a context with an initial value (in this case, null)
const GattServerContext = createContext(null);

// Create a provider component that will wrap your app and provide the GattServer value
export const GattServerProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [gattServer, setGattServer] = useState(null);

  if (!gattServer){
    dispatch(setPrinterStatus("Disconnected"))
  }

  return (
    <GattServerContext.Provider value={{gattServer, setGattServer}}>
      {children}
    </GattServerContext.Provider>
  );
};

// Create a hook to easily access the GattServer value in your components
export const useGattServer = () => {
  const context = useContext(GattServerContext);
  if (!context) {
    throw new Error('useGattServer must be used within a GattServerProvider');
  }
  return context;
};


