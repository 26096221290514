import React, { useState } from 'react';
import { List, ListItem, ListItemText, Popover, Typography, Button } from '@mui/material';

const EnvironmentInfo = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0 }}>
      <Button  onClick={handleClick} >
        v1.0
      </Button> 
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typography >
                  Backend env: {process.env.REACT_APP_ENV} | Build date: {process.env.REACT_APP_DATE} {/* Auth api: {process.env.REACT_APP_AUTH} */}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default EnvironmentInfo;
