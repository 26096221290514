import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Checkbox,
  DialogActions,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllItemFromStore } from '../../../utilities/getFromStore';
import { ORDER_STORE } from '../../../utilities/indexedDB';

import { refreshQrlPage } from '../asnSlice';
import { ASN_SHIPPED, ORDER_COMPLETED, ORDER_CREATED } from '../../../components/LABEL_CHOICES';
import TextItem from '../../../components/TextItem';
import { assignOrdersToQrl, getActiveAsn } from '../removeUpdateAsnUtil';
import { fetchDataAndStore } from '../../../utilities/fetchData';
import { Link } from 'react-router-dom';

const OrderAddDialogButton = ({qrlId}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState(new Set());
  const [orderList, setOrderList] = useState([]);
  const pageRefresh = useSelector((state) => state.asn.refreshedQrlPageTime)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    const fetchdata = async() => {
      const asnData = await getActiveAsn()
      setIsDisabled(asnData.asn_status === ASN_SHIPPED)
      // get all the unassigned orders from the store
      // var _orders = await getAllItemFromStore(ORDER_STORE, 'order_status')
      fetchDataAndStore(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_ORDER_ROUTE}/`,
        ORDER_STORE,
        'order_status'
      ).then((orders) => {
        const _orders = orders?.filter((order) => (
        order.order_status === ORDER_COMPLETED &&
        order.order_shipto_id === asnData.asn_shipto_id
        ))
        setOrderList(_orders)
      })
      .catch(err => {
        console.error(err)
      })
      
    }
    fetchdata()
  },[pageRefresh])


  const toggleMark = (item) => {
    const newSelectedOrderIds = new Set(selectedOrderIds);
    if (newSelectedOrderIds.has(item)) {
      newSelectedOrderIds.delete(item);
    } else {
      newSelectedOrderIds.add(item);
    }
    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleSave = async() => {
    // assign orders to the qrl
    await assignOrdersToQrl(selectedOrderIds, qrlId)
    
    setOpen(false);
    dispatch(refreshQrlPage());
  }

  const dialogTitle = `Select Orders: (${selectedOrderIds.size})`;

  return (
    <div>
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={handleOpen}
        disabled={isDisabled}
        >
        <Add />
        Add Order
      </Button>
      <Dialog 
        fullWidth
        open={open} 
        onClose={handleClose} 
        >
        <DialogTitle>{dialogTitle}
        <Typography variant='subtitle2'>
                  *Order must be completed and have same shipto id to be visible here.
                </Typography>
        </DialogTitle>
        
        <Divider />
        
          <List>
            {!orderList?.length && 
              <ListItem>
                  <Typography variant='body1'>
                    <Link to="/create-order/">Add</Link> more order
                  </Typography>
              </ListItem>}
            {orderList?.map((order, index) => (
              
              <ListItem divider key={`${order.order_id}-${index}`} onClick={() => toggleMark(order.order_id)}>
                
                  <ListItemButton>
                    <Checkbox
                      checked={selectedOrderIds.has(order.order_id)}
                      onChange={() => toggleMark(order.order_id)}
                    />
                    <Grid container spacing={1}>
                      <TextItem
                        itemKey={"Order Id"}
                        itemValue={order.order_id}
                        xsSize={12}
                        mdSize={12}
                        />
                      
                    </Grid>
                  </ListItemButton>
                </ListItem>
            ))}

          </List>
        {/* </DialogContent> */}
        <Divider/>
        <DialogActions>
            

          <Button 
            disabled={!orderList?.length}
            onClick={handleSave} 
            color="primary" 
            variant='contained'>
            Add
          </Button>

          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OrderAddDialogButton;
