export function calculateTimeAgo(previousTime, currentTime) {
  const currentDate = new Date();
  const previousDate = new Date(previousTime);
  const timeDifference = currentDate - previousDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Roughly, assuming 30 days in a month
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
}

export const sortList = (itemList, sortKey) => 
  Array.isArray(itemList) && itemList.length
    ? [...itemList].sort((a, b) =>
        a[sortKey] === undefined || b[sortKey] === undefined
          ? 0
          : typeof a[sortKey] === 'string'
          ? a[sortKey].localeCompare(b[sortKey])
          : a[sortKey] - b[sortKey]
      )
    : itemList;


export function formatTimestampToDateTime(timestamp) {
    // Create a Date object from the timestamp (assumes the timestamp is in milliseconds)
    const date = new Date(timestamp);
  
    // Get the individual date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Create the formatted date and time string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    return formattedDateTime;
  }

export const triggerBackgroundSync = () => {
    if ("serviceWorker" in navigator && "SyncManager" in window) {
      navigator.serviceWorker.ready
        .then((swRegistration) => {
          return swRegistration.sync.register("sync-data");
        })
        .catch((error) => {
          console.error("Error registering sync event:", error);
        });
    }
  };

export function generateUniqueNumber(prefix) {
  // This function generates a unique ID using a combination of a timestamp and a random number.
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 100000); // Adjust the range as needed
  return `${prefix}-${timestamp}-${random}`;
}

export function generateUniquePartNumber() {
    // This function generates a unique ID using a combination of a timestamp and a random number.
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 100000); // Adjust the range as needed
    return `${timestamp}-${random}`;
  }

  export function generateUniquPartId() {
    // This function generates a unique ID using a combination of a timestamp and a random number.
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 100000); // Adjust the range as needed
    return random;
  }

export function generateRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

export function generateRandomDescription() {
    const descriptions = [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "Sed vel suscipit urna, sit amet lacinia libero.",
      "Fusce eget ex ut neque dictum efficitur.",
      "Vestibulum pharetra ante et eros bibendum, a lacinia justo malesuada.",
      "Sed eget arcu non est euismod posuere.",
    ];
    const randomIndex = Math.floor(Math.random() * descriptions.length);
    return descriptions[randomIndex];
  }
  
  