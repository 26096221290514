import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";

import AsnDrawerButton from "./AsnHistoryCardMenu";
import TextItem from "../../../components/TextItem";

const AsnHistoryCard = ({
  asnData,
  skeleton = false,
}) => {
  const theme = useTheme();

  const cardStyle = {
    padding: theme.spacing(2),
    border: theme.shadows[24],
    borderRadius: theme.spacing(2),
    margin: theme.spacing(1),
    flexWrap: "no-wrap",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const asnItem = (itemKey, itemValue, xsSize, mdSize) =>{
    return (
      <TextItem 
        itemKey={itemKey} 
        itemValue={itemValue} 
        xsSize={xsSize} 
        mdSize={mdSize} 
        skeleton={false}/>
    )
  }

  return (
    <Paper style={cardStyle}>
      <Grid container spacing={1}>
        {asnItem("ASN Id", asnData?.asn_id, 6, 3)}
        {asnItem("SCAC - Trailer", asnData?.asn_scac + " - "+ asnData?.asn_trailer, 6, 3)}
        {asnItem("Shipto Id", asnData?.asn_shipto_id, 6, 3)}
        {asnItem("Status", asnData?.asn_status, 6, 3)}

      </Grid>
      {skeleton ? (
        <Skeleton variant="circle" width={24} height={24} />
      ) : (
        <AsnDrawerButton asnData={asnData} />
      )}
    </Paper>
  );
};

export default AsnHistoryCard;
