import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import SearchHeaderUI from "../../../components/headers/search-header/SearchHeader";
import { ASN_STORE } from "../../../utilities/indexedDB";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import CreateAsnCard from "./CreateAsnCard";
import { applySearchAndFilter } from "../../../utilities/searchFilter";
import AddAsnDialogButton from "./AddAsnDialogButton";
import { ASN_CREATED } from "../../../components/LABEL_CHOICES";
import asnFilterMapFn from "../asnFilterMapFn";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";


const CreateAsnPage = () => {
    const navigate = useNavigate()
    
    const [isSearching, setIsSearching] = useState(false);
    const [asnList, setAsnList] = useState([]);
    const [filterMap, setFilterMap] = useState({})

    const [searchValue, setSearchValue] = useState(""); 
    const [asnSearchShowList, setAsnSearchShowList] = useState([]);
    const {selectedSupplier} = useSupplierContext();
 
    const pageRefresh = useSelector((state) => state.asn.refreshedAsnPageTime)

    // Check permissions
    usePermissionCheck({
        requestType: 'view',
        tableName:'asntable',
        onClose: () => navigate("/"),
        message: "You don't have permission to view Asn, check with your administrator!"
      })

    // Intialisation
    useEffect(() => {
        const fetchData = async() => {
            const _asnList = await fetchDataAndStore(
                `${process.env.REACT_APP_API}/${process.env.REACT_APP_ASN_ROUTE}/`,
                ASN_STORE,
                'asn_id'
            )
            if (!_asnList) return;
            
            const filteredAsnList = _asnList.filter((_asnItem) => (
                _asnItem.asn_status === ASN_CREATED
            ))

            setAsnList(filteredAsnList)
            setFilterMap(asnFilterMapFn(filteredAsnList));
            setAsnSearchShowList(filteredAsnList);
        }
        fetchData()

    },[pageRefresh, selectedSupplier])

    const asnListArea = (asnList) => {
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            {asnList.map((asn, index) => (
                <CreateAsnCard
                    key={asn + index}
                    asnData={asn}
                    skeleton={false}
                />
            ))}
            </Grid>
        </Grid>
        )
    }

    useEffect(() => {
        const searchKeys = ['asn_status', 'asn_id', 'asn_shipto_id']
        setAsnSearchShowList(applySearchAndFilter(searchValue, searchKeys, asnList, filterMap))
      }, [searchValue, asnList, filterMap])

    return (
        <div>
            <SearchHeaderUI
                label="Create Asn"
                shownData={asnSearchShowList}
                setSearchValue={setSearchValue}
                setIsSearching={setIsSearching}

                filterMap={filterMap}
                setFilterMap={setFilterMap}
            />
            <Container>

                {isSearching ? asnListArea(asnSearchShowList): asnListArea(asnList)}

            </Container>
            <AddAsnDialogButton />
        </div>
    );
};

export default CreateAsnPage;

