// shipToSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shipsRefreshedTime: new Date().toISOString()
};

const shipToSlice = createSlice({
  name: 'shipTo',
  initialState,
  reducers: {
    updateShipsRefreshedTime: (state) => {
      state.shipsRefreshedTime = new Date().toISOString();
    }
  },
});

export const { updateShipsRefreshedTime } = shipToSlice.actions;

export default shipToSlice.reducer;
