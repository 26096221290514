import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { closeDialog } from "./confirmationDialogSlice";

const ConfirmationDialog = () => {
  const dispatch = useDispatch();
  const confirmationDialog = useSelector((state) => state.confirmationDialog);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleConfirmAction = () => {
    if (confirmationDialog.onConfirm) {
      confirmationDialog.onConfirm();
    }
    handleClose();
  };

  return (
    <div>
      {confirmationDialog?.isOpen && (
        <Dialog open={true} onClose={handleClose}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmationDialog.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmAction} color="secondary">
              <Delete />
              Confirm
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ConfirmationDialog;
