import { openDB } from "./indexedDB";
import axios from 'axios';
// import { setLoadingMessage, clearLoadingMessage } from "../components/loading-dialog/loadingMessageSlice";
// import { setAlert } from "../components/alert-message/alerMessageSlice";
// import store from "../store";
import { handleErrorResponse } from "./handleErrors";
// const dispatch = store.dispatch;


export async function postDataAndPutInStore(url, data, storeName){

  try {

    // dispatch(setLoadingMessage("saving in backend..."))
    const _response = await postDataToUrl(url, data);
    
    // dispatch(setLoadingMessage("Saving to store..."))
    await putItemInStore(storeName, _response)
    
    console.log('Data stored in IndexedDB:', _response);
    return _response

  } catch (error) {
    const message = error.message
    console.error('Error:', message);
    
  } 

}

async function postDataToUrl(url, data) {
  const access_token = JSON.parse(localStorage.getItem('userData'))['access_token']

  try {
    const response = await axios.post(
      url, 
      data, 
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`
        }
      }
    );
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    handleErrorResponse(error);
    throw error;
  }
}


async function putItemInStore(storeName, item) {
    const db = await openDB();
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.put(item);
  }


