import store from "../store";
import { set401Error } from "./error/error401Slice";
import { setError } from "../components/alert-message/alerMessageSlice";
const dispatch = store.dispatch;

export function handleErrorResponse(error) {

    if (error.response) {
        // Log the entire error response
        console.error('Error:', error.response);

        // Handle specific error cases based on the status code
        switch (error.response.status) {
            case 400:
                dispatch(setError('400 Bad request: item cannot be updated!'))
                // throw new Error('Bad request: item cannot be updated!');
            case 401:
                dispatch(setError('401 Token Expired/Unauthroised: Please log in.'))
                dispatch(set401Error());
                // console.error(error);
                // throw new Error('401 Token Expired/Unauthroised: Please log in.');
            case 403:
                dispatch(setError('403 Forbidden: You do not have permission to perform this action.'))
                // throw new Error('Forbidden: You do not have permission to perform this action.');
            case 423:
                dispatch(setError('423 Locked: Your access has expired, contact administrator...'));
                // throw new Error('Locked: Your access has expired, contact administrator...');
            // Add more cases as needed
            default:
                dispatch(setError('500 Error: An unexpected error occurred. Please try again later.'))
                // throw new Error('Error: An unexpected error occurred. Please try again later.');
        }
    } else {
        // Log the error message for non-response errors
        console.error('Error:', error.message);

        // Throw the original error for non-response errors
        throw error;
    }
}
