import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import CancelIcon from "@mui/icons-material/Cancel";
import { ArrowForward, MoreVert as MoreVertIcon } from "@mui/icons-material";
import { AppBar, Container, Grid, Paper, Toolbar, Typography } from "@mui/material";
import LabelPreviewCard from "./labelPreviewCard";
import { createAiagLabel, createQrlZpl } from "../../handlePrint";
import { useGattServer } from "../../../../components/PrinterLabel/GattServerContext";
import { printLabel } from "../../../../components/PrinterLabel/printerUtils";
import { useDispatch } from "react-redux";
import { setAlert, setError } from "../../../../components/alert-message/alerMessageSlice";
import { LABEL_CREATED, LABEL_CANCELLED } from "../../../../components/LABEL_CHOICES";
import { updateBackendAndStore } from "../../../../utilities/updateData";
import { LABEL_STORE } from "../../../../utilities/indexedDB";
import { clearLoadingMessage, setLoadingMessage } from "../../../../components/loading-dialog/loadingMessageSlice";
import { refreshLabelHistoryPage, refreshLabelPage } from "../../labelSlice";

const LabelOptionsMenu = ({ labelData }) => {
  const {gattServer} = useGattServer();
  const dispatch  = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleCancelLabel = () => {

      if (labelData.label_status === LABEL_CREATED){
        
        var label_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_LABEL_ROUTE}/`
        if (labelData.label_type === 'QRL')
          label_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_QRL_ROUTE}/`

        dispatch(setLoadingMessage("updating label..."))
        updateBackendAndStore(
          label_url,
          {label_status: LABEL_CANCELLED},
          labelData.label_id,
          LABEL_STORE
        ).then((data) => {
          // dispatch refresh history page
          if (data.label_status === LABEL_CANCELLED){
            dispatch(setAlert({severity:"success", message:`label: ${labelData.label_id} cancelled!`}))
          }else{
            dispatch(setError("cannot update status!"))
          }
          dispatch(refreshLabelHistoryPage())
        }).catch((err) => {
          dispatch(setError("Cannot update status!"))
          console.error(err)
        }).finally(() => {
          dispatch(clearLoadingMessage())
        })
      
    }else {
      dispatch(setError("Only OPEN Label can be cancelled!"))
    }
  }

  const handleReprint = async() => {
    try{
      if (labelData.label_type === 'AIAG'){
        const zpl = await createAiagLabel(labelData, false)
        await printLabel(gattServer, zpl)

      }else if(labelData.label_type === 'QRL'){
        const zpl = await createQrlZpl(labelData, false)
        await printLabel(gattServer, zpl)
        
      }else {
        dispatch(setError("No suitable label type found!"))
      }
    }catch(error){
      console.error(error)
      dispatch(setError("Failed to re-print!"))
    }
  };

  return (
    <>
      <IconButton aria-label="more" size="small" onClick={handleOpenDrawer}>
        <ArrowForward></ArrowForward>
      </IconButton>
        <Drawer
          anchor="bottom"
          open={drawerOpen}
          onClose={handleCloseDrawer}
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            width: "100%",
          }}
        >
        <AppBar
          position="static"
          color="primary"
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Toolbar>
              <Typography variant="h6">{labelData.label_id}</Typography>
            </Toolbar>
          </AppBar>
          
          <Container>
              <Grid container sx={{mb:4}}>
                <Grid item xs={12} style={{margin: 32, }}>
                  <LabelPreviewCard formValues={labelData} />
                </Grid>

                <Grid item xs={12} sx={{display: "flex", justifyContent: "space-evenly"}} >
                  
                  <Button
                    disabled={labelData.label_status !== LABEL_CREATED}
                    variant="outlined"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={handleCancelLabel}
                  >
                    Cancel
                  </Button>
                  <Button 
                    size="large" 
                    variant="contained" 
                    color="primary" 
                    startIcon={<PrintIcon />}
                    onClick={handleReprint}
                    >
                    Reprint
                  </Button>
                </Grid>

              </Grid>
          </Container>
        </Drawer>
      
    </>
  );
};

export default LabelOptionsMenu;
