// Removing means remove from it the ids used to refer it.

import {  getItemFromStoreById } from "../../utilities/getFromStore";
import { ASN_STORE, QRL_STORE } from "../../utilities/indexedDB";
import { updateBackendAndStore } from "../../utilities/updateData";
import { ASN_CANCELLED, ASN_CREATED,} from "../../components/LABEL_CHOICES";
import { fetchDataFromUrl } from "../../utilities/fetchData";


const ASN_URL = `${process.env.REACT_APP_API}/${process.env.REACT_APP_ASN_ROUTE}/`
const QRL_URL = `${process.env.REACT_APP_API}/${process.env.REACT_APP_QRL_ROUTE}/`


export async function emailMplBol(asnId){
    const EMAIL_URL = `${process.env.REACT_APP_API}/${process.env.REACT_APP_EMAIL_MPL_BOL}/${asnId}/`
    return fetchDataFromUrl(EMAIL_URL)
}   

async function extractItem(itemId, itemStore){
    const itemData = await getItemFromStoreById(itemStore, itemId)
    return itemData
}

export async function getActiveAsn(){
    const _asnId = await JSON.parse(localStorage.getItem('asnRef'))
    const _asnData = await extractItem(_asnId, ASN_STORE)
    return _asnData
}

export async function assignOrderToQrl(orderId, qrlId) {
    const qrlData = await extractItem(qrlId, QRL_STORE)
    const updatedQrl = {
        'qrl_order_ids': [...qrlData.qrl_order_ids, orderId]
    }

    await updateBackendAndStore(
        QRL_URL,
        updatedQrl,
        qrlId,
        QRL_STORE
    )

}

export async function assignOrdersToQrl(orderIds, qrlId){
    const qrlData = await extractItem(qrlId, QRL_STORE)
    const updatedQrl = {
        'qrl_order_ids': [...qrlData.qrl_order_ids, ...orderIds]
    }

    await updateBackendAndStore(
        QRL_URL,
        updatedQrl,
        qrlId,
        QRL_STORE
    )
}

export async function assignQrlToAsn(qrlId, asnId){
    const asnData = await extractItem(asnId, ASN_STORE)
    const udpatedAsn = {
        'asn_qrl_ids': [...asnData.asn_qrl_ids, qrlId]
    }
    await updateBackendAndStore(
        ASN_URL,
        udpatedAsn,
        asnId,
        ASN_STORE
    )
}

export const unAssignOrderFromQrl = async(orderId, qrlId) => {
    const qrlData = await extractItem(qrlId, QRL_STORE);
    const updatedQrl = {
        'qrl_order_ids': qrlData.qrl_order_ids.filter((oId) => oId !== orderId)
    }
    // updated qrl
    await updateBackendAndStore(
        QRL_URL,
        updatedQrl,
        qrlId,
        QRL_STORE
    )
}

export const unAssignQrlFromAsn = async(qrlId, asnId) => {

    await updateBackendAndStore(
        QRL_URL,
        {"qrl_order_ids": []},
        qrlId,
        QRL_STORE
    )

    const asnData = await extractItem(asnId, ASN_STORE)
    const updatedAsnData = {
        'asn_qrl_ids': asnData.asn_qrl_ids.filter((qId) => qId !== qrlId)
    }
    await updateBackendAndStore(
        ASN_URL,
        updatedAsnData,
        asnId,
        ASN_STORE
    )
  }

export const cancelAsn = async(asnId) => {
    const asn = await extractItem(asnId, ASN_STORE)
    if (asn?.asn_status === ASN_CREATED){
    await updateBackendAndStore(
        ASN_URL,
        {"asn_status": ASN_CANCELLED, "asn_qrl_ids": []},
        asnId,
        ASN_STORE
    )
}
}


//====================== Close ASN =====================
