import { openDB } from "./indexedDB";


//======================

export async function getItemFromStoreById(storeName, itemId) {
    const db = await openDB();
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
  
    return new Promise((resolve, reject) => {
      const request = store.get(itemId);
  
      request.onsuccess = (event) => {
        const item = event.target.result;
        if (item) {
          console.log('item retrieved successfully:', item);
          resolve(item);
        } else {
          console.warn('item not found for ID:', itemId);
          resolve(null); // Resolve with null when item is not found
        }
      };
  
      request.onerror = (event) => {
        const error = event.target.error;
        console.error('Error retrieving item:', error);
        reject(error);
      };
    });
  }
  
  
  export async function getAllItemFromStore(storeName, sortKey) {
    const db = await openDB();
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
  
    return new Promise((resolve, reject) => {
      const request = store.getAll();
  
      request.onsuccess = (event) => {
        const items = event.target.result;
        if (items) {
          items.sort((a, b) => {
            const valueA = a[sortKey] || '';
            const valueB = b[sortKey] || '';
            return valueA.localeCompare(valueB);
          });
          resolve(items);
        } else {
          resolve([]); // Return an empty array if no items were found
        }
      };
  
      request.onerror = (event) => {
        const error = event.target.error;
        console.error('Error retrieving items:', error);
        reject(error);
      };
    });
  }
  