import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  IconButton,
  Drawer,
  InputAdornment,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { closePmEditOverlay, selectIsOverlayOpen, selectOpenRefId } from "./pmEditOverlaySlice";
import PmPreviewCard from "../PartDetailCard/PmPreviewCard";
import { getItemFromStoreById } from "../../../utilities/getFromStore";
import { PARTS_STORE } from "../../../utilities/indexedDB";
import { setPartsRefresh } from "../PmMainPage/partsSlice";
import { updateBackendAndStore } from "../../../utilities/updateData";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";

export const PmEditOverlay = () => {
  const [part, setPart] = useState({});
  const dispatch = useDispatch();
  const openRefId = useSelector(selectOpenRefId);
  const isOverlayOpen = useSelector(selectIsOverlayOpen);
  const [errors, setErrors] = useState({ quantity: "", partWeight: "" });

  usePermissionCheck({ requestType: 'change', 
                       tableName: 'partstable', 
                       onClose: () => dispatch(closePmEditOverlay()),
                       message: "User don't have permission to Edit parts!"
                      });

  const handleCloseDrawer = () => {
    dispatch(closePmEditOverlay());
  };

  useEffect(() => {
    const fetchPart = async () => {
      try {
        const part = await getItemFromStoreById(
          PARTS_STORE,
          openRefId
        );
        setPart(part);
      } catch (error) {
        console.error("Error fetching part:", error);
      }
    };
    
    if(isOverlayOpen){
     fetchPart()
    }
  }, [openRefId]);

  const handleChange = (event) => {
    const {name, value} = event.target
    setPart( {
      ...part,
      [name]: value
    });

    // Validate the quantity field
    if (name === "container_qty" && !/^\d+$/.test(value)) {
      setErrors({ ...errors, [name]: "Quantity must be an integer" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }

    // Validate the "Part Weight" field
  if (name === "part_weight") {
    // Regular expression to match decimal or integer values
    const validValue = /^\d+(\.\d*)?$/.test(value);
    if (!validValue) {
      // Set an error message if the value is not valid
      setErrors({ ...errors, [name]: "Part Weight must be a decimal or integer" });
    } else {
      // Clear the error message if the value is valid
      setErrors({ ...errors, [name]: "" });
    }
  }
  }

  const handleSubmit = async() => {

    await updateBackendAndStore(
      process.env.REACT_APP_PART,
      part,
      part.part_id,
      PARTS_STORE
    )
    dispatch(setPartsRefresh());
    handleCloseDrawer();
  }

  return (

    <Drawer
      disableBackdropClick
      anchor="bottom"
      open={isOverlayOpen}
      onClose={handleCloseDrawer}
      elevation={4}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <AppBar position="sticky" top={0} 
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }} component="div">
                Edit: {part.part_number}
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="close"
              sx={{ mr: 2 }}
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container
          fixed
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "16px", // Add some padding for spacing
            overflow: "auto",
          }}
        >
          <div style={{ marginTop: 16, marginBottom: "32px", alignSelf:"center" }}>
            <PmPreviewCard formValues={part}/>
          </div>
          <FormControl
              variant="standard" 
              fullWidth 
              style={{marginTop: 16}}>

            <TextField
              id="outlined-basic"
              label="Part Weight"
              variant="outlined"
              name="part_weight"
              value={part.part_weight}
              onChange={handleChange}

              fullWidth
              style={{ marginBottom: 16 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">lbs</InputAdornment>
              }}
              error={!!errors.partWeight} // Set error prop based on validation result
              helperText={errors.partWeight} // Display validation error message
                />
            
            <TextField
              id="outlined-basic"
              label="Color"
              variant="outlined"
              name="color_code"
              value={part.color_code}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: 16 }}
            />

            <TextField
              id="outlined-basic"
              label="Quantity"
              variant="outlined"
              name="container_qty"
              value={part.container_qty}
              onChange={handleChange}
              style={{ marginBottom: 16 }}
              fullWidth
              error={!!errors.container_qty} // Set error prop based on validation result
              helperText={errors.container_qty} // Display validation error message
            />


          <TextField
              id="outlined-basic"
              label="DC Part Level"
              variant="outlined"
              name="dc_part_level"
              value={part.dc_part_level}
              onChange={handleChange}
              style={{ marginBottom: 16 }}
            />

          <TextField
              id="outlined-basic"
              label="Container Type"
              variant="outlined"
              name="container_type"
              value={part.container_type}
              onChange={handleChange}
              style={{ marginBottom: 16 }}
            />

            <TextField
              variant="outlined"
              label="Description"
              name="part_description"
              value={part.part_description}
              onChange={handleChange}
              multiline
              rows={4}
              style={{ marginBottom: 32 }}
            />
            <Button 
              style={{ bottom: 16}}
              onClick={handleSubmit} 
              variant="contained" 
              color="primary">
              Update
            </Button>
          </FormControl>
        </Container>
      </Paper>
    </Drawer>
  );
};
