export default function asnFilterMapFn(items) {
    return {
      "asn_shipto": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.asn_shipto_id)) {
            return true;
          }
        },
        key: "asn_shipto",
        filter_values: [...new Set(items?.map((part) => part.asn_shipto_id))].sort(),
        values: [...new Set(items?.map((part) => part.asn_shipto_id))].sort(),
        name: "ASN Ship-To"
      },
      "asn_trailer": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.asn_trailer)) {
            return true;
          }
        },
        key: "asn_trailer",
        filter_values: [...new Set(items?.map((part) => part.asn_trailer))].sort(),
        values: [...new Set(items?.map((part) => part.asn_trailer))].sort(),
        name: "ASN Trailer"
      },
      "asn_scac": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.asn_scac)) {
            return true;
          }
        },
        key: "asn_scac",
        filter_values: [...new Set(items?.map((part) => part.asn_scac))].sort(),
        values: [...new Set(items?.map((part) => part.asn_scac))].sort(),
        name: "ASN SCAC"
      }
      
    };
  }