import React, { useState } from 'react';
import { Button, Drawer, Grid, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ViewListIcon from '@mui/icons-material/ViewList';
import CancelIcon from '@mui/icons-material/Cancel';
import { HighlightOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router';
import { cancelOrder } from '../removeUpdateOrderUtil';
import { refreshOrders } from '../orderSlice';
import { ORDER_CREATED } from '../../../components/LABEL_CHOICES';
import { setError } from '../../../components/alert-message/alerMessageSlice';

const CreateOrderCardMenu = ({ orderData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };

  const buttonData = [
    { label: 'View Labels', icon: <ViewListIcon />, callback: () => handleButtonClick('View LPN') },
    { label: 'Cancel', icon: <CancelIcon />, callback: () => handleButtonClick('Cancel') },
  ];

  const handleButtonClick = async(buttonLabel) => {
    // Handle button click here, e.g., call a function or perform an action
    // console.log(`Clicked: ${buttonLabel}`);
    
    if (buttonLabel === 'View LPN'){
      localStorage.setItem('orderRef', orderData.order_id);
      navigate('/create-order/add-label/')
    }else if(buttonLabel === 'Cancel') {
      // dispatch(openDialog({onConfirm: async() => {await cancelOrder(orderData.order_id)} }))
      if(orderData?.order_status === ORDER_CREATED){
        await cancelOrder(orderData.order_id)
        dispatch(refreshOrders())
        setOpen(false);
    }else{
      dispatch(setError("Order can be cancelled if OPEN only!"))
    }
    }else {
      dispatch(setError("Not Implemented!"))
    }
  }

  const list = (
    <div>
      {buttonData.map((item, index) => (
          <ListItem key={item.label}>
            <ListItemButton onClick={item.callback} divider >
              <ListItemIcon >
                {item.icon}
              </ListItemIcon>
              <ListItemText>
                <Typography variant="button">{item.label}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
      ))}
    </div>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon />
      </Button>
      <Drawer 
        anchor="bottom" 
        open={open} 
        onClose={toggleDrawer(false)}
        >
        <IconButton size="large" onClick={toggleDrawer(false)}>
          <HighlightOff/>
        </IconButton>
        {list}
      </Drawer>
    </div>
  );
};

export default CreateOrderCardMenu;
