import axios from "axios";
import { openDB } from "./indexedDB";
import { clearLoadingMessage, setLoadingMessage } from "../components/loading-dialog/loadingMessageSlice";
import { setAlert, setError } from "../components/alert-message/alerMessageSlice";
import store from "../store";
import { handleErrorResponse } from "./handleErrors";
const dispatch = store.dispatch;


export async function fetchDataFromUrl(url) {
  const accessToken = JSON.parse(localStorage.getItem('userData'))?.access_token || ''
  const supplierId = localStorage.getItem('selectedSupplier') || null

  try {
    const response = await axios.get(url, {
      headers : {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        supplierId: supplierId
      }
    });
    // Access the data from the response object
    if (response.status === 200){
      return response.data
    }
  } catch (error) {
    console.error(error);
    handleErrorResponse(error);
    throw(error)
  }
}

async function storeDataInIndexedDB(data, storeName) {
  // update the store once get all the ships from backend.
  dispatch(setLoadingMessage("saving data..."))
  try{
      const db = await openDB();
      const tx = db.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      store.clear();
      for (const item of data) {
          store.add(item)
      }
      await tx.complete;
  } catch(error){
      dispatch(setAlert({severity:"error", message:"Failed to save data!"}))
      console.error(error)
  }finally {
      dispatch(setLoadingMessage(null))
  }
}

export async function fetchDataAndStore(apiUrl, storeName, sortKey) {
  try {
    
    dispatch(setLoadingMessage("Loading data..."))
    const _data = await fetchDataFromUrl(apiUrl);
    
    _data && _data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    await storeDataInIndexedDB(_data, storeName);
    console.log('Data stored in IndexedDB:', _data);

    return _data;

  } catch (error) {
    console.error(error);

  } finally {
    dispatch(clearLoadingMessage());
  }
}





