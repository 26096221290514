import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Container, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import { 
  AccountTreeOutlined, 
  DirectionsBoatOutlined, 
  Inventory2Outlined, 
  ListAltOutlined, 
  LocalShippingOutlined, 
  LogoutOutlined, 
  Menu as MenuIcon, 
  PendingActionsOutlined, 
  PrintOutlined, 
  QrCode, 
  WorkHistoryOutlined,
  Person as PersonIcon,
  Password as PasswordIcon
} from "@mui/icons-material";
import { useUser } from "../UserPage/UserContext";
import { setError } from "../../components/alert-message/alerMessageSlice";
import { useDispatch } from "react-redux";
import { APP_NAME } from "../../variables";
import LogoIcon from "../../components/logoIcon/LogoIcon";
import { useSupplierContext } from "../../components/supplier-context/SupplierThemeContext";
import { clearLoadingMessage, setLoadingMessage } from "../../components/loading-dialog/loadingMessageSlice";
import { fetchDataFromUrl } from "../../utilities/fetchData";
import { handleErrorResponse } from "../../utilities/handleErrors";


export const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();
  const theme = useTheme();
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const { setSuppliersList, selectSupplier} = useSupplierContext();

  const checkExpiry = async() => {
    const user = localStorage.getItem('userData')

    if (user?.password_expiry_days < 1){
      dispatch(setError("Password has expired!"))
      navigate("/update-password")
    }
  }

  useEffect(() => {
    checkExpiry()
  })

  useEffect(() => {
    // initialise suppliers 
    const storedSupplier = localStorage.getItem('selectedSupplier')
    if(storedSupplier) return;

    const supplierUrl = `${process.env.REACT_APP_API}/${process.env.REACT_APP_SUPPLIER_IDS_ROUTE}/`
    dispatch(setLoadingMessage("Reading Suppliers..."))

    fetchDataFromUrl(
      supplierUrl
    ).then((suppliers)=> {
      setSuppliersList(suppliers)
      const storedSupplier = localStorage.getItem('selectedSupplier') || '';
      if (storedSupplier){
        selectSupplier(storedSupplier)
      }else {
        console.log(`setting supplier as: ${suppliers[0]}`)
        selectSupplier(suppliers[0])
        localStorage.setItem('selectedSupplier', suppliers[0])
      }
    }).catch(err => {
        dispatch(setError("Cannot read suppliers!"))
        console.error(err)
      })
    .finally(() => {
      dispatch(clearLoadingMessage())
    })

  }, []);

  useEffect(() =>{
    const userTimeout = setInterval(() => {
      if (!user){
        dispatch(setError("User Not Found!"));
        navigate("/login")
      }
    }, 1000)

    return () => {
      clearTimeout(userTimeout);
    }
  }, [])

  const labelGenerationData = [
    {
      to: '/aiag-label',
      label: 'AIAG Label',
      icon: <PrintOutlined style={{ fontSize: '48px' }} color="primary" />,
    },
    {
      to: '/qrl-label',
      label: 'QRL Label',
      icon: <QrCode style={{ fontSize: '48px' }} color="primary" />,
    },
    {
      to: '/label-history',
      label: 'Label History',
      icon: <PendingActionsOutlined style={{ fontSize: '48px' }} color="primary" />,
    }
  ];

  const asnLabels = [
    {
      label: 'Create Order',
      to: '/create-order',
      icon: <Inventory2Outlined style={{ fontSize: '48px' }} color="primary" />
    },
    {
        label: 'Order History',
        to: '/order-history',
        icon: <ListAltOutlined style={{ fontSize: '48px' }} color="primary" />
    },
    {
      label: 'Create ASN',
      to: '/create-asn',
      icon: <LocalShippingOutlined style={{ fontSize: '48px' }} color="primary" />,
    },
    {
      label: 'ASN History',
      to: '/asn-history',
      icon: <WorkHistoryOutlined style={{ fontSize: '48px' }} color="primary" />,
    }
  ];

  const maintenanceLabels = [
    {
      label: 'Part Master',
      to: '/part-master',
      icon: <AccountTreeOutlined style={{ fontSize: '48px' }} color="primary" />,
    },
    {
      label: 'Ship To',
      to: '/ship-to',
      icon: <DirectionsBoatOutlined style={{ fontSize: '48px' }} color="primary" />,
    },
    // Add more labels as needed
  ];

  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  function password_expiry_days(){
    const userData = JSON.parse(localStorage.getItem('userData'));
    const days_to_expire = userData?.password_expiry_days
    if (days_to_expire){
      return `Expiring in ${days_to_expire} days`
    }else{
      return null
    }
  }

  function logged_account(){
    const userData = JSON.parse(localStorage.getItem('userData'));
    const username = userData?.username
    if (username){
      return username
    }else{
      return null
    }
  }

  return (
    <div >
      <Drawer 
          anchor="left"
          open={leftDrawerOpen}
          onClose={() => setLeftDrawerOpen(false)}
            >
              <Box sx={{width: 250, display: "flex", flexDirection: "column", flexGrow: 1}}>
                <List sx={{ display: "flex", flexDirection: "column", flexGrow: 2}}>
                  <ListItem key={"title"} onClick={logout}>
                    <Paper elevation={3} sx={{p:2, textAlign: "center"}}>
                      <LogoIcon /> <div/>
                      {APP_NAME}</Paper>
                  </ListItem>

                  <ListItem key={"change"} onClick={() => {navigate('/update-password')}} sx={{p:0}}>
                    <ListItemButton>
                      <ListItemIcon>
                        <PasswordIcon/>
                      </ListItemIcon>
                      <ListItemText primary={"Change Password"} secondary={password_expiry_days()}/>
                    </ListItemButton>
                  </ListItem>

                  <ListItem key={"logout"} onClick={logout} sx={{p:0}}>
                    <ListItemButton>
                        <ListItemIcon>
                          <LogoutOutlined /> 
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} secondary={logged_account()} />
                      </ListItemButton>
                  </ListItem>

                  <div style={{flexGrow: 1}}></div>

                  <Divider/>
                  <ListItem key={"Build Date"} >
                    <ListItemText secondary={`Build: ${process.env.REACT_APP_DATE}`}>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Drawer>

      <Box sx={{flexGrow: 1, position: "sticky", top: 0, zIndex:1200}}>
        <AppBar position="relative" >
            <Toolbar>
            
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setLeftDrawerOpen(true)}
            >
            <MenuIcon />
          </IconButton>
            <Typography variant="h6" style={{flexGrow: 1, textAlign:"center"}}>
              {APP_NAME}
            </Typography>
            <IconButton 
              disabled
              size="large"
              edge="start"
              color="inherit"
              sx={{ ml: 2 }}
            />
            </Toolbar>
        </AppBar>
      </Box>
      
      <Container sx={{mt: theme.spacing(2)}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Welcome!</Typography>
          </Grid>

          <LinkList listData={labelGenerationData} label="Label Generation" />
          <LinkList listData={asnLabels} label="Shipping" />
          <LinkList listData={maintenanceLabels} label="Maintenance" />

        </Grid>
      </Container>
    </div>
  );
};


function LinkList({ listData, label }) {
  return (
    <Grid item xs={12} >
      <Paper elevation={3} sx={{p:2}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={400} gutterBottom>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              {listData.map((link, index) => (
                <Link to={link.to} style={{ textDecoration: 'none' }} key={index}>
                  <Stack direction="column" alignItems="center" sx={{ mr: 4 }}>
                    {link.icon}
                    <Typography variant="body1" color="primary" textAlign='center'>{link.label}</Typography>
                  </Stack>
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    
  );
}
