export function is_none(item){
    if (!item || item === null || item === ""){
        return true;
    }
}

export function is_non_positive(item){
    if(item === 0 || item < 0){
        return true
    }
}