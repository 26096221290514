export default function orderFilterMapFn(items) {
    return {
      "order_shipto_id": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.order_shipto_id)) {
            return true;
          }
        },
        key: "order_shipto_id",
        filter_values: [...new Set(items?.map((part) => part.order_shipto_id))].sort(),
        values: [...new Set(items?.map((part) => part.order_shipto_id))].sort(),
        name: "Order Ship-To"
      },
      "order_status": {
        funct: (filterValues, item) => {
          if (filterValues.includes(item.order_status)) {
            return true;
          }
        },
        key: "order_status",
        filter_values: [...new Set(items?.map((part) => part.order_status))].sort(),
        values: [...new Set(items?.map((part) => part.order_status))].sort(),
        name: "Status"
      }
      
    };
  }