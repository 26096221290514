import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const NumberField = ({ label, value, onChange }) => {

  
  const handleIncrease = () => {
    onChange(value + 1);
  };

  const handleDecrease = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <TextField
      label={label}
      type="number"
      fullWidth
      variant="outlined"
      margin="normal"
      InputProps={{
        inputProps: { min: 0 },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="Decrease"
              onClick={handleDecrease}
              edge="start"
            >
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Increase"
              onClick={handleIncrease}
              edge="end"
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default NumberField;
