// themes.js
import theme1 from './theme1';
import theme2 from './theme2';
import customTheme from './customTheme';

const themes = {
  'default': theme2,
  'black': theme1,
  'teal': theme2,
};

export default themes;
