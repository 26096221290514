import React, { useState } from 'react';
import { Button, TextField, Typography, Grid, Alert, Box, AppBar, IconButton, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAlert, setError } from '../../components/alert-message/alerMessageSlice';
import axios from 'axios';
import LogoIcon from '../../components/logoIcon/LogoIcon';
import { APP_NAME } from '../../variables';
import { clearLoadingMessage, setLoadingMessage } from '../../components/loading-dialog/loadingMessageSlice';
import HeaderMui from '../../components/headers/mui-header/HeaderMui';
import { ArrowBack } from '@mui/icons-material';



const UserResetPasswordOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage] = useState("");
  const [formValues, setFormValues] = useState({ 
    username: "", 
    new_password: "", 
    confirm_password: "",  
    otp: ""
});

  const handleNextClick = async () => {
    const resetUrl = `${process.env.REACT_APP_API}/${process.env.REACT_APP_RESET_PASSWORD_ROUTE}/`
    dispatch(setLoadingMessage("Reseting password..."))
    await axios.post(resetUrl, 
        formValues, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          withCredentials: true
        }).then(response => {
            dispatch(setAlert({severity: "success", message: response?.data?.message}));
            navigate("/login");
            
        }).catch(err => {
            // setErrorMessage(err?.response?.data?.error);
            if (err?.response?.data?.error)
              dispatch(setError(err?.response?.data?.error));
            else if(err?.response?.data?.new_password){
              dispatch(setError(err?.response?.data?.new_password[0]));
            }else{
              dispatch(setError("Cannot reset password!"))
            }
            console.error(err)
        }).finally(() => {
            dispatch(clearLoadingMessage())
        })
    
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    setFormValues({
      ...formValues,
      [name]: val,
    });
  };

  return (
    <div>
    <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 1200}}>
        <AppBar position="relative">
          <Toolbar>
              <IconButton color="inherit" onClick={() => navigate('/login')}>
                <ArrowBack />
              </IconButton>
              <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1}}>
              
              <Typography
                variant="h6"
                style={{ flexGrow: 1, textAlign: 'center' }}
              >
                
                Reset Password
              </Typography>  
            </Box>
            {/* <SelectSupplierDropdown hid={true}/> */}
            
            <IconButton disabled/>
            </Toolbar>
          </AppBar>
      </Box>

      <Grid container spacing={3} sx={{ textAlign: "center", mt: 5 }}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 6 }}>
          <LogoIcon/>
          <div/>
          {APP_NAME}
          <div>
          Reset Password
          </div>
        </Typography>
        
      </Grid>

        <Grid item xs={12} >
        {errorMessage && <Alert severity='error' sx={{mt:1}}>{errorMessage}</Alert>}
        </Grid>
      
      <Grid item xs={12}>
        <TextField
          type="text"
          label="Username"
          name="username"
          required
          value={formValues.username}
          onChange={handleChange}
          variant="outlined"
          sx={{width:"300px"}}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField

          type='password'
          label="New Password"
          name="new_password"
          value={formValues.new_password}
          onChange={handleChange}
          variant="outlined"
          sx={{width:"300px"}}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          
          type='password'
          label="Confirm Password"
          name="confirm_password"
          value={formValues.confirm_password}
          onChange={handleChange}
          variant="outlined"
          sx={{width:"300px"}}
          disabled={!formValues.new_password}
          error={formValues.new_password !== formValues.confirm_password}
          helperText={ formValues.new_password !== formValues.confirm_password ? "Password does not match":""}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type='text'
          label="OTP"
          name="otp"
          value={formValues.otp}
          onChange={handleChange}
          variant="outlined"
          sx={{width:"300px"}}
        />
      </Grid>

      <Grid item xs={12}>
        <Button 
            disabled={!formValues.new_password || !formValues.otp || !formValues.username || formValues.new_password !== formValues.confirm_password}
            variant="contained" 
            color="primary" 
            onClick={handleNextClick}>
          Reset Password 
        </Button>
      </Grid>
    </Grid>
    </div>
  );
};

export default UserResetPasswordOtp;
