// partUpdateTriggerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const partsSlice = createSlice({
  name: 'parts',
  initialState: {
    parts_timestamp: new Date().toISOString(),
  },
  reducers: {
    setPartsRefresh: (state) => {
      state.parts_timestamp = new Date().toISOString();
    },
  },
});

export const { setPartsRefresh } = partsSlice.actions;
export default partsSlice.reducer;