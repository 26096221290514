import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Add } from '@mui/icons-material';
import { Container, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { getAllItemFromStore, getItemFromStoreById} from '../../../utilities/getFromStore';
import {  ASN_STORE, QRL_STORE } from '../../../utilities/indexedDB';
import { refreshQrlPage } from '../asnSlice';
import { ASN_CREATED, ASN_SHIPPED, QRL_CREATED } from '../../../components/LABEL_CHOICES';
import { assignQrlToAsn, getActiveAsn } from '../removeUpdateAsnUtil';
import TextItem from '../../../components/TextItem';
import { is_none } from '../../../components/validations';
import { fetchDataAndStore } from '../../../utilities/fetchData';
import {Link} from "react-router-dom"

const AddQrlDialogButton = () => {
  const dispatch = useDispatch();
  const [qrlList, setQrlList] = useState([]);
  const [asnData, setAsnData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedQRL, setSelectedQRL] = useState({});
  const refreshPage = useSelector((state) =>  state.asn.refreshedQrlPageTime);


  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  async function getShiptoId(){
    // const _asnId = await JSON.parse(localStorage.getItem('asnRef'));
    // const _asnData = await getItemFromStoreById(ASN_STORE, _asnId);
    const _asnData = await getActiveAsn()
    
    return _asnData.asn_shipto_id
  }

  useEffect(() => {
    const fetchData = async() => {

      const _asnData = await getActiveAsn()
      setAsnData(_asnData)

      const qrl_url = `${process.env.REACT_APP_API}/${process.env.REACT_APP_QRL_ROUTE}/`
      const _qrls = await fetchDataAndStore(
        qrl_url,
        QRL_STORE,
        'label_id'
      )

      const shiptoId = await getShiptoId()

      const _qrl_labels = _qrls?.filter((qrl) => 
        qrl.label_status === QRL_CREATED &&
        qrl.qrl_shipto_id === shiptoId
      )
      setQrlList(_qrl_labels)
    }
    fetchData();
  }, [refreshPage]);

  const handleQRLSelection = (qrl) => {
    setSelectedQRL(qrl);
  };

  const handleAddButtonClick = async() => {
    if (selectedQRL) {

      var asnId = await JSON.parse(localStorage.getItem('asnRef'))
      await assignQrlToAsn(selectedQRL.label_id, asnId)
      
    }
    setDialogOpen(false);
    dispatch(refreshQrlPage());
  };

  return (
    <div>
      <Button 
        variant="outlined" 
        onClick={handleOpenDialog}
        disabled={asnData?.asn_status !== ASN_CREATED}
        >
        <Add />
        Add QRL
      </Button>
      <Dialog 
        fullWidth
        open={dialogOpen} 
        onClose={handleCloseDialog}
        >
        <DialogTitle>Select QRL: </DialogTitle>
        <Divider />
            <DialogContent>   
              <List>
                  {!qrlList?.length && 
                    <Typography>
                      Print more <Link to="/qrl-label/">QRL</Link>
                    </Typography>
                  }
                  {qrlList?.map((qrlItem) => (
                    <React.Fragment key={qrlItem.label_id}>
                    <ListItem key={qrlItem.label_id}>
                        <ListItemButton onClick={() => handleQRLSelection(qrlItem)} 
                                        selected={selectedQRL && selectedQRL.label_id === qrlItem.label_id}>

                          <TextItem
                            itemKey={"QRL Id"}
                            itemValue={qrlItem.label_id}
                            sxSize={12}
                            mdSize={12}
                            />

                        </ListItemButton>
                    </ListItem>
                    </React.Fragment>
                  ))}
              </List>
        </DialogContent>
        <Divider />
        <DialogActions>
        <Button
            onClick={handleAddButtonClick}
            variant="contained"
            color="primary"
            disabled={!selectedQRL || !qrlList?.length}
          >
            Add
          </Button>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddQrlDialogButton;

