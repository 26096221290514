import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link } from "react-router-dom";
import { Homepage } from './pages/Homepage/Homepage';

import UserLogin from './pages/UserPage/UserLoginPage';
import UserResetPasswordOtp from './pages/UserPage/UserResetPasswordOtp';

// import { Container, ThemeProvider } from '@mui/material';
import AlertMessage from './components/alert-message/AlertMessageMui';
import LoadingOverlay from './components/loading-dialog/LoadingOverlay';

// Maintenance Section
import { PmMainPage } from './sections/partsSection/PmMainPage/PmMainPage';
import ShipToMainPage from './sections/ShipToSection/ShipToMainPage/page-shipto-main';

// ASN Section
import AddQrlPage from './sections/AsnSection/add-qrl-page/AddQrlPage';
import CreateAsnPage from './sections/AsnSection/create-asn-page/CreateAsnPage';
import AsnHistoryPage from './sections/AsnSection/asn-history-page/AsnHistoryPage';

// LABEL SECTION
import AiagMainPage from './sections/labelSection/LabelAiagMainPage/AiagMainPage';
import QrlMainPage from './sections/labelSection/LabelQrlMainPage/QrlMainPage';
import LabelHistoryMainPage from './sections/labelSection/LabelHistoryMainPage/page-label-history-main';
import Error401Redirect from './utilities/error/Error401Redirect';
import { ThemeProviderContext } from './theme/ThemeProviderContext';

import CreateOrderPage from './sections/OrderSection/create-order-page/CreateOrderPage';
import OrderHistoryPage from './sections/OrderSection/order-history-page/OrderHistoryPage';
import AddLabelPage from './sections/OrderSection/add-label-page/AddLabelPage';
import EnvironmentInfo from './components/version-item/VersionItem';
import ConfirmationDialog from './components/dialog-confirmation/ConfirmationDialog';
import UserChangePassword from './pages/UserPage/UserChangePassword';


function App() {

  return (
        <div>
          {/* <Container maxWidth="xl" sx={{height:"100vh"}}> */}
          
            {/* <PermissionProvider> */}
              <Router>
                <Routes>
                    <Route path="/login" element={<UserLogin />} />
                    <Route path="/" index element={<Homepage/> } />
                    <Route path="/update-password" element={<UserChangePassword/>} />
                    <Route path="/reset-password" element={<UserResetPasswordOtp />} />
                    <Route path="/aiag-label" element={<AiagMainPage/>} />

                    <Route path="/qrl-label" element={<QrlMainPage/>} />
                    <Route path="/label-history" element ={<LabelHistoryMainPage/>} />
                    
                    <Route path="/create-order" element={<CreateOrderPage/>} />
                    <Route path="/create-order/add-label" element={<AddLabelPage />} />
                    <Route path="/order-history" element={<OrderHistoryPage/>} />

                    <Route path="/create-asn" element={<CreateAsnPage />} />
                    <Route path="/create-asn/add-qrl" element={<AddQrlPage />}/>
                    <Route path="/asn-history" element={<AsnHistoryPage />} />

                    <Route path="/part-master" element={<PmMainPage/>} />
                    <Route path="/ship-to" element={<ShipToMainPage />} />
                    <Route path="/*" element={<Homepage/> } />
                  </Routes>
                  <Error401Redirect/>
                </Router>
              {/* </PermissionProvider> */}
              <LoadingOverlay/>
              <AlertMessage/>

              {/* <div style={{height: "10vh"}}></div> */}

              <EnvironmentInfo />
              <ConfirmationDialog />
              
        </div>
  );
}

export default App;
