import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  Paper,
  ListItem,
  Typography,
  Grid,
  IconButton,
  Divider,
  Button,
  useTheme,
  Box,
} from '@mui/material';
import { DeleteForever, RemoveCircleOutline } from '@mui/icons-material';
import { refreshQrlPage } from '../asnSlice';
import {  QRL_STORE } from '../../../utilities/indexedDB';
import {  getItemFromStoreById } from '../../../utilities/getFromStore';
import ConfirmationDialogTag from '../../../components/dialog-confirmation/ConfirmationDialogTag';
import { getActiveAsn, unAssignOrderFromQrl, unAssignQrlFromAsn } from '../removeUpdateAsnUtil';
import OrderAddDialogButton from './OrderAddDialogButton';
import TextItem from '../../../components/TextItem';
import { ASN_SHIPPED } from '../../../components/LABEL_CHOICES';
import OrderDetailDialogItem from './OrderDetailDialogItem';
import { setError } from '../../../components/alert-message/alerMessageSlice';
// import QRLCard from '../../AsnCreateSection/page-add-qrl/dialog-add-lpn/QRLCard';
// import OrderAddDialogButton from './OrderAddDialogButton'
// Assuming OrderCard and AddOrderButtonDialog components are correctly imported

const SelectedQrlCard = ({ refreshTrigger, asnId, qrlId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState();
  const [qrlData, setQrlData] = useState({});
  const [orderIdList, setOrderIdList] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchData = async () => {
      const asnData = await getActiveAsn()  
      setIsDisabled(asnData.asn_status === ASN_SHIPPED)

      const _qrlData = await getItemFromStoreById(QRL_STORE, qrlId);
      setQrlData(_qrlData)

      setOrderIdList(_qrlData?.qrl_order_ids);
    };
    fetchData();
  }, [refreshTrigger, qrlId]);

  const getDisplayItems = () => {
    return expanded ? orderIdList : orderIdList.slice(0, 2);
  };

  const handleRemoveQrl = async () => {

    unAssignQrlFromAsn(qrlData?.label_id, asnId)
    .then(data => {
      console.log("Unassigned QRL from ASN")
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      dispatch(refreshQrlPage());
    })
    
  };

  const handleRemoveOrder = async (orderId) => {
    unAssignOrderFromQrl(orderId, qrlData?.label_id)
    .then(data => {
      console.log(data)
    }).catch(err => {
      console.error(err)
      dispatch(setError("Error! Cannot remove order!"))
    }).finally(() => {
      dispatch(refreshQrlPage());
    })
  };

  return (
    <List>
      <Paper elevation={3}>
        <ListItem sx={{ backgroundColor: 'whitesmoke', width: '100%' }}>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6" color="textPrimary" textAlign="center">
                QRL: {qrlData?.label_id} ({(orderIdList && orderIdList.length) || 0})
              </Typography>
            </Grid>
            <Grid item xs={1}>
                <IconButton disabled={isDisabled} color="secondary">
                  <ConfirmationDialogTag onConfirm={handleRemoveQrl}>
                    <DeleteForever />
                    </ConfirmationDialogTag>
                </IconButton>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          
          <OrderAddDialogButton qrlId={qrlData?.label_id} />
        </ListItem>

        {orderIdList &&
          getDisplayItems().map((orderId) => (
            <ListItem key={orderId} divider>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              > 
                <Grid container spacing={1}>
                  
                <OrderDetailDialogItem
                  itemKey={"Order Id"}
                  orderId={orderId}
                  xsSize={11}
                  mdSize={11}
                />
                <Grid item xs={1}>
                  <IconButton 
                    disabled={isDisabled}
                    onClick={() => handleRemoveOrder(orderId)}
                  >
                    <RemoveCircleOutline 
                    color={'dark'}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              </Box>
            </ListItem>
          ))}
        <Divider />
        {orderIdList && orderIdList.length > 2 && (
          <ListItem divider>
            <Button onClick={toggleExpand}>
              {expanded ? 'See Less' : 'See More'}
            </Button>
          </ListItem>
        )}
      </Paper>
    </List>
  );
};

export default SelectedQrlCard;

