import React from 'react';
import { useTheme } from '@mui/material/styles';
import logo192 from './hlna_icon_with_bg/logo192.png';
import logo512 from './hlna_icon_with_bg/logo512.png';
import logo16 from './hlna_icon_with_bg/logo16.png';
import logo32 from './hlna_icon_with_bg/logo32.png';

const LogoIcon = () => {
  const theme = useTheme();
  const isLargeScreen = theme.breakpoints.values.md <= theme.breakpoints.width;

  const imageSize = {
    width: isLargeScreen ? 64 : 50,
    height: 'auto', // Maintain aspect ratio
  };

  const getLogoImage = () => (isLargeScreen ? logo192 : logo512);

  return <img src={getLogoImage()} alt="Logo" style={imageSize} />;
};

export default LogoIcon;
