import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import Grid from "@mui/material/Grid";
import { openPmEditOverlay } from "../PmEditOverlay/pmEditOverlaySlice";
import {
  addToDelete,
  removeFromDelete,
  selectDeleteState,
} from "../PartDeleteHeader/partDeleteSlice";

import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { PmEditOverlay } from "../PmEditOverlay/PmEditOverlay";

export const PartDetailCardMUI = ({
  descriptionValue = "x xxxxx xxxxx xxxxxx",
  partNumberValue = "XXXXXA AXXX1",
  checkbox = false,
  editButton = true,
  colorValue = "NHXXXX",
  partId = null,
  skeleton = false, // Add skeleton prop
}) => {
  const dispatch = useDispatch();
  const deleteState = useSelector(selectDeleteState);
  const [isChecked, setIsChecked] = useState(deleteState.includes(partId));
  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setIsChecked(deleteState.includes(partId));
  }, [deleteState, partId]);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;

    if (checked) {
      // Call the provided callback to add or remove the partId
      dispatch(addToDelete(partId ));
    } else {
      dispatch(removeFromDelete(partId ));
    }
    setIsChecked(checked);
  };

  const handleOpenOverlay = (refId) => {
    setIsEditOverlayOpen(true);
    dispatch(openPmEditOverlay(refId)); // Pass the refId to open the specific overlay
  };

  const cardStyle = {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    margin: "8px",
    flexWrap: "no-wrap",
  };

  const iconStyle = {
    marginRight: "4px",
    fontSize: "16px",
  };

  const descriptionContainerStyle = {
    backgroundColor:theme.palette.primary.light, // "#f0f0f0", // Light gray background color
    padding: "8px",
    borderRadius: "0 0 0 8px",
    display: "flex",
    alignItems: "center",
  };

  return (
    <Paper elevation={2} style={cardStyle}>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          {checkbox && (
            <Checkbox
              style={iconStyle}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          )}
        </Grid>

        <Grid item xs={8} alignItems="stretch">
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HexagonOutlinedIcon style={iconStyle} />
                <Typography variant="body2">Part Number</Typography>
              </div>
              {skeleton ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Typography variant="body1">{partNumberValue}</Typography>
              )}
            </Grid>

            <Grid item xs={2}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ColorLensOutlinedIcon style={iconStyle} />
                <Typography variant="body2">Color</Typography>
              </div>
              {skeleton ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Typography variant="body1">{colorValue}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {editButton && (
          <Grid item xs="auto" style={{ marginLeft: "auto" }}>
            {skeleton ? (
              <Skeleton variant="rect" width={24} height={24} />
            ) : (
              <IconButton
                onClick={() => handleOpenOverlay(partId)}
                style={iconStyle}
              >
                <EditIcon />
              </IconButton>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <div style={descriptionContainerStyle}>
            <DescriptionOutlinedIcon style={iconStyle} />
            {skeleton ? (
              <Skeleton variant="rect" width="100%" animation="wave"/>
            ) : (
              <Typography variant="body1">{descriptionValue}</Typography>
            )}
          </div>
        </Grid>
      </Grid>
      {isEditOverlayOpen && <PmEditOverlay/>}
    </Paper>
  );
};

PartDetailCardMUI.propTypes = {
  descriptionValue: PropTypes.string,
  partNumberValue: PropTypes.string,
  checkbox: PropTypes.bool,
  colorValue: PropTypes.string,
  editButton: PropTypes.bool,
  className: PropTypes.string,
  partId: PropTypes.string,
  skeleton: PropTypes.bool, // Add prop type for skeleton
};
