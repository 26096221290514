// asnSlice.js
import { createSlice } from '@reduxjs/toolkit';

const labelSlice = createSlice({
  name: 'label',
  initialState: {
    historyPageRefreshTimestamp: new Date().toISOString(),
  },
  reducers: {
    refreshLabelHistoryPage: (state) => {
      state.historyPageRefreshTimestamp = new Date().toISOString(); // Set the refreshAsn with the provided ISO string
    }
  },
});

export const { refreshLabelHistoryPage } = labelSlice.actions;

export default labelSlice.reducer;