import React, { useCallback, useEffect, useState } from "react";
import {
    Container,
    Grid,
  } from '@mui/material';
import SearchHeaderUI from "../../../components/headers/search-header/SearchHeader";
import usePermissionCheck from "../../../components/permission-check/usePermissionCheck";
import AsnHistoryCard from "./AsnHistoryCard";
import asnFilterMapFn from "../asnFilterMapFn";
import { ASN_STORE } from "../../../utilities/indexedDB";
import { applySearchAndFilter } from "../../../utilities/searchFilter";
import { fetchDataAndStore } from "../../../utilities/fetchData";
import { useSupplierContext } from "../../../components/supplier-context/SupplierThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { clearLoadingMessage, setLoadingMessage } from "../../../components/loading-dialog/loadingMessageSlice";

const AsnHistoryPage = () => {
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [filterMap, setFilterMap] = useState({})
    const [asnList, setAsnList] = useState([])
    const [asnSearchShowList, setAsnSearchShowList] = useState([]);
    const {selectedSupplier} = useSupplierContext()
    const pageRefresh = useSelector(state => state.asn.refreshedAsnHistoryPageTime)

    usePermissionCheck({
      requestType: 'view',
      tableName:'asntable',
      message: "You don't have permission to view ASN, check with your administrator!"
    })

    useEffect(() => {
      dispatch(setLoadingMessage("loading ASNs"))
      fetchDataAndStore(
        `${process.env.REACT_APP_API}/${process.env.REACT_APP_ASN_ROUTE}/`,
        ASN_STORE,
        'asn_status'
      ).then((asns) => {
        setAsnList(asns);
        setFilterMap(asnFilterMapFn(asns));
        setAsnSearchShowList(asns);
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        dispatch(clearLoadingMessage())
      })

    }, [selectedSupplier, pageRefresh])

    // const fetchData = useCallback(async () => {
    //   const _asnlist = await fetchDataAndStore(
    //     `${process.env.REACT_APP_API}/${process.env.REACT_APP_ASN_ROUTE}/`,
    //     ASN_STORE,
    //     'asn_status'
    //     );
    //   if(! _asnlist) return;
    //   const filteredAsnList = [..._asnlist]
    //   setAsnList(filteredAsnList);
    //   setFilterMap(asnFilterMapFn(filteredAsnList));
    //   setAsnSearchShowList(filteredAsnList);
    // }, []);
    
    // useEffect(() => {
    //   fetchData();
    // }, []);

    useEffect(() => {
      const searchKeys = ['asn_status', 'asn_id', 'asn_scac']
      setAsnSearchShowList(applySearchAndFilter(searchValue, searchKeys, asnList, filterMap))
    }, [searchValue, asnList, filterMap])

    const asnListArea = (asnList) => {
      return (
        <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          {asnList?.map((asn, index) => (
              <AsnHistoryCard
                key={asn + index}
                asnData={asn}
                skeleton={false}
              />
          ))}
        </Grid>
      </Grid>
      )
    }

    return (
    <div>
      <SearchHeaderUI
        label="ASN History"
        shownData={asnSearchShowList}
        setSearchValue={setSearchValue}
        setIsSearching={setIsSearching}

        filterMap={filterMap}
        setFilterMap={setFilterMap}
      />
      
      <Container>
        {isSearching ? asnListArea(asnSearchShowList): asnListArea(asnList)}
      </Container>

    </div>
    )
}

export default AsnHistoryPage;
