import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { getItemFromStoreById } from "../../../../utilities/getFromStore";
import { PARTS_STORE } from "../../../../utilities/indexedDB";
import { TAG_LABEL_TYPE } from "../../../../components/LABEL_CHOICES";

const LabelPreviewCard = ({ formValues }) => {
  const [part, setPart] = useState({});

  useEffect(() => {
    
    const fetchData = async() => {
      const partId = formValues.label_part_id;
      if(partId) setPart(await getItemFromStoreById(PARTS_STORE, partId));
    }
    fetchData()
  }, [])

  return (
    <Card>
      <CardContent >
        <Typography variant="h6" component="div">
          Preview
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Label ID:</strong> {formValues.label_id}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Supplier ID:</strong> {formValues.label_supplier_id}
        </Typography>

        { 
        formValues.label_type === TAG_LABEL_TYPE ?
        <>
          <Typography variant="body2" color="text.secondary">
            <strong>Part Number:</strong> {part?.part_number}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          <strong>Color Code:</strong> {part?.color_code}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Container Quantity:</strong> {formValues.label_container_qty}
        </Typography>
        </>
        :
          <Typography variant="body2" color="text.secondary">
          <strong>Shipto Id:</strong> {formValues.qrl_shipto_id}
        </Typography>
        }

        <Typography variant="body2" color="text.secondary">
          <strong>Label Status:</strong> {formValues.label_status}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Label Type:</strong> {formValues.label_type}
        </Typography>

      </CardContent>
    </Card>
  );
};

export default LabelPreviewCard;
