// asnSlice.js
import { createSlice } from '@reduxjs/toolkit';

const asnSlice = createSlice({
  name: 'asn',
  initialState: {
    refreshedAsnPageTime: new Date().toISOString(),
    refreshedQrlPageTime: new Date().toISOString(),
    refreshedAsnHistoryPageTime: new Date().toISOString()
  },
  reducers: {
    refreshAsnPage: (state) => {
      state.refreshedAsnPageTime = new Date().toISOString(); // Set the refreshAsn with the provided ISO string
    },
    refreshQrlPage: (state) => {
        state.refreshedQrlPageTime = new Date().toISOString();
    },
    refreshAsnHistoryPage: (state) => {
      state.refreshedAsnHistoryPageTime = new Date().toISOString()
    }
  },
});

export const { refreshAsnPage, refreshQrlPage, refreshAsnHistoryPage } = asnSlice.actions;

export default asnSlice.reducer;
