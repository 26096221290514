import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const LoadingOverlay = () => {
  const message = useSelector((state) => state.loadingMessage.message)

  // useEffect(() => {
  //   console.log({message})
  // },[message])

  return (
    <Dialog
      open={message ? true : false}
      hideBackdrop
      PaperProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
        },
      }}
    >
      <CircularProgress size={50} thickness={5} />
      <Typography variant="body1" style={{ marginTop: '16px' }}>
        {message}
      </Typography>
    </Dialog>
  );
};

export default LoadingOverlay;
