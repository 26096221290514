import { ASN_SHIPPED } from "../../components/LABEL_CHOICES"
import { ASN_STORE } from "../../utilities/indexedDB"
import { updateBackendAndStore } from "../../utilities/updateData"


const ASN_URL = `${process.env.REACT_APP_API}/${process.env.REACT_APP_ASN_ROUTE}/`

export const closeAsn = async (asnId) => {
    return updateBackendAndStore(
        ASN_URL,
        {"asn_status": ASN_SHIPPED},
        asnId,
        ASN_STORE
    )
};

