import React from "react";
import { Grid, Typography } from "@mui/material";
import DirectGattConnect from "./DirectConnect";

const PrinterLabel = () => {
    return (
        <Grid item xs={12} sx={{ mt: 4 }}>

            <DirectGattConnect/>
        </Grid>

    );
};

export default PrinterLabel;
